import { RefObject, useEffect } from 'react'
import { TypeAttributes } from 'rsuite/cjs/@types/common'

export function useClickOutside(
  ref: RefObject<HTMLElement>,
  handler: () => void
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      // @ts-ignore
      if (ref?.current && !ref.current.contains(event.target)) {
        handler()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

export function getDistinctColorForString(
  str: string,
  idx: number,
  colors: TypeAttributes.Color[]
): TypeAttributes.Color {
  let sum = 0
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i)
  }

  return colors[(sum + idx) % colors.length]
}
