import React, { useEffect, useState } from 'react'
import moment from 'moment/moment'
import { IconButton, Loader, Popover, Stack, Whisper } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'
import { CiClock2 } from 'react-icons/ci'
import { Timeline } from '../../components/Timeline'
import { PiUsersLight } from 'react-icons/pi'
import {
  ProfileFragment,
  useGetMeetingTimelineSubscription,
} from '../../generated/urql.user'
import { TfiPlus } from 'react-icons/tfi'
import { ProfileAvatar } from '../../components/ProfileAvatar'
import { TypeAttributes } from 'rsuite/cjs/@types/common'

function formatDuration(duration: moment.Duration) {
  return (
    (duration.hours() > 0 ? duration.hours() + ':' : '') +
    String(duration.minutes()).padStart(duration.hours() > 0 ? 2 : 1, '0') +
    ':' +
    String(duration.seconds()).padStart(2, '0')
  )
}

const MeetingTimeline: React.FC<{
  meetingId: string
  participants: Array<{
    id: string
    externalId: string
    profile?: ProfileFragment | null
    color: TypeAttributes.Color
  }>
  currentTime?: number
  onCurrentTimeChange?: (currentTime: number | undefined) => void
  onMeetingParticipantEdit?: (id: string) => void
  onMeetingParticipantCreate?: () => void
}> = ({
  meetingId,
  participants,
  currentTime,
  onCurrentTimeChange,
  onMeetingParticipantEdit,
  onMeetingParticipantCreate,
}) => {
  const { getAccessTokenSilently } = useAuth0()
  const [{ data: getMeetingTimelineData }] = useGetMeetingTimelineSubscription({
    variables: { meetingId },
  })
  const [accessToken, setAccessToken] = useState<string>()
  const [hoverTime, setHoverTime] = useState<number>()

  useEffect(() => {
    let stop = false
    getAccessTokenSilently().then((accessToken) => {
      if (!stop) {
        setAccessToken(accessToken)
      }
    })

    return () => {
      stop = true
    }
  }, [getAccessTokenSilently, setAccessToken])

  const meetingTimeline = getMeetingTimelineData?.meeting_by_pk

  if (!meetingTimeline) {
    return <Loader />
  }

  return (
    <>
      <Stack alignItems="flex-start" className="mb-3">
        <Stack.Item basis="130px">
          <h5 className="flex items-center gap-1 text-gray-900">
            <CiClock2 size="20" />
            <span>Time</span>
          </h5>
        </Stack.Item>
        <Stack.Item grow={1}>
          <Timeline
            style={{ height: '24px' }}
            onClick={onCurrentTimeChange}
            onMouseMove={setHoverTime}
            onMouseLeave={() => setHoverTime(undefined)}
            maxTime={meetingTimeline.actualDuration}
          >
            <Timeline.Duration
              startTime={0}
              endTime={meetingTimeline.actualDuration}
            >
              <div
                style={{
                  backgroundColor: '#c9d6f4',
                  width: '100%',
                  height: '12px',
                  border: `6px solid #c9d6f4`,
                  borderRadius: '6px',
                }}
              ></div>
            </Timeline.Duration>
            {currentTime ? (
              <Timeline.Duration startTime={0} endTime={currentTime}>
                <div
                  style={{
                    backgroundColor: '#1091F4',
                    width: '100%',
                    height: '12px',
                    border: `6px solid #1091F4`,
                    borderRadius: '6px',
                  }}
                ></div>
              </Timeline.Duration>
            ) : null}
            {currentTime ? (
              <Timeline.Point time={currentTime}>
                <div
                  style={{
                    backgroundColor: 'rgb(245, 248, 250)',
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    border: '2px solid #1091F4',
                    marginTop: '-14px',
                    marginLeft: '-14px',
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      fontSize: '28px',
                      position: 'absolute',
                      top: '48px',
                      left: '-50px',
                      textAlign: 'center',
                      background:
                        'linear-gradient(90deg, transparent 0%, rgb(245, 248, 250) 15%, rgb(245, 248, 250) 85%, transparent 100%)',
                      borderRadius: '14px',
                      lineHeight: '100%',
                      width: '140px',
                    }}
                  >
                    {formatDuration(
                      moment.duration(currentTime, 'milliseconds')
                    )}
                  </div>
                </div>
              </Timeline.Point>
            ) : null}
            {hoverTime ? (
              <Timeline.Point time={hoverTime}>
                <div
                  style={{
                    backgroundColor: 'rgb(245, 248, 250)',
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    border: '2px solid #1091F4',
                    marginTop: '-14px',
                    marginLeft: '-14px',
                    position: 'relative',
                    pointerEvents: 'none',
                  }}
                >
                  <div
                    style={{
                      fontSize: '28px',
                      position: 'absolute',
                      top: '48px',
                      left: '-50px',
                      textAlign: 'center',
                      background:
                        'linear-gradient(90deg, transparent 0%, rgb(245, 248, 250) 15%, rgb(245, 248, 250) 85%, transparent 100%)',
                      borderRadius: '14px',
                      lineHeight: '100%',
                      width: '140px',
                    }}
                  >
                    {formatDuration(moment.duration(hoverTime, 'milliseconds'))}
                  </div>
                </div>
              </Timeline.Point>
            ) : null}
          </Timeline>
          <Stack justifyContent="space-between">
            <Stack.Item>
              <span className="text-sm">0:00</span>
            </Stack.Item>
            <Stack.Item>
              <span className="text-sm">
                {formatDuration(
                  moment.duration(
                    meetingTimeline.actualDuration,
                    'milliseconds'
                  )
                )}
              </span>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
      <Stack alignItems="flex-start">
        <Stack.Item basis="130px">
          <h5 className="flex items-center gap-1 text-gray-900">
            <PiUsersLight size="20" />
            <span>Participants</span>
          </h5>
        </Stack.Item>
        <Stack.Item grow={1}>
          <Timeline
            style={{ height: '24px' }}
            onClick={onCurrentTimeChange}
            onMouseMove={setHoverTime}
            onMouseLeave={() => setHoverTime(undefined)}
          >
            {[
              <Timeline.Duration
                key="meeting-duration"
                startTime={0}
                endTime={meetingTimeline.actualDuration}
              >
                <div
                  style={{
                    backgroundColor: '#dfe1e5',
                    width: '100%',
                    height: '12px',
                    border: `6px solid #dfe1e5`,
                    borderRadius: '6px',
                  }}
                ></div>
              </Timeline.Duration>,
              ...meetingTimeline.transcriptions.map((transcription) => {
                const participant = participants.find(
                  (p) => transcription.participantId === p.id
                )

                if (!participant) {
                  throw new Error(
                    `Participant ${transcription.participantId} not found`
                  )
                }

                return (
                  <Timeline.Duration
                    key={transcription.id}
                    startTime={transcription.startTime}
                    endTime={transcription.endTime}
                  >
                    <div
                      style={{
                        backgroundColor: `var(--rs-${participant.color}-500)`,
                        width: '100%',
                        height: '12px',
                        border: `6px solid var(--rs-${participant.color}-500)`,
                        borderRadius: '6px',
                      }}
                    ></div>
                  </Timeline.Duration>
                )
              }),
              currentTime ? (
                <Timeline.Point key="current-date" time={currentTime}>
                  <div
                    style={{
                      backgroundColor: 'rgb(245, 248, 250)',
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      border: '2px solid #1091F4',
                      marginTop: '-14px',
                      marginLeft: '-14px',
                    }}
                  ></div>
                </Timeline.Point>
              ) : null,
              hoverTime ? (
                <Timeline.Point key="hover-date" time={hoverTime}>
                  <div
                    style={{
                      backgroundColor: 'rgb(245, 248, 250)',
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      border: '2px solid #1091F4',
                      marginTop: '-14px',
                      marginLeft: '-14px',
                      pointerEvents: 'none',
                    }}
                  ></div>
                </Timeline.Point>
              ) : null,
            ]}
          </Timeline>
          <Stack className="mt-1" spacing="6px" alignItems="center">
            {participants.map((participant, i) => (
              <Stack.Item key={participant.id} className="leading-none">
                <Whisper
                  placement="bottom"
                  speaker={
                    <Popover>
                      {participant.profile?.name || participant.externalId}
                    </Popover>
                  }
                >
                  <ProfileAvatar
                    profile={
                      participant.profile || { name: participant.externalId }
                    }
                    color={participant.color}
                    accessToken={accessToken}
                    onClick={() => onMeetingParticipantEdit?.(participant.id)}
                  />
                </Whisper>
              </Stack.Item>
            ))}
            {!!onMeetingParticipantCreate && (
              <Stack.Item>
                <IconButton
                  circle
                  icon={<TfiPlus className="text-gray-600" />}
                  onClick={() => onMeetingParticipantCreate()}
                />
              </Stack.Item>
            )}
          </Stack>
        </Stack.Item>
      </Stack>
    </>
  )
}

export default MeetingTimeline
