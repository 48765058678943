import React, { useCallback, useState, forwardRef } from 'react'
import {
  useGetCenterAnalysisMeetingPromptOutputsSubscription,
  useGetSavedUserPromptsQuery,
  useInsertMeetingPromptOutputMutation,
} from '../../generated/urql.user'
import { Button, IconButton, Popover, SelectPicker, Stack } from 'rsuite'
import { TfiPlus } from 'react-icons/tfi'
import { PositionChildProps } from 'rsuite/cjs/internals/Overlay/Position'

export const MeetingPromptAddPopover = forwardRef<
  HTMLDivElement,
  PositionChildProps & {
    meetingId: string
    onCreateCustom?: (args: { meetingId: string }) => void
    onClose?: () => void
  }
>(({ meetingId, onCreateCustom, left, top, className, onClose }, ref) => {
  const [{ data: GetCenterAnalysisMeetingPromptOutputsData }] =
    useGetCenterAnalysisMeetingPromptOutputsSubscription({
      variables: { meetingId },
    })
  const [
    { data: getSavedUserPromptsData, fetching: getSavedUserPromptsFetching },
  ] = useGetSavedUserPromptsQuery()
  const [, insertMeetingPromptOutput] = useInsertMeetingPromptOutputMutation()
  const [selectedPromptId, setSelectedPromptId] = useState<string | null>()
  const meetingPromptOutputs =
    GetCenterAnalysisMeetingPromptOutputsData?.meeting_prompt_output

  const doInsertMeetingPromptOutput = useCallback(async () => {
    if (!selectedPromptId) return
    await insertMeetingPromptOutput({
      meetingId,
      promptId: selectedPromptId,
    })
    setSelectedPromptId(null)
    onClose && onClose()
  }, [selectedPromptId, meetingId])

  const availablePrompts = (getSavedUserPromptsData?.prompt || []).filter(
    (prompt) =>
      !(meetingPromptOutputs || []).find(
        (meetingPromptOutput) => meetingPromptOutput.prompt.id === prompt.id
      )
  )

  return (
    <Popover style={{ left, top }} className={`${className} p-6`} ref={ref}>
      <Stack justifyContent="center" direction="column">
        {!!availablePrompts.length && (
          <div>
            <SelectPicker<string>
              loading={getSavedUserPromptsFetching}
              size="sm"
              style={{ width: '300px' }}
              searchable={false}
              placeholder="Select prompt"
              value={selectedPromptId}
              onChange={setSelectedPromptId}
              data={
                availablePrompts.map(
                  ({ id, name, input, systemPrompts: [systemPrompt] }) => ({
                    label: name,
                    value: id,
                    description: systemPrompt?.description || input,
                  })
                ) || []
              }
              renderMenuItem={(label, item) => (
                <div>
                  <p className="font-sm font-bold m-0">{label}</p>
                  <p className="text-xs text-muted m-0">
                    {item.description.substring(0, 100) + '...'}
                  </p>
                </div>
              )}
            />
            <Button
              appearance="link"
              disabled={!selectedPromptId}
              size="sm"
              onClick={doInsertMeetingPromptOutput}
            >
              Add
            </Button>
          </div>
        )}
        {!!availablePrompts.length && <span className="text-xs">or</span>}
        <IconButton
          onClick={() => {
            onCreateCustom && onCreateCustom({ meetingId })
            onClose && onClose()
          }}
          style={{ paddingLeft: '10px' }}
          appearance="link"
          startIcon={<TfiPlus className="mr-1" />}
          size="sm"
        >
          Create custom prompt...
        </IconButton>
      </Stack>
    </Popover>
  )
})
