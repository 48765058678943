import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  float8: any;
  jsonb: any;
  numeric: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type InstallUserIntegrationOutput = {
  __typename?: 'InstallUserIntegrationOutput';
  userIntegrationId: Scalars['uuid'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type MeetingVideoOutput = {
  __typename?: 'MeetingVideoOutput';
  accessToken: Scalars['String'];
  url: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export enum Content_Type_Enum {
  ApplicationJson = 'application_json',
  TextMarkdown = 'text_markdown',
  TextPlain = 'text_plain'
}

/** Boolean expression to compare columns of type "content_type_enum". All fields are combined with logical 'AND'. */
export type Content_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Content_Type_Enum>;
  _in?: InputMaybe<Array<Content_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Content_Type_Enum>;
  _nin?: InputMaybe<Array<Content_Type_Enum>>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "file" */
export type File = {
  __typename?: 'file';
  id: Scalars['uuid'];
  /** An array relationship */
  meetingRecordings: Array<Meeting_Recording>;
  /** An aggregate relationship */
  meetingRecordings_aggregate: Meeting_Recording_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  profiles: Array<Profile>;
  /** An aggregate relationship */
  profiles_aggregate: Profile_Aggregate;
};


/** columns and relationships of "file" */
export type FileMeetingRecordingsArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Recording_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Recording_Order_By>>;
  where?: InputMaybe<Meeting_Recording_Bool_Exp>;
};


/** columns and relationships of "file" */
export type FileMeetingRecordings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Recording_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Recording_Order_By>>;
  where?: InputMaybe<Meeting_Recording_Bool_Exp>;
};


/** columns and relationships of "file" */
export type FileProfilesArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


/** columns and relationships of "file" */
export type FileProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "file". All fields are combined with a logical 'AND'. */
export type File_Bool_Exp = {
  _and?: InputMaybe<Array<File_Bool_Exp>>;
  _not?: InputMaybe<File_Bool_Exp>;
  _or?: InputMaybe<Array<File_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  meetingRecordings?: InputMaybe<Meeting_Recording_Bool_Exp>;
  meetingRecordings_aggregate?: InputMaybe<Meeting_Recording_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  profiles?: InputMaybe<Profile_Bool_Exp>;
  profiles_aggregate?: InputMaybe<Profile_Aggregate_Bool_Exp>;
};

/** Ordering options when selecting data from "file". */
export type File_Order_By = {
  id?: InputMaybe<Order_By>;
  meetingRecordings_aggregate?: InputMaybe<Meeting_Recording_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  profiles_aggregate?: InputMaybe<Profile_Aggregate_Order_By>;
};

/** select columns of table "file" */
export enum File_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "file" */
export type File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type File_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "integration_type" */
export type Integration_Type = {
  __typename?: 'integration_type';
  type: Scalars['String'];
};

/** Boolean expression to filter rows from the table "integration_type". All fields are combined with a logical 'AND'. */
export type Integration_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Integration_Type_Bool_Exp>>;
  _not?: InputMaybe<Integration_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Integration_Type_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

export enum Integration_Type_Enum {
  None = 'none',
  Zoom = 'zoom'
}

/** Boolean expression to compare columns of type "integration_type_enum". All fields are combined with logical 'AND'. */
export type Integration_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Integration_Type_Enum>;
  _in?: InputMaybe<Array<Integration_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Integration_Type_Enum>;
  _nin?: InputMaybe<Array<Integration_Type_Enum>>;
};

/** Ordering options when selecting data from "integration_type". */
export type Integration_Type_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** select columns of table "integration_type" */
export enum Integration_Type_Select_Column {
  /** column name */
  Type = 'type'
}

/** Streaming cursor of the table "integration_type" */
export type Integration_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integration_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_Type_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "job" */
export type Job = {
  __typename?: 'job';
  createdAt: Scalars['timestamptz'];
  failedAt?: Maybe<Scalars['timestamptz']>;
  finishedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  meeting?: Maybe<Meeting>;
  meetingId?: Maybe<Scalars['uuid']>;
  type: Scalars['String'];
};

/** aggregated selection of "job" */
export type Job_Aggregate = {
  __typename?: 'job_aggregate';
  aggregate?: Maybe<Job_Aggregate_Fields>;
  nodes: Array<Job>;
};

export type Job_Aggregate_Bool_Exp = {
  count?: InputMaybe<Job_Aggregate_Bool_Exp_Count>;
};

export type Job_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Job_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "job" */
export type Job_Aggregate_Fields = {
  __typename?: 'job_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Job_Max_Fields>;
  min?: Maybe<Job_Min_Fields>;
};


/** aggregate fields of "job" */
export type Job_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job" */
export type Job_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Max_Order_By>;
  min?: InputMaybe<Job_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "job". All fields are combined with a logical 'AND'. */
export type Job_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Bool_Exp>>;
  _not?: InputMaybe<Job_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  failedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  finishedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  meeting?: InputMaybe<Meeting_Bool_Exp>;
  meetingId?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Job_Max_Fields = {
  __typename?: 'job_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  failedAt?: Maybe<Scalars['timestamptz']>;
  finishedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meetingId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "job" */
export type Job_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  failedAt?: InputMaybe<Order_By>;
  finishedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meetingId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Min_Fields = {
  __typename?: 'job_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  failedAt?: Maybe<Scalars['timestamptz']>;
  finishedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meetingId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "job" */
export type Job_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  failedAt?: InputMaybe<Order_By>;
  finishedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meetingId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "job". */
export type Job_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  failedAt?: InputMaybe<Order_By>;
  finishedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting?: InputMaybe<Meeting_Order_By>;
  meetingId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "job" */
export enum Job_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FailedAt = 'failedAt',
  /** column name */
  FinishedAt = 'finishedAt',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meetingId',
  /** column name */
  Type = 'type'
}

/** Streaming cursor of the table "job" */
export type Job_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  failedAt?: InputMaybe<Scalars['timestamptz']>;
  finishedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  meetingId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "meeting" */
export type Meeting = {
  __typename?: 'meeting';
  /** A computed field, executes function "get_meeting_actual_duration" */
  actualDuration?: Maybe<Scalars['float8']>;
  /** A computed field, executes function "get_meeting_actual_start" */
  actualStart?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  events: Array<Meeting_Event>;
  /** An aggregate relationship */
  events_aggregate: Meeting_Event_Aggregate;
  externalId: Scalars['String'];
  id: Scalars['uuid'];
  integrationType: Integration_Type_Enum;
  /** An array relationship */
  jobs: Array<Job>;
  /** An aggregate relationship */
  jobs_aggregate: Job_Aggregate;
  /** An array relationship */
  meetingTypes: Array<Meeting_Type>;
  /** An aggregate relationship */
  meetingTypes_aggregate: Meeting_Type_Aggregate;
  /** An array relationship */
  participants: Array<Meeting_Participant>;
  /** An aggregate relationship */
  participants_aggregate: Meeting_Participant_Aggregate;
  /** An array relationship */
  promptOutputs: Array<Meeting_Prompt_Output>;
  /** An aggregate relationship */
  promptOutputs_aggregate: Meeting_Prompt_Output_Aggregate;
  /** An array relationship */
  recordings: Array<Meeting_Recording>;
  /** An aggregate relationship */
  recordings_aggregate: Meeting_Recording_Aggregate;
  scheduledDuration?: Maybe<Scalars['Int']>;
  scheduledStart?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "get_current_meeting_status" */
  status?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_current_meeting_status_date" */
  statusDate?: Maybe<Scalars['timestamptz']>;
  topic?: Maybe<Scalars['String']>;
  /** An array relationship */
  transcriptions: Array<Meeting_Transcription>;
  /** An aggregate relationship */
  transcriptions_aggregate: Meeting_Transcription_Aggregate;
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "meeting" */
export type MeetingEventsArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Event_Order_By>>;
  where?: InputMaybe<Meeting_Event_Bool_Exp>;
};


/** columns and relationships of "meeting" */
export type MeetingEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Event_Order_By>>;
  where?: InputMaybe<Meeting_Event_Bool_Exp>;
};


/** columns and relationships of "meeting" */
export type MeetingJobsArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


/** columns and relationships of "meeting" */
export type MeetingJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


/** columns and relationships of "meeting" */
export type MeetingMeetingTypesArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Type_Order_By>>;
  where?: InputMaybe<Meeting_Type_Bool_Exp>;
};


/** columns and relationships of "meeting" */
export type MeetingMeetingTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Type_Order_By>>;
  where?: InputMaybe<Meeting_Type_Bool_Exp>;
};


/** columns and relationships of "meeting" */
export type MeetingParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Participant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Participant_Order_By>>;
  where?: InputMaybe<Meeting_Participant_Bool_Exp>;
};


/** columns and relationships of "meeting" */
export type MeetingParticipants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Participant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Participant_Order_By>>;
  where?: InputMaybe<Meeting_Participant_Bool_Exp>;
};


/** columns and relationships of "meeting" */
export type MeetingPromptOutputsArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Prompt_Output_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Prompt_Output_Order_By>>;
  where?: InputMaybe<Meeting_Prompt_Output_Bool_Exp>;
};


/** columns and relationships of "meeting" */
export type MeetingPromptOutputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Prompt_Output_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Prompt_Output_Order_By>>;
  where?: InputMaybe<Meeting_Prompt_Output_Bool_Exp>;
};


/** columns and relationships of "meeting" */
export type MeetingRecordingsArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Recording_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Recording_Order_By>>;
  where?: InputMaybe<Meeting_Recording_Bool_Exp>;
};


/** columns and relationships of "meeting" */
export type MeetingRecordings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Recording_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Recording_Order_By>>;
  where?: InputMaybe<Meeting_Recording_Bool_Exp>;
};


/** columns and relationships of "meeting" */
export type MeetingTranscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Transcription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Transcription_Order_By>>;
  where?: InputMaybe<Meeting_Transcription_Bool_Exp>;
};


/** columns and relationships of "meeting" */
export type MeetingTranscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Transcription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Transcription_Order_By>>;
  where?: InputMaybe<Meeting_Transcription_Bool_Exp>;
};

/** aggregated selection of "meeting" */
export type Meeting_Aggregate = {
  __typename?: 'meeting_aggregate';
  aggregate?: Maybe<Meeting_Aggregate_Fields>;
  nodes: Array<Meeting>;
};

/** aggregate fields of "meeting" */
export type Meeting_Aggregate_Fields = {
  __typename?: 'meeting_aggregate_fields';
  avg?: Maybe<Meeting_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Meeting_Max_Fields>;
  min?: Maybe<Meeting_Min_Fields>;
  stddev?: Maybe<Meeting_Stddev_Fields>;
  stddev_pop?: Maybe<Meeting_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Meeting_Stddev_Samp_Fields>;
  sum?: Maybe<Meeting_Sum_Fields>;
  var_pop?: Maybe<Meeting_Var_Pop_Fields>;
  var_samp?: Maybe<Meeting_Var_Samp_Fields>;
  variance?: Maybe<Meeting_Variance_Fields>;
};


/** aggregate fields of "meeting" */
export type Meeting_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Meeting_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Meeting_Avg_Fields = {
  __typename?: 'meeting_avg_fields';
  /** A computed field, executes function "get_meeting_actual_duration" */
  actualDuration?: Maybe<Scalars['float8']>;
  scheduledDuration?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "meeting". All fields are combined with a logical 'AND'. */
export type Meeting_Bool_Exp = {
  _and?: InputMaybe<Array<Meeting_Bool_Exp>>;
  _not?: InputMaybe<Meeting_Bool_Exp>;
  _or?: InputMaybe<Array<Meeting_Bool_Exp>>;
  actualDuration?: InputMaybe<Float8_Comparison_Exp>;
  actualStart?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  events?: InputMaybe<Meeting_Event_Bool_Exp>;
  events_aggregate?: InputMaybe<Meeting_Event_Aggregate_Bool_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  integrationType?: InputMaybe<Integration_Type_Enum_Comparison_Exp>;
  jobs?: InputMaybe<Job_Bool_Exp>;
  jobs_aggregate?: InputMaybe<Job_Aggregate_Bool_Exp>;
  meetingTypes?: InputMaybe<Meeting_Type_Bool_Exp>;
  meetingTypes_aggregate?: InputMaybe<Meeting_Type_Aggregate_Bool_Exp>;
  participants?: InputMaybe<Meeting_Participant_Bool_Exp>;
  participants_aggregate?: InputMaybe<Meeting_Participant_Aggregate_Bool_Exp>;
  promptOutputs?: InputMaybe<Meeting_Prompt_Output_Bool_Exp>;
  promptOutputs_aggregate?: InputMaybe<Meeting_Prompt_Output_Aggregate_Bool_Exp>;
  recordings?: InputMaybe<Meeting_Recording_Bool_Exp>;
  recordings_aggregate?: InputMaybe<Meeting_Recording_Aggregate_Bool_Exp>;
  scheduledDuration?: InputMaybe<Int_Comparison_Exp>;
  scheduledStart?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  statusDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  topic?: InputMaybe<String_Comparison_Exp>;
  transcriptions?: InputMaybe<Meeting_Transcription_Bool_Exp>;
  transcriptions_aggregate?: InputMaybe<Meeting_Transcription_Aggregate_Bool_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "meeting" */
export enum Meeting_Constraint {
  /** unique or primary key constraint on columns "workspace_id", "external_id" */
  MeetingExternalIdWorkspaceIdKey = 'meeting_external_id_workspace_id_key',
  /** unique or primary key constraint on columns "id" */
  MeetingPkey = 'meeting_pkey'
}

/** columns and relationships of "meeting_event" */
export type Meeting_Event = {
  __typename?: 'meeting_event';
  id: Scalars['uuid'];
  /** An object relationship */
  meeting: Meeting;
  meetingId: Scalars['uuid'];
  /** An object relationship */
  meetingParticipant?: Maybe<Meeting_Participant>;
  meetingParticipantId?: Maybe<Scalars['uuid']>;
  timestamp: Scalars['timestamptz'];
  type: Scalars['String'];
};

/** aggregated selection of "meeting_event" */
export type Meeting_Event_Aggregate = {
  __typename?: 'meeting_event_aggregate';
  aggregate?: Maybe<Meeting_Event_Aggregate_Fields>;
  nodes: Array<Meeting_Event>;
};

export type Meeting_Event_Aggregate_Bool_Exp = {
  count?: InputMaybe<Meeting_Event_Aggregate_Bool_Exp_Count>;
};

export type Meeting_Event_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Meeting_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Meeting_Event_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "meeting_event" */
export type Meeting_Event_Aggregate_Fields = {
  __typename?: 'meeting_event_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Meeting_Event_Max_Fields>;
  min?: Maybe<Meeting_Event_Min_Fields>;
};


/** aggregate fields of "meeting_event" */
export type Meeting_Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Meeting_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "meeting_event" */
export type Meeting_Event_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Meeting_Event_Max_Order_By>;
  min?: InputMaybe<Meeting_Event_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "meeting_event". All fields are combined with a logical 'AND'. */
export type Meeting_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Meeting_Event_Bool_Exp>>;
  _not?: InputMaybe<Meeting_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Meeting_Event_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  meeting?: InputMaybe<Meeting_Bool_Exp>;
  meetingId?: InputMaybe<Uuid_Comparison_Exp>;
  meetingParticipant?: InputMaybe<Meeting_Participant_Bool_Exp>;
  meetingParticipantId?: InputMaybe<Uuid_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Meeting_Event_Max_Fields = {
  __typename?: 'meeting_event_max_fields';
  id?: Maybe<Scalars['uuid']>;
  meetingId?: Maybe<Scalars['uuid']>;
  meetingParticipantId?: Maybe<Scalars['uuid']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "meeting_event" */
export type Meeting_Event_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  meetingId?: InputMaybe<Order_By>;
  meetingParticipantId?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Meeting_Event_Min_Fields = {
  __typename?: 'meeting_event_min_fields';
  id?: Maybe<Scalars['uuid']>;
  meetingId?: Maybe<Scalars['uuid']>;
  meetingParticipantId?: Maybe<Scalars['uuid']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "meeting_event" */
export type Meeting_Event_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  meetingId?: InputMaybe<Order_By>;
  meetingParticipantId?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "meeting_event". */
export type Meeting_Event_Order_By = {
  id?: InputMaybe<Order_By>;
  meeting?: InputMaybe<Meeting_Order_By>;
  meetingId?: InputMaybe<Order_By>;
  meetingParticipant?: InputMaybe<Meeting_Participant_Order_By>;
  meetingParticipantId?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "meeting_event" */
export enum Meeting_Event_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meetingId',
  /** column name */
  MeetingParticipantId = 'meetingParticipantId',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Type = 'type'
}

/** Streaming cursor of the table "meeting_event" */
export type Meeting_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Meeting_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Meeting_Event_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  meetingId?: InputMaybe<Scalars['uuid']>;
  meetingParticipantId?: InputMaybe<Scalars['uuid']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "meeting" */
export type Meeting_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  integrationType?: InputMaybe<Integration_Type_Enum>;
  meetingTypes?: InputMaybe<Meeting_Type_Arr_Rel_Insert_Input>;
  participants?: InputMaybe<Meeting_Participant_Arr_Rel_Insert_Input>;
  promptOutputs?: InputMaybe<Meeting_Prompt_Output_Arr_Rel_Insert_Input>;
  recordings?: InputMaybe<Meeting_Recording_Arr_Rel_Insert_Input>;
  scheduledDuration?: InputMaybe<Scalars['Int']>;
  scheduledStart?: InputMaybe<Scalars['timestamptz']>;
  topic?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Meeting_Max_Fields = {
  __typename?: 'meeting_max_fields';
  /** A computed field, executes function "get_meeting_actual_duration" */
  actualDuration?: Maybe<Scalars['float8']>;
  /** A computed field, executes function "get_meeting_actual_start" */
  actualStart?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  scheduledDuration?: Maybe<Scalars['Int']>;
  scheduledStart?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "get_current_meeting_status" */
  status?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_current_meeting_status_date" */
  statusDate?: Maybe<Scalars['timestamptz']>;
  topic?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Meeting_Min_Fields = {
  __typename?: 'meeting_min_fields';
  /** A computed field, executes function "get_meeting_actual_duration" */
  actualDuration?: Maybe<Scalars['float8']>;
  /** A computed field, executes function "get_meeting_actual_start" */
  actualStart?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  scheduledDuration?: Maybe<Scalars['Int']>;
  scheduledStart?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "get_current_meeting_status" */
  status?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_current_meeting_status_date" */
  statusDate?: Maybe<Scalars['timestamptz']>;
  topic?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "meeting" */
export type Meeting_Mutation_Response = {
  __typename?: 'meeting_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Meeting>;
};

/** input type for inserting object relation for remote table "meeting" */
export type Meeting_Obj_Rel_Insert_Input = {
  data: Meeting_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Meeting_On_Conflict>;
};

/** on_conflict condition type for table "meeting" */
export type Meeting_On_Conflict = {
  constraint: Meeting_Constraint;
  update_columns?: Array<Meeting_Update_Column>;
  where?: InputMaybe<Meeting_Bool_Exp>;
};

/** Ordering options when selecting data from "meeting". */
export type Meeting_Order_By = {
  actualDuration?: InputMaybe<Order_By>;
  actualStart?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  events_aggregate?: InputMaybe<Meeting_Event_Aggregate_Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integrationType?: InputMaybe<Order_By>;
  jobs_aggregate?: InputMaybe<Job_Aggregate_Order_By>;
  meetingTypes_aggregate?: InputMaybe<Meeting_Type_Aggregate_Order_By>;
  participants_aggregate?: InputMaybe<Meeting_Participant_Aggregate_Order_By>;
  promptOutputs_aggregate?: InputMaybe<Meeting_Prompt_Output_Aggregate_Order_By>;
  recordings_aggregate?: InputMaybe<Meeting_Recording_Aggregate_Order_By>;
  scheduledDuration?: InputMaybe<Order_By>;
  scheduledStart?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  statusDate?: InputMaybe<Order_By>;
  topic?: InputMaybe<Order_By>;
  transcriptions_aggregate?: InputMaybe<Meeting_Transcription_Aggregate_Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** columns and relationships of "meeting_participant" */
export type Meeting_Participant = {
  __typename?: 'meeting_participant';
  externalId: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  meeting: Meeting;
  meetingId: Scalars['uuid'];
  /** An object relationship */
  profile?: Maybe<Profile>;
  profileId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  recordings: Array<Meeting_Recording>;
  /** An aggregate relationship */
  recordings_aggregate: Meeting_Recording_Aggregate;
  /** An array relationship */
  transcriptions: Array<Meeting_Transcription>;
  /** An aggregate relationship */
  transcriptions_aggregate: Meeting_Transcription_Aggregate;
};


/** columns and relationships of "meeting_participant" */
export type Meeting_ParticipantRecordingsArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Recording_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Recording_Order_By>>;
  where?: InputMaybe<Meeting_Recording_Bool_Exp>;
};


/** columns and relationships of "meeting_participant" */
export type Meeting_ParticipantRecordings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Recording_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Recording_Order_By>>;
  where?: InputMaybe<Meeting_Recording_Bool_Exp>;
};


/** columns and relationships of "meeting_participant" */
export type Meeting_ParticipantTranscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Transcription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Transcription_Order_By>>;
  where?: InputMaybe<Meeting_Transcription_Bool_Exp>;
};


/** columns and relationships of "meeting_participant" */
export type Meeting_ParticipantTranscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Transcription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Transcription_Order_By>>;
  where?: InputMaybe<Meeting_Transcription_Bool_Exp>;
};

/** aggregated selection of "meeting_participant" */
export type Meeting_Participant_Aggregate = {
  __typename?: 'meeting_participant_aggregate';
  aggregate?: Maybe<Meeting_Participant_Aggregate_Fields>;
  nodes: Array<Meeting_Participant>;
};

export type Meeting_Participant_Aggregate_Bool_Exp = {
  count?: InputMaybe<Meeting_Participant_Aggregate_Bool_Exp_Count>;
};

export type Meeting_Participant_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Meeting_Participant_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Meeting_Participant_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "meeting_participant" */
export type Meeting_Participant_Aggregate_Fields = {
  __typename?: 'meeting_participant_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Meeting_Participant_Max_Fields>;
  min?: Maybe<Meeting_Participant_Min_Fields>;
};


/** aggregate fields of "meeting_participant" */
export type Meeting_Participant_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Meeting_Participant_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "meeting_participant" */
export type Meeting_Participant_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Meeting_Participant_Max_Order_By>;
  min?: InputMaybe<Meeting_Participant_Min_Order_By>;
};

/** input type for inserting array relation for remote table "meeting_participant" */
export type Meeting_Participant_Arr_Rel_Insert_Input = {
  data: Array<Meeting_Participant_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Meeting_Participant_On_Conflict>;
};

/** Boolean expression to filter rows from the table "meeting_participant". All fields are combined with a logical 'AND'. */
export type Meeting_Participant_Bool_Exp = {
  _and?: InputMaybe<Array<Meeting_Participant_Bool_Exp>>;
  _not?: InputMaybe<Meeting_Participant_Bool_Exp>;
  _or?: InputMaybe<Array<Meeting_Participant_Bool_Exp>>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  meeting?: InputMaybe<Meeting_Bool_Exp>;
  meetingId?: InputMaybe<Uuid_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  profileId?: InputMaybe<Uuid_Comparison_Exp>;
  recordings?: InputMaybe<Meeting_Recording_Bool_Exp>;
  recordings_aggregate?: InputMaybe<Meeting_Recording_Aggregate_Bool_Exp>;
  transcriptions?: InputMaybe<Meeting_Transcription_Bool_Exp>;
  transcriptions_aggregate?: InputMaybe<Meeting_Transcription_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "meeting_participant" */
export enum Meeting_Participant_Constraint {
  /** unique or primary key constraint on columns "external_id", "meeting_id" */
  MeetingParticipantExternalIdMeetingIdKey = 'meeting_participant_external_id_meeting_id_key',
  /** unique or primary key constraint on columns "id" */
  MeetingParticipantPkey = 'meeting_participant_pkey',
  /** unique or primary key constraint on columns "profile_id", "meeting_id" */
  MeetingParticipantProfileIdMeetingIdKey = 'meeting_participant_profile_id_meeting_id_key'
}

/** input type for inserting data into table "meeting_participant" */
export type Meeting_Participant_Insert_Input = {
  externalId?: InputMaybe<Scalars['String']>;
  meeting?: InputMaybe<Meeting_Obj_Rel_Insert_Input>;
  meetingId?: InputMaybe<Scalars['uuid']>;
  profile?: InputMaybe<Profile_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['uuid']>;
  recordings?: InputMaybe<Meeting_Recording_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Meeting_Participant_Max_Fields = {
  __typename?: 'meeting_participant_max_fields';
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  meetingId?: Maybe<Scalars['uuid']>;
  profileId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "meeting_participant" */
export type Meeting_Participant_Max_Order_By = {
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meetingId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Meeting_Participant_Min_Fields = {
  __typename?: 'meeting_participant_min_fields';
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  meetingId?: Maybe<Scalars['uuid']>;
  profileId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "meeting_participant" */
export type Meeting_Participant_Min_Order_By = {
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meetingId?: InputMaybe<Order_By>;
  profileId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "meeting_participant" */
export type Meeting_Participant_Mutation_Response = {
  __typename?: 'meeting_participant_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Meeting_Participant>;
};

/** input type for inserting object relation for remote table "meeting_participant" */
export type Meeting_Participant_Obj_Rel_Insert_Input = {
  data: Meeting_Participant_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Meeting_Participant_On_Conflict>;
};

/** on_conflict condition type for table "meeting_participant" */
export type Meeting_Participant_On_Conflict = {
  constraint: Meeting_Participant_Constraint;
  update_columns?: Array<Meeting_Participant_Update_Column>;
  where?: InputMaybe<Meeting_Participant_Bool_Exp>;
};

/** Ordering options when selecting data from "meeting_participant". */
export type Meeting_Participant_Order_By = {
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting?: InputMaybe<Meeting_Order_By>;
  meetingId?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  profileId?: InputMaybe<Order_By>;
  recordings_aggregate?: InputMaybe<Meeting_Recording_Aggregate_Order_By>;
  transcriptions_aggregate?: InputMaybe<Meeting_Transcription_Aggregate_Order_By>;
};

/** primary key columns input for table: meeting_participant */
export type Meeting_Participant_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "meeting_participant" */
export enum Meeting_Participant_Select_Column {
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meetingId',
  /** column name */
  ProfileId = 'profileId'
}

/** input type for updating data in table "meeting_participant" */
export type Meeting_Participant_Set_Input = {
  externalId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "meeting_participant" */
export type Meeting_Participant_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Meeting_Participant_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Meeting_Participant_Stream_Cursor_Value_Input = {
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  meetingId?: InputMaybe<Scalars['uuid']>;
  profileId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "meeting_participant" */
export enum Meeting_Participant_Update_Column {
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ProfileId = 'profileId'
}

export type Meeting_Participant_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Meeting_Participant_Set_Input>;
  /** filter the rows which have to be updated */
  where: Meeting_Participant_Bool_Exp;
};

/** columns and relationships of "meeting_prompt_output" */
export type Meeting_Prompt_Output = {
  __typename?: 'meeting_prompt_output';
  finishedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  meeting: Meeting;
  meetingId: Scalars['uuid'];
  output: Scalars['String'];
  /** An object relationship */
  prompt: Prompt;
  promptId: Scalars['uuid'];
};

/** aggregated selection of "meeting_prompt_output" */
export type Meeting_Prompt_Output_Aggregate = {
  __typename?: 'meeting_prompt_output_aggregate';
  aggregate?: Maybe<Meeting_Prompt_Output_Aggregate_Fields>;
  nodes: Array<Meeting_Prompt_Output>;
};

export type Meeting_Prompt_Output_Aggregate_Bool_Exp = {
  count?: InputMaybe<Meeting_Prompt_Output_Aggregate_Bool_Exp_Count>;
};

export type Meeting_Prompt_Output_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Meeting_Prompt_Output_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Meeting_Prompt_Output_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "meeting_prompt_output" */
export type Meeting_Prompt_Output_Aggregate_Fields = {
  __typename?: 'meeting_prompt_output_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Meeting_Prompt_Output_Max_Fields>;
  min?: Maybe<Meeting_Prompt_Output_Min_Fields>;
};


/** aggregate fields of "meeting_prompt_output" */
export type Meeting_Prompt_Output_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Meeting_Prompt_Output_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "meeting_prompt_output" */
export type Meeting_Prompt_Output_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Meeting_Prompt_Output_Max_Order_By>;
  min?: InputMaybe<Meeting_Prompt_Output_Min_Order_By>;
};

/** input type for inserting array relation for remote table "meeting_prompt_output" */
export type Meeting_Prompt_Output_Arr_Rel_Insert_Input = {
  data: Array<Meeting_Prompt_Output_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Meeting_Prompt_Output_On_Conflict>;
};

/** Boolean expression to filter rows from the table "meeting_prompt_output". All fields are combined with a logical 'AND'. */
export type Meeting_Prompt_Output_Bool_Exp = {
  _and?: InputMaybe<Array<Meeting_Prompt_Output_Bool_Exp>>;
  _not?: InputMaybe<Meeting_Prompt_Output_Bool_Exp>;
  _or?: InputMaybe<Array<Meeting_Prompt_Output_Bool_Exp>>;
  finishedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  meeting?: InputMaybe<Meeting_Bool_Exp>;
  meetingId?: InputMaybe<Uuid_Comparison_Exp>;
  output?: InputMaybe<String_Comparison_Exp>;
  prompt?: InputMaybe<Prompt_Bool_Exp>;
  promptId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "meeting_prompt_output" */
export enum Meeting_Prompt_Output_Constraint {
  /** unique or primary key constraint on columns "meeting_id", "prompt_id" */
  MeetingPromptOutputPkey = 'meeting_prompt_output_pkey'
}

/** input type for inserting data into table "meeting_prompt_output" */
export type Meeting_Prompt_Output_Insert_Input = {
  finishedAt?: InputMaybe<Scalars['timestamptz']>;
  meeting?: InputMaybe<Meeting_Obj_Rel_Insert_Input>;
  meetingId?: InputMaybe<Scalars['uuid']>;
  output?: InputMaybe<Scalars['String']>;
  prompt?: InputMaybe<Prompt_Obj_Rel_Insert_Input>;
  promptId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Meeting_Prompt_Output_Max_Fields = {
  __typename?: 'meeting_prompt_output_max_fields';
  finishedAt?: Maybe<Scalars['timestamptz']>;
  meetingId?: Maybe<Scalars['uuid']>;
  output?: Maybe<Scalars['String']>;
  promptId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "meeting_prompt_output" */
export type Meeting_Prompt_Output_Max_Order_By = {
  finishedAt?: InputMaybe<Order_By>;
  meetingId?: InputMaybe<Order_By>;
  output?: InputMaybe<Order_By>;
  promptId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Meeting_Prompt_Output_Min_Fields = {
  __typename?: 'meeting_prompt_output_min_fields';
  finishedAt?: Maybe<Scalars['timestamptz']>;
  meetingId?: Maybe<Scalars['uuid']>;
  output?: Maybe<Scalars['String']>;
  promptId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "meeting_prompt_output" */
export type Meeting_Prompt_Output_Min_Order_By = {
  finishedAt?: InputMaybe<Order_By>;
  meetingId?: InputMaybe<Order_By>;
  output?: InputMaybe<Order_By>;
  promptId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "meeting_prompt_output" */
export type Meeting_Prompt_Output_Mutation_Response = {
  __typename?: 'meeting_prompt_output_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Meeting_Prompt_Output>;
};

/** on_conflict condition type for table "meeting_prompt_output" */
export type Meeting_Prompt_Output_On_Conflict = {
  constraint: Meeting_Prompt_Output_Constraint;
  update_columns?: Array<Meeting_Prompt_Output_Update_Column>;
  where?: InputMaybe<Meeting_Prompt_Output_Bool_Exp>;
};

/** Ordering options when selecting data from "meeting_prompt_output". */
export type Meeting_Prompt_Output_Order_By = {
  finishedAt?: InputMaybe<Order_By>;
  meeting?: InputMaybe<Meeting_Order_By>;
  meetingId?: InputMaybe<Order_By>;
  output?: InputMaybe<Order_By>;
  prompt?: InputMaybe<Prompt_Order_By>;
  promptId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: meeting_prompt_output */
export type Meeting_Prompt_Output_Pk_Columns_Input = {
  meetingId: Scalars['uuid'];
  promptId: Scalars['uuid'];
};

/** select columns of table "meeting_prompt_output" */
export enum Meeting_Prompt_Output_Select_Column {
  /** column name */
  FinishedAt = 'finishedAt',
  /** column name */
  MeetingId = 'meetingId',
  /** column name */
  Output = 'output',
  /** column name */
  PromptId = 'promptId'
}

/** input type for updating data in table "meeting_prompt_output" */
export type Meeting_Prompt_Output_Set_Input = {
  finishedAt?: InputMaybe<Scalars['timestamptz']>;
  output?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "meeting_prompt_output" */
export type Meeting_Prompt_Output_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Meeting_Prompt_Output_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Meeting_Prompt_Output_Stream_Cursor_Value_Input = {
  finishedAt?: InputMaybe<Scalars['timestamptz']>;
  meetingId?: InputMaybe<Scalars['uuid']>;
  output?: InputMaybe<Scalars['String']>;
  promptId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "meeting_prompt_output" */
export enum Meeting_Prompt_Output_Update_Column {
  /** column name */
  FinishedAt = 'finishedAt',
  /** column name */
  Output = 'output'
}

export type Meeting_Prompt_Output_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Meeting_Prompt_Output_Set_Input>;
  /** filter the rows which have to be updated */
  where: Meeting_Prompt_Output_Bool_Exp;
};

/** columns and relationships of "meeting_recording" */
export type Meeting_Recording = {
  __typename?: 'meeting_recording';
  /** An object relationship */
  file: File;
  fileId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  meeting: Meeting;
  meetingId: Scalars['uuid'];
  /** An object relationship */
  participant?: Maybe<Meeting_Participant>;
  participantId?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  type: Scalars['String'];
};

/** aggregated selection of "meeting_recording" */
export type Meeting_Recording_Aggregate = {
  __typename?: 'meeting_recording_aggregate';
  aggregate?: Maybe<Meeting_Recording_Aggregate_Fields>;
  nodes: Array<Meeting_Recording>;
};

export type Meeting_Recording_Aggregate_Bool_Exp = {
  count?: InputMaybe<Meeting_Recording_Aggregate_Bool_Exp_Count>;
};

export type Meeting_Recording_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Meeting_Recording_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Meeting_Recording_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "meeting_recording" */
export type Meeting_Recording_Aggregate_Fields = {
  __typename?: 'meeting_recording_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Meeting_Recording_Max_Fields>;
  min?: Maybe<Meeting_Recording_Min_Fields>;
};


/** aggregate fields of "meeting_recording" */
export type Meeting_Recording_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Meeting_Recording_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "meeting_recording" */
export type Meeting_Recording_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Meeting_Recording_Max_Order_By>;
  min?: InputMaybe<Meeting_Recording_Min_Order_By>;
};

/** input type for inserting array relation for remote table "meeting_recording" */
export type Meeting_Recording_Arr_Rel_Insert_Input = {
  data: Array<Meeting_Recording_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Meeting_Recording_On_Conflict>;
};

/** Boolean expression to filter rows from the table "meeting_recording". All fields are combined with a logical 'AND'. */
export type Meeting_Recording_Bool_Exp = {
  _and?: InputMaybe<Array<Meeting_Recording_Bool_Exp>>;
  _not?: InputMaybe<Meeting_Recording_Bool_Exp>;
  _or?: InputMaybe<Array<Meeting_Recording_Bool_Exp>>;
  file?: InputMaybe<File_Bool_Exp>;
  fileId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  meeting?: InputMaybe<Meeting_Bool_Exp>;
  meetingId?: InputMaybe<Uuid_Comparison_Exp>;
  participant?: InputMaybe<Meeting_Participant_Bool_Exp>;
  participantId?: InputMaybe<Uuid_Comparison_Exp>;
  startDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "meeting_recording" */
export enum Meeting_Recording_Constraint {
  /** unique or primary key constraint on columns "participant_id", "meeting_id", "type" */
  MeetingRecordingMeetingIdParticipantIdTypeKey = 'meeting_recording_meeting_id_participant_id_type_key',
  /** unique or primary key constraint on columns "id" */
  MeetingRecordingPkey = 'meeting_recording_pkey'
}

/** input type for inserting data into table "meeting_recording" */
export type Meeting_Recording_Insert_Input = {
  fileId?: InputMaybe<Scalars['uuid']>;
  meeting?: InputMaybe<Meeting_Obj_Rel_Insert_Input>;
  meetingId?: InputMaybe<Scalars['uuid']>;
  participant?: InputMaybe<Meeting_Participant_Obj_Rel_Insert_Input>;
  participantId?: InputMaybe<Scalars['uuid']>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Meeting_Recording_Max_Fields = {
  __typename?: 'meeting_recording_max_fields';
  fileId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  meetingId?: Maybe<Scalars['uuid']>;
  participantId?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "meeting_recording" */
export type Meeting_Recording_Max_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meetingId?: InputMaybe<Order_By>;
  participantId?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Meeting_Recording_Min_Fields = {
  __typename?: 'meeting_recording_min_fields';
  fileId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  meetingId?: Maybe<Scalars['uuid']>;
  participantId?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "meeting_recording" */
export type Meeting_Recording_Min_Order_By = {
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meetingId?: InputMaybe<Order_By>;
  participantId?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "meeting_recording" */
export type Meeting_Recording_Mutation_Response = {
  __typename?: 'meeting_recording_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Meeting_Recording>;
};

/** on_conflict condition type for table "meeting_recording" */
export type Meeting_Recording_On_Conflict = {
  constraint: Meeting_Recording_Constraint;
  update_columns?: Array<Meeting_Recording_Update_Column>;
  where?: InputMaybe<Meeting_Recording_Bool_Exp>;
};

/** Ordering options when selecting data from "meeting_recording". */
export type Meeting_Recording_Order_By = {
  file?: InputMaybe<File_Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting?: InputMaybe<Meeting_Order_By>;
  meetingId?: InputMaybe<Order_By>;
  participant?: InputMaybe<Meeting_Participant_Order_By>;
  participantId?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: meeting_recording */
export type Meeting_Recording_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "meeting_recording" */
export enum Meeting_Recording_Select_Column {
  /** column name */
  FileId = 'fileId',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meetingId',
  /** column name */
  ParticipantId = 'participantId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "meeting_recording" */
export type Meeting_Recording_Set_Input = {
  fileId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "meeting_recording" */
export type Meeting_Recording_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Meeting_Recording_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Meeting_Recording_Stream_Cursor_Value_Input = {
  fileId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  meetingId?: InputMaybe<Scalars['uuid']>;
  participantId?: InputMaybe<Scalars['uuid']>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "meeting_recording" */
export enum Meeting_Recording_Update_Column {
  /** column name */
  FileId = 'fileId'
}

export type Meeting_Recording_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Meeting_Recording_Set_Input>;
  /** filter the rows which have to be updated */
  where: Meeting_Recording_Bool_Exp;
};

/** select columns of table "meeting" */
export enum Meeting_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationType = 'integrationType',
  /** column name */
  ScheduledDuration = 'scheduledDuration',
  /** column name */
  ScheduledStart = 'scheduledStart',
  /** column name */
  Topic = 'topic',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** aggregate stddev on columns */
export type Meeting_Stddev_Fields = {
  __typename?: 'meeting_stddev_fields';
  /** A computed field, executes function "get_meeting_actual_duration" */
  actualDuration?: Maybe<Scalars['float8']>;
  scheduledDuration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Meeting_Stddev_Pop_Fields = {
  __typename?: 'meeting_stddev_pop_fields';
  /** A computed field, executes function "get_meeting_actual_duration" */
  actualDuration?: Maybe<Scalars['float8']>;
  scheduledDuration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Meeting_Stddev_Samp_Fields = {
  __typename?: 'meeting_stddev_samp_fields';
  /** A computed field, executes function "get_meeting_actual_duration" */
  actualDuration?: Maybe<Scalars['float8']>;
  scheduledDuration?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "meeting" */
export type Meeting_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Meeting_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Meeting_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  integrationType?: InputMaybe<Integration_Type_Enum>;
  scheduledDuration?: InputMaybe<Scalars['Int']>;
  scheduledStart?: InputMaybe<Scalars['timestamptz']>;
  topic?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Meeting_Sum_Fields = {
  __typename?: 'meeting_sum_fields';
  /** A computed field, executes function "get_meeting_actual_duration" */
  actualDuration?: Maybe<Scalars['float8']>;
  scheduledDuration?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "meeting_transcription" */
export type Meeting_Transcription = {
  __typename?: 'meeting_transcription';
  endTime: Scalars['numeric'];
  id: Scalars['uuid'];
  language: Scalars['String'];
  /** An object relationship */
  meeting: Meeting;
  meetingId: Scalars['uuid'];
  /** An object relationship */
  participant: Meeting_Participant;
  participantId: Scalars['uuid'];
  startTime: Scalars['numeric'];
  verse: Scalars['String'];
};

/** aggregated selection of "meeting_transcription" */
export type Meeting_Transcription_Aggregate = {
  __typename?: 'meeting_transcription_aggregate';
  aggregate?: Maybe<Meeting_Transcription_Aggregate_Fields>;
  nodes: Array<Meeting_Transcription>;
};

export type Meeting_Transcription_Aggregate_Bool_Exp = {
  count?: InputMaybe<Meeting_Transcription_Aggregate_Bool_Exp_Count>;
};

export type Meeting_Transcription_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Meeting_Transcription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Meeting_Transcription_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "meeting_transcription" */
export type Meeting_Transcription_Aggregate_Fields = {
  __typename?: 'meeting_transcription_aggregate_fields';
  avg?: Maybe<Meeting_Transcription_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Meeting_Transcription_Max_Fields>;
  min?: Maybe<Meeting_Transcription_Min_Fields>;
  stddev?: Maybe<Meeting_Transcription_Stddev_Fields>;
  stddev_pop?: Maybe<Meeting_Transcription_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Meeting_Transcription_Stddev_Samp_Fields>;
  sum?: Maybe<Meeting_Transcription_Sum_Fields>;
  var_pop?: Maybe<Meeting_Transcription_Var_Pop_Fields>;
  var_samp?: Maybe<Meeting_Transcription_Var_Samp_Fields>;
  variance?: Maybe<Meeting_Transcription_Variance_Fields>;
};


/** aggregate fields of "meeting_transcription" */
export type Meeting_Transcription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Meeting_Transcription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "meeting_transcription" */
export type Meeting_Transcription_Aggregate_Order_By = {
  avg?: InputMaybe<Meeting_Transcription_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Meeting_Transcription_Max_Order_By>;
  min?: InputMaybe<Meeting_Transcription_Min_Order_By>;
  stddev?: InputMaybe<Meeting_Transcription_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Meeting_Transcription_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Meeting_Transcription_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Meeting_Transcription_Sum_Order_By>;
  var_pop?: InputMaybe<Meeting_Transcription_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Meeting_Transcription_Var_Samp_Order_By>;
  variance?: InputMaybe<Meeting_Transcription_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Meeting_Transcription_Avg_Fields = {
  __typename?: 'meeting_transcription_avg_fields';
  endTime?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "meeting_transcription" */
export type Meeting_Transcription_Avg_Order_By = {
  endTime?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "meeting_transcription". All fields are combined with a logical 'AND'. */
export type Meeting_Transcription_Bool_Exp = {
  _and?: InputMaybe<Array<Meeting_Transcription_Bool_Exp>>;
  _not?: InputMaybe<Meeting_Transcription_Bool_Exp>;
  _or?: InputMaybe<Array<Meeting_Transcription_Bool_Exp>>;
  endTime?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  meeting?: InputMaybe<Meeting_Bool_Exp>;
  meetingId?: InputMaybe<Uuid_Comparison_Exp>;
  participant?: InputMaybe<Meeting_Participant_Bool_Exp>;
  participantId?: InputMaybe<Uuid_Comparison_Exp>;
  startTime?: InputMaybe<Numeric_Comparison_Exp>;
  verse?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Meeting_Transcription_Max_Fields = {
  __typename?: 'meeting_transcription_max_fields';
  endTime?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  meetingId?: Maybe<Scalars['uuid']>;
  participantId?: Maybe<Scalars['uuid']>;
  startTime?: Maybe<Scalars['numeric']>;
  verse?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "meeting_transcription" */
export type Meeting_Transcription_Max_Order_By = {
  endTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  meetingId?: InputMaybe<Order_By>;
  participantId?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
  verse?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Meeting_Transcription_Min_Fields = {
  __typename?: 'meeting_transcription_min_fields';
  endTime?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  meetingId?: Maybe<Scalars['uuid']>;
  participantId?: Maybe<Scalars['uuid']>;
  startTime?: Maybe<Scalars['numeric']>;
  verse?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "meeting_transcription" */
export type Meeting_Transcription_Min_Order_By = {
  endTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  meetingId?: InputMaybe<Order_By>;
  participantId?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
  verse?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "meeting_transcription". */
export type Meeting_Transcription_Order_By = {
  endTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  meeting?: InputMaybe<Meeting_Order_By>;
  meetingId?: InputMaybe<Order_By>;
  participant?: InputMaybe<Meeting_Participant_Order_By>;
  participantId?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
  verse?: InputMaybe<Order_By>;
};

/** select columns of table "meeting_transcription" */
export enum Meeting_Transcription_Select_Column {
  /** column name */
  EndTime = 'endTime',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  MeetingId = 'meetingId',
  /** column name */
  ParticipantId = 'participantId',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  Verse = 'verse'
}

/** aggregate stddev on columns */
export type Meeting_Transcription_Stddev_Fields = {
  __typename?: 'meeting_transcription_stddev_fields';
  endTime?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "meeting_transcription" */
export type Meeting_Transcription_Stddev_Order_By = {
  endTime?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Meeting_Transcription_Stddev_Pop_Fields = {
  __typename?: 'meeting_transcription_stddev_pop_fields';
  endTime?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "meeting_transcription" */
export type Meeting_Transcription_Stddev_Pop_Order_By = {
  endTime?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Meeting_Transcription_Stddev_Samp_Fields = {
  __typename?: 'meeting_transcription_stddev_samp_fields';
  endTime?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "meeting_transcription" */
export type Meeting_Transcription_Stddev_Samp_Order_By = {
  endTime?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "meeting_transcription" */
export type Meeting_Transcription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Meeting_Transcription_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Meeting_Transcription_Stream_Cursor_Value_Input = {
  endTime?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Scalars['String']>;
  meetingId?: InputMaybe<Scalars['uuid']>;
  participantId?: InputMaybe<Scalars['uuid']>;
  startTime?: InputMaybe<Scalars['numeric']>;
  verse?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Meeting_Transcription_Sum_Fields = {
  __typename?: 'meeting_transcription_sum_fields';
  endTime?: Maybe<Scalars['numeric']>;
  startTime?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "meeting_transcription" */
export type Meeting_Transcription_Sum_Order_By = {
  endTime?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Meeting_Transcription_Var_Pop_Fields = {
  __typename?: 'meeting_transcription_var_pop_fields';
  endTime?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "meeting_transcription" */
export type Meeting_Transcription_Var_Pop_Order_By = {
  endTime?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Meeting_Transcription_Var_Samp_Fields = {
  __typename?: 'meeting_transcription_var_samp_fields';
  endTime?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "meeting_transcription" */
export type Meeting_Transcription_Var_Samp_Order_By = {
  endTime?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Meeting_Transcription_Variance_Fields = {
  __typename?: 'meeting_transcription_variance_fields';
  endTime?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "meeting_transcription" */
export type Meeting_Transcription_Variance_Order_By = {
  endTime?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
};

/** columns and relationships of "meeting_type" */
export type Meeting_Type = {
  __typename?: 'meeting_type';
  /** An object relationship */
  meeting: Meeting;
  meetingId: Scalars['uuid'];
  /** An object relationship */
  meetingTypeDefinition: Meeting_Type_Definition;
  meetingTypeDefinitionId: Scalars['String'];
};

/** aggregated selection of "meeting_type" */
export type Meeting_Type_Aggregate = {
  __typename?: 'meeting_type_aggregate';
  aggregate?: Maybe<Meeting_Type_Aggregate_Fields>;
  nodes: Array<Meeting_Type>;
};

export type Meeting_Type_Aggregate_Bool_Exp = {
  count?: InputMaybe<Meeting_Type_Aggregate_Bool_Exp_Count>;
};

export type Meeting_Type_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Meeting_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Meeting_Type_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "meeting_type" */
export type Meeting_Type_Aggregate_Fields = {
  __typename?: 'meeting_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Meeting_Type_Max_Fields>;
  min?: Maybe<Meeting_Type_Min_Fields>;
};


/** aggregate fields of "meeting_type" */
export type Meeting_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Meeting_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "meeting_type" */
export type Meeting_Type_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Meeting_Type_Max_Order_By>;
  min?: InputMaybe<Meeting_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "meeting_type" */
export type Meeting_Type_Arr_Rel_Insert_Input = {
  data: Array<Meeting_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Meeting_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "meeting_type". All fields are combined with a logical 'AND'. */
export type Meeting_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Meeting_Type_Bool_Exp>>;
  _not?: InputMaybe<Meeting_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Meeting_Type_Bool_Exp>>;
  meeting?: InputMaybe<Meeting_Bool_Exp>;
  meetingId?: InputMaybe<Uuid_Comparison_Exp>;
  meetingTypeDefinition?: InputMaybe<Meeting_Type_Definition_Bool_Exp>;
  meetingTypeDefinitionId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "meeting_type" */
export enum Meeting_Type_Constraint {
  /** unique or primary key constraint on columns "meeting_id", "meeting_type_definition_id" */
  MeetingTypePkey = 'meeting_type_pkey'
}

/** columns and relationships of "meeting_type_definition" */
export type Meeting_Type_Definition = {
  __typename?: 'meeting_type_definition';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  shortDescription?: Maybe<Scalars['String']>;
  /** An array relationship */
  systemPrompts: Array<Meeting_Type_Definition_System_Prompt>;
  /** An aggregate relationship */
  systemPrompts_aggregate: Meeting_Type_Definition_System_Prompt_Aggregate;
};


/** columns and relationships of "meeting_type_definition" */
export type Meeting_Type_DefinitionSystemPromptsArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Order_By>>;
  where?: InputMaybe<Meeting_Type_Definition_System_Prompt_Bool_Exp>;
};


/** columns and relationships of "meeting_type_definition" */
export type Meeting_Type_DefinitionSystemPrompts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Order_By>>;
  where?: InputMaybe<Meeting_Type_Definition_System_Prompt_Bool_Exp>;
};

/** aggregated selection of "meeting_type_definition" */
export type Meeting_Type_Definition_Aggregate = {
  __typename?: 'meeting_type_definition_aggregate';
  aggregate?: Maybe<Meeting_Type_Definition_Aggregate_Fields>;
  nodes: Array<Meeting_Type_Definition>;
};

/** aggregate fields of "meeting_type_definition" */
export type Meeting_Type_Definition_Aggregate_Fields = {
  __typename?: 'meeting_type_definition_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Meeting_Type_Definition_Max_Fields>;
  min?: Maybe<Meeting_Type_Definition_Min_Fields>;
};


/** aggregate fields of "meeting_type_definition" */
export type Meeting_Type_Definition_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Meeting_Type_Definition_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "meeting_type_definition". All fields are combined with a logical 'AND'. */
export type Meeting_Type_Definition_Bool_Exp = {
  _and?: InputMaybe<Array<Meeting_Type_Definition_Bool_Exp>>;
  _not?: InputMaybe<Meeting_Type_Definition_Bool_Exp>;
  _or?: InputMaybe<Array<Meeting_Type_Definition_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  shortDescription?: InputMaybe<String_Comparison_Exp>;
  systemPrompts?: InputMaybe<Meeting_Type_Definition_System_Prompt_Bool_Exp>;
  systemPrompts_aggregate?: InputMaybe<Meeting_Type_Definition_System_Prompt_Aggregate_Bool_Exp>;
};

/** aggregate max on columns */
export type Meeting_Type_Definition_Max_Fields = {
  __typename?: 'meeting_type_definition_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Meeting_Type_Definition_Min_Fields = {
  __typename?: 'meeting_type_definition_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "meeting_type_definition". */
export type Meeting_Type_Definition_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  shortDescription?: InputMaybe<Order_By>;
  systemPrompts_aggregate?: InputMaybe<Meeting_Type_Definition_System_Prompt_Aggregate_Order_By>;
};

/** select columns of table "meeting_type_definition" */
export enum Meeting_Type_Definition_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ShortDescription = 'shortDescription'
}

/** Streaming cursor of the table "meeting_type_definition" */
export type Meeting_Type_Definition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Meeting_Type_Definition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Meeting_Type_Definition_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  shortDescription?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "meeting_type_definition_system_prompt" */
export type Meeting_Type_Definition_System_Prompt = {
  __typename?: 'meeting_type_definition_system_prompt';
  /** An object relationship */
  meetingTypeDefinition: Meeting_Type_Definition;
  meetingTypeDefinitionId: Scalars['String'];
  /** An object relationship */
  systemPrompt: System_Prompt;
  systemPromptId: Scalars['String'];
};

/** aggregated selection of "meeting_type_definition_system_prompt" */
export type Meeting_Type_Definition_System_Prompt_Aggregate = {
  __typename?: 'meeting_type_definition_system_prompt_aggregate';
  aggregate?: Maybe<Meeting_Type_Definition_System_Prompt_Aggregate_Fields>;
  nodes: Array<Meeting_Type_Definition_System_Prompt>;
};

export type Meeting_Type_Definition_System_Prompt_Aggregate_Bool_Exp = {
  count?: InputMaybe<Meeting_Type_Definition_System_Prompt_Aggregate_Bool_Exp_Count>;
};

export type Meeting_Type_Definition_System_Prompt_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Meeting_Type_Definition_System_Prompt_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "meeting_type_definition_system_prompt" */
export type Meeting_Type_Definition_System_Prompt_Aggregate_Fields = {
  __typename?: 'meeting_type_definition_system_prompt_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Meeting_Type_Definition_System_Prompt_Max_Fields>;
  min?: Maybe<Meeting_Type_Definition_System_Prompt_Min_Fields>;
};


/** aggregate fields of "meeting_type_definition_system_prompt" */
export type Meeting_Type_Definition_System_Prompt_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "meeting_type_definition_system_prompt" */
export type Meeting_Type_Definition_System_Prompt_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Meeting_Type_Definition_System_Prompt_Max_Order_By>;
  min?: InputMaybe<Meeting_Type_Definition_System_Prompt_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "meeting_type_definition_system_prompt". All fields are combined with a logical 'AND'. */
export type Meeting_Type_Definition_System_Prompt_Bool_Exp = {
  _and?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Bool_Exp>>;
  _not?: InputMaybe<Meeting_Type_Definition_System_Prompt_Bool_Exp>;
  _or?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Bool_Exp>>;
  meetingTypeDefinition?: InputMaybe<Meeting_Type_Definition_Bool_Exp>;
  meetingTypeDefinitionId?: InputMaybe<String_Comparison_Exp>;
  systemPrompt?: InputMaybe<System_Prompt_Bool_Exp>;
  systemPromptId?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Meeting_Type_Definition_System_Prompt_Max_Fields = {
  __typename?: 'meeting_type_definition_system_prompt_max_fields';
  meetingTypeDefinitionId?: Maybe<Scalars['String']>;
  systemPromptId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "meeting_type_definition_system_prompt" */
export type Meeting_Type_Definition_System_Prompt_Max_Order_By = {
  meetingTypeDefinitionId?: InputMaybe<Order_By>;
  systemPromptId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Meeting_Type_Definition_System_Prompt_Min_Fields = {
  __typename?: 'meeting_type_definition_system_prompt_min_fields';
  meetingTypeDefinitionId?: Maybe<Scalars['String']>;
  systemPromptId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "meeting_type_definition_system_prompt" */
export type Meeting_Type_Definition_System_Prompt_Min_Order_By = {
  meetingTypeDefinitionId?: InputMaybe<Order_By>;
  systemPromptId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "meeting_type_definition_system_prompt". */
export type Meeting_Type_Definition_System_Prompt_Order_By = {
  meetingTypeDefinition?: InputMaybe<Meeting_Type_Definition_Order_By>;
  meetingTypeDefinitionId?: InputMaybe<Order_By>;
  systemPrompt?: InputMaybe<System_Prompt_Order_By>;
  systemPromptId?: InputMaybe<Order_By>;
};

/** select columns of table "meeting_type_definition_system_prompt" */
export enum Meeting_Type_Definition_System_Prompt_Select_Column {
  /** column name */
  MeetingTypeDefinitionId = 'meetingTypeDefinitionId',
  /** column name */
  SystemPromptId = 'systemPromptId'
}

/** Streaming cursor of the table "meeting_type_definition_system_prompt" */
export type Meeting_Type_Definition_System_Prompt_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Meeting_Type_Definition_System_Prompt_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Meeting_Type_Definition_System_Prompt_Stream_Cursor_Value_Input = {
  meetingTypeDefinitionId?: InputMaybe<Scalars['String']>;
  systemPromptId?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "meeting_type" */
export type Meeting_Type_Insert_Input = {
  meeting?: InputMaybe<Meeting_Obj_Rel_Insert_Input>;
  meetingId?: InputMaybe<Scalars['uuid']>;
  meetingTypeDefinitionId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Meeting_Type_Max_Fields = {
  __typename?: 'meeting_type_max_fields';
  meetingId?: Maybe<Scalars['uuid']>;
  meetingTypeDefinitionId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "meeting_type" */
export type Meeting_Type_Max_Order_By = {
  meetingId?: InputMaybe<Order_By>;
  meetingTypeDefinitionId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Meeting_Type_Min_Fields = {
  __typename?: 'meeting_type_min_fields';
  meetingId?: Maybe<Scalars['uuid']>;
  meetingTypeDefinitionId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "meeting_type" */
export type Meeting_Type_Min_Order_By = {
  meetingId?: InputMaybe<Order_By>;
  meetingTypeDefinitionId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "meeting_type" */
export type Meeting_Type_Mutation_Response = {
  __typename?: 'meeting_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Meeting_Type>;
};

/** on_conflict condition type for table "meeting_type" */
export type Meeting_Type_On_Conflict = {
  constraint: Meeting_Type_Constraint;
  update_columns?: Array<Meeting_Type_Update_Column>;
  where?: InputMaybe<Meeting_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "meeting_type". */
export type Meeting_Type_Order_By = {
  meeting?: InputMaybe<Meeting_Order_By>;
  meetingId?: InputMaybe<Order_By>;
  meetingTypeDefinition?: InputMaybe<Meeting_Type_Definition_Order_By>;
  meetingTypeDefinitionId?: InputMaybe<Order_By>;
};

/** select columns of table "meeting_type" */
export enum Meeting_Type_Select_Column {
  /** column name */
  MeetingId = 'meetingId',
  /** column name */
  MeetingTypeDefinitionId = 'meetingTypeDefinitionId'
}

/** Streaming cursor of the table "meeting_type" */
export type Meeting_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Meeting_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Meeting_Type_Stream_Cursor_Value_Input = {
  meetingId?: InputMaybe<Scalars['uuid']>;
  meetingTypeDefinitionId?: InputMaybe<Scalars['String']>;
};

/** placeholder for update columns of table "meeting_type" (current role has no relevant permissions) */
export enum Meeting_Type_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** placeholder for update columns of table "meeting" (current role has no relevant permissions) */
export enum Meeting_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** aggregate var_pop on columns */
export type Meeting_Var_Pop_Fields = {
  __typename?: 'meeting_var_pop_fields';
  /** A computed field, executes function "get_meeting_actual_duration" */
  actualDuration?: Maybe<Scalars['float8']>;
  scheduledDuration?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Meeting_Var_Samp_Fields = {
  __typename?: 'meeting_var_samp_fields';
  /** A computed field, executes function "get_meeting_actual_duration" */
  actualDuration?: Maybe<Scalars['float8']>;
  scheduledDuration?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Meeting_Variance_Fields = {
  __typename?: 'meeting_variance_fields';
  /** A computed field, executes function "get_meeting_actual_duration" */
  actualDuration?: Maybe<Scalars['float8']>;
  scheduledDuration?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "meeting_prompt_output" */
  delete_meeting_prompt_output?: Maybe<Meeting_Prompt_Output_Mutation_Response>;
  /** delete single row from the table: "meeting_prompt_output" */
  delete_meeting_prompt_output_by_pk?: Maybe<Meeting_Prompt_Output>;
  /** delete data from the table: "meeting_type" */
  delete_meeting_type?: Maybe<Meeting_Type_Mutation_Response>;
  /** delete single row from the table: "meeting_type" */
  delete_meeting_type_by_pk?: Maybe<Meeting_Type>;
  /** delete data from the table: "prompt" */
  delete_prompt?: Maybe<Prompt_Mutation_Response>;
  /** delete single row from the table: "prompt" */
  delete_prompt_by_pk?: Maybe<Prompt>;
  /** delete data from the table: "user_integration" */
  delete_user_integration?: Maybe<User_Integration_Mutation_Response>;
  /** delete single row from the table: "user_integration" */
  delete_user_integration_by_pk?: Maybe<User_Integration>;
  /** insert data into the table: "meeting" */
  insert_meeting?: Maybe<Meeting_Mutation_Response>;
  /** insert a single row into the table: "meeting" */
  insert_meeting_one?: Maybe<Meeting>;
  /** insert data into the table: "meeting_participant" */
  insert_meeting_participant?: Maybe<Meeting_Participant_Mutation_Response>;
  /** insert a single row into the table: "meeting_participant" */
  insert_meeting_participant_one?: Maybe<Meeting_Participant>;
  /** insert data into the table: "meeting_prompt_output" */
  insert_meeting_prompt_output?: Maybe<Meeting_Prompt_Output_Mutation_Response>;
  /** insert a single row into the table: "meeting_prompt_output" */
  insert_meeting_prompt_output_one?: Maybe<Meeting_Prompt_Output>;
  /** insert data into the table: "meeting_recording" */
  insert_meeting_recording?: Maybe<Meeting_Recording_Mutation_Response>;
  /** insert a single row into the table: "meeting_recording" */
  insert_meeting_recording_one?: Maybe<Meeting_Recording>;
  /** insert data into the table: "meeting_type" */
  insert_meeting_type?: Maybe<Meeting_Type_Mutation_Response>;
  /** insert a single row into the table: "meeting_type" */
  insert_meeting_type_one?: Maybe<Meeting_Type>;
  /** insert data into the table: "profile" */
  insert_profile?: Maybe<Profile_Mutation_Response>;
  /** insert a single row into the table: "profile" */
  insert_profile_one?: Maybe<Profile>;
  /** insert data into the table: "profile_stem" */
  insert_profile_stem?: Maybe<Profile_Stem_Mutation_Response>;
  /** insert a single row into the table: "profile_stem" */
  insert_profile_stem_one?: Maybe<Profile_Stem>;
  /** insert data into the table: "prompt" */
  insert_prompt?: Maybe<Prompt_Mutation_Response>;
  /** insert a single row into the table: "prompt" */
  insert_prompt_one?: Maybe<Prompt>;
  /** insert data into the table: "prompt_workspace" */
  insert_prompt_workspace?: Maybe<Prompt_Workspace_Mutation_Response>;
  /** insert a single row into the table: "prompt_workspace" */
  insert_prompt_workspace_one?: Maybe<Prompt_Workspace>;
  installUserIntegration?: Maybe<InstallUserIntegrationOutput>;
  /** update data of the table: "meeting_participant" */
  update_meeting_participant?: Maybe<Meeting_Participant_Mutation_Response>;
  /** update single row of the table: "meeting_participant" */
  update_meeting_participant_by_pk?: Maybe<Meeting_Participant>;
  /** update multiples rows of table: "meeting_participant" */
  update_meeting_participant_many?: Maybe<Array<Maybe<Meeting_Participant_Mutation_Response>>>;
  /** update data of the table: "meeting_prompt_output" */
  update_meeting_prompt_output?: Maybe<Meeting_Prompt_Output_Mutation_Response>;
  /** update single row of the table: "meeting_prompt_output" */
  update_meeting_prompt_output_by_pk?: Maybe<Meeting_Prompt_Output>;
  /** update multiples rows of table: "meeting_prompt_output" */
  update_meeting_prompt_output_many?: Maybe<Array<Maybe<Meeting_Prompt_Output_Mutation_Response>>>;
  /** update data of the table: "meeting_recording" */
  update_meeting_recording?: Maybe<Meeting_Recording_Mutation_Response>;
  /** update single row of the table: "meeting_recording" */
  update_meeting_recording_by_pk?: Maybe<Meeting_Recording>;
  /** update multiples rows of table: "meeting_recording" */
  update_meeting_recording_many?: Maybe<Array<Maybe<Meeting_Recording_Mutation_Response>>>;
  /** update data of the table: "prompt" */
  update_prompt?: Maybe<Prompt_Mutation_Response>;
  /** update single row of the table: "prompt" */
  update_prompt_by_pk?: Maybe<Prompt>;
  /** update multiples rows of table: "prompt" */
  update_prompt_many?: Maybe<Array<Maybe<Prompt_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_Meeting_Prompt_OutputArgs = {
  where: Meeting_Prompt_Output_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Meeting_Prompt_Output_By_PkArgs = {
  meetingId: Scalars['uuid'];
  promptId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Meeting_TypeArgs = {
  where: Meeting_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Meeting_Type_By_PkArgs = {
  meetingId: Scalars['uuid'];
  meetingTypeDefinitionId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_PromptArgs = {
  where: Prompt_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Prompt_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_IntegrationArgs = {
  where: User_Integration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Integration_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_MeetingArgs = {
  objects: Array<Meeting_Insert_Input>;
  on_conflict?: InputMaybe<Meeting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Meeting_OneArgs = {
  object: Meeting_Insert_Input;
  on_conflict?: InputMaybe<Meeting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Meeting_ParticipantArgs = {
  objects: Array<Meeting_Participant_Insert_Input>;
  on_conflict?: InputMaybe<Meeting_Participant_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Meeting_Participant_OneArgs = {
  object: Meeting_Participant_Insert_Input;
  on_conflict?: InputMaybe<Meeting_Participant_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Meeting_Prompt_OutputArgs = {
  objects: Array<Meeting_Prompt_Output_Insert_Input>;
  on_conflict?: InputMaybe<Meeting_Prompt_Output_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Meeting_Prompt_Output_OneArgs = {
  object: Meeting_Prompt_Output_Insert_Input;
  on_conflict?: InputMaybe<Meeting_Prompt_Output_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Meeting_RecordingArgs = {
  objects: Array<Meeting_Recording_Insert_Input>;
  on_conflict?: InputMaybe<Meeting_Recording_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Meeting_Recording_OneArgs = {
  object: Meeting_Recording_Insert_Input;
  on_conflict?: InputMaybe<Meeting_Recording_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Meeting_TypeArgs = {
  objects: Array<Meeting_Type_Insert_Input>;
  on_conflict?: InputMaybe<Meeting_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Meeting_Type_OneArgs = {
  object: Meeting_Type_Insert_Input;
  on_conflict?: InputMaybe<Meeting_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProfileArgs = {
  objects: Array<Profile_Insert_Input>;
  on_conflict?: InputMaybe<Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_OneArgs = {
  object: Profile_Insert_Input;
  on_conflict?: InputMaybe<Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_StemArgs = {
  objects: Array<Profile_Stem_Insert_Input>;
  on_conflict?: InputMaybe<Profile_Stem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Stem_OneArgs = {
  object: Profile_Stem_Insert_Input;
  on_conflict?: InputMaybe<Profile_Stem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PromptArgs = {
  objects: Array<Prompt_Insert_Input>;
  on_conflict?: InputMaybe<Prompt_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Prompt_OneArgs = {
  object: Prompt_Insert_Input;
  on_conflict?: InputMaybe<Prompt_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Prompt_WorkspaceArgs = {
  objects: Array<Prompt_Workspace_Insert_Input>;
  on_conflict?: InputMaybe<Prompt_Workspace_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Prompt_Workspace_OneArgs = {
  object: Prompt_Workspace_Insert_Input;
  on_conflict?: InputMaybe<Prompt_Workspace_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInstallUserIntegrationArgs = {
  payload: Scalars['jsonb'];
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_Meeting_ParticipantArgs = {
  _set?: InputMaybe<Meeting_Participant_Set_Input>;
  where: Meeting_Participant_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Meeting_Participant_By_PkArgs = {
  _set?: InputMaybe<Meeting_Participant_Set_Input>;
  pk_columns: Meeting_Participant_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Meeting_Participant_ManyArgs = {
  updates: Array<Meeting_Participant_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Meeting_Prompt_OutputArgs = {
  _set?: InputMaybe<Meeting_Prompt_Output_Set_Input>;
  where: Meeting_Prompt_Output_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Meeting_Prompt_Output_By_PkArgs = {
  _set?: InputMaybe<Meeting_Prompt_Output_Set_Input>;
  pk_columns: Meeting_Prompt_Output_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Meeting_Prompt_Output_ManyArgs = {
  updates: Array<Meeting_Prompt_Output_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Meeting_RecordingArgs = {
  _set?: InputMaybe<Meeting_Recording_Set_Input>;
  where: Meeting_Recording_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Meeting_Recording_By_PkArgs = {
  _set?: InputMaybe<Meeting_Recording_Set_Input>;
  pk_columns: Meeting_Recording_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Meeting_Recording_ManyArgs = {
  updates: Array<Meeting_Recording_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PromptArgs = {
  _set?: InputMaybe<Prompt_Set_Input>;
  where: Prompt_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Prompt_By_PkArgs = {
  _set?: InputMaybe<Prompt_Set_Input>;
  pk_columns: Prompt_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Prompt_ManyArgs = {
  updates: Array<Prompt_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "profile" */
export type Profile = {
  __typename?: 'profile';
  email?: Maybe<Scalars['String']>;
  externalId: Scalars['String'];
  /** An object relationship */
  file?: Maybe<File>;
  id: Scalars['uuid'];
  integrationType: Integration_Type_Enum;
  name: Scalars['String'];
  pictureFileId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  profileStem: Profile_Stem;
  profileStemId: Scalars['uuid'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "profile" */
export type Profile_Aggregate = {
  __typename?: 'profile_aggregate';
  aggregate?: Maybe<Profile_Aggregate_Fields>;
  nodes: Array<Profile>;
};

export type Profile_Aggregate_Bool_Exp = {
  count?: InputMaybe<Profile_Aggregate_Bool_Exp_Count>;
};

export type Profile_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Profile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Profile_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "profile" */
export type Profile_Aggregate_Fields = {
  __typename?: 'profile_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Profile_Max_Fields>;
  min?: Maybe<Profile_Min_Fields>;
};


/** aggregate fields of "profile" */
export type Profile_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Profile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "profile" */
export type Profile_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Profile_Max_Order_By>;
  min?: InputMaybe<Profile_Min_Order_By>;
};

/** input type for inserting array relation for remote table "profile" */
export type Profile_Arr_Rel_Insert_Input = {
  data: Array<Profile_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Profile_On_Conflict>;
};

/** Boolean expression to filter rows from the table "profile". All fields are combined with a logical 'AND'. */
export type Profile_Bool_Exp = {
  _and?: InputMaybe<Array<Profile_Bool_Exp>>;
  _not?: InputMaybe<Profile_Bool_Exp>;
  _or?: InputMaybe<Array<Profile_Bool_Exp>>;
  email?: InputMaybe<String_Comparison_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  file?: InputMaybe<File_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  integrationType?: InputMaybe<Integration_Type_Enum_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pictureFileId?: InputMaybe<Uuid_Comparison_Exp>;
  profileStem?: InputMaybe<Profile_Stem_Bool_Exp>;
  profileStemId?: InputMaybe<Uuid_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile" */
export enum Profile_Constraint {
  /** unique or primary key constraint on columns "external_id", "integration_type" */
  ProfileIntegrationTypeExternalIdKey = 'profile_integration_type_external_id_key',
  /** unique or primary key constraint on columns "id" */
  ProfilePkey = 'profile_pkey'
}

/** input type for inserting data into table "profile" */
export type Profile_Insert_Input = {
  externalId?: InputMaybe<Scalars['String']>;
  integrationType?: InputMaybe<Integration_Type_Enum>;
  name?: InputMaybe<Scalars['String']>;
  profileStem?: InputMaybe<Profile_Stem_Obj_Rel_Insert_Input>;
  profileStemId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Profile_Max_Fields = {
  __typename?: 'profile_max_fields';
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  pictureFileId?: Maybe<Scalars['uuid']>;
  profileStemId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "profile" */
export type Profile_Max_Order_By = {
  email?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pictureFileId?: InputMaybe<Order_By>;
  profileStemId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Min_Fields = {
  __typename?: 'profile_min_fields';
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  pictureFileId?: Maybe<Scalars['uuid']>;
  profileStemId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "profile" */
export type Profile_Min_Order_By = {
  email?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pictureFileId?: InputMaybe<Order_By>;
  profileStemId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "profile" */
export type Profile_Mutation_Response = {
  __typename?: 'profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile>;
};

/** input type for inserting object relation for remote table "profile" */
export type Profile_Obj_Rel_Insert_Input = {
  data: Profile_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Profile_On_Conflict>;
};

/** on_conflict condition type for table "profile" */
export type Profile_On_Conflict = {
  constraint: Profile_Constraint;
  update_columns?: Array<Profile_Update_Column>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** Ordering options when selecting data from "profile". */
export type Profile_Order_By = {
  email?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  file?: InputMaybe<File_Order_By>;
  id?: InputMaybe<Order_By>;
  integrationType?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pictureFileId?: InputMaybe<Order_By>;
  profileStem?: InputMaybe<Profile_Stem_Order_By>;
  profileStemId?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** select columns of table "profile" */
export enum Profile_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationType = 'integrationType',
  /** column name */
  Name = 'name',
  /** column name */
  PictureFileId = 'pictureFileId',
  /** column name */
  ProfileStemId = 'profileStemId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** columns and relationships of "profile_stem" */
export type Profile_Stem = {
  __typename?: 'profile_stem';
  id: Scalars['uuid'];
  /** An array relationship */
  profiles: Array<Profile>;
  /** An aggregate relationship */
  profiles_aggregate: Profile_Aggregate;
};


/** columns and relationships of "profile_stem" */
export type Profile_StemProfilesArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


/** columns and relationships of "profile_stem" */
export type Profile_StemProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** aggregated selection of "profile_stem" */
export type Profile_Stem_Aggregate = {
  __typename?: 'profile_stem_aggregate';
  aggregate?: Maybe<Profile_Stem_Aggregate_Fields>;
  nodes: Array<Profile_Stem>;
};

/** aggregate fields of "profile_stem" */
export type Profile_Stem_Aggregate_Fields = {
  __typename?: 'profile_stem_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Profile_Stem_Max_Fields>;
  min?: Maybe<Profile_Stem_Min_Fields>;
};


/** aggregate fields of "profile_stem" */
export type Profile_Stem_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Profile_Stem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "profile_stem". All fields are combined with a logical 'AND'. */
export type Profile_Stem_Bool_Exp = {
  _and?: InputMaybe<Array<Profile_Stem_Bool_Exp>>;
  _not?: InputMaybe<Profile_Stem_Bool_Exp>;
  _or?: InputMaybe<Array<Profile_Stem_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  profiles?: InputMaybe<Profile_Bool_Exp>;
  profiles_aggregate?: InputMaybe<Profile_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "profile_stem" */
export enum Profile_Stem_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProfileStemPkey = 'profile_stem_pkey'
}

/** input type for inserting data into table "profile_stem" */
export type Profile_Stem_Insert_Input = {
  profiles?: InputMaybe<Profile_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Profile_Stem_Max_Fields = {
  __typename?: 'profile_stem_max_fields';
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Profile_Stem_Min_Fields = {
  __typename?: 'profile_stem_min_fields';
  id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "profile_stem" */
export type Profile_Stem_Mutation_Response = {
  __typename?: 'profile_stem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Stem>;
};

/** input type for inserting object relation for remote table "profile_stem" */
export type Profile_Stem_Obj_Rel_Insert_Input = {
  data: Profile_Stem_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Profile_Stem_On_Conflict>;
};

/** on_conflict condition type for table "profile_stem" */
export type Profile_Stem_On_Conflict = {
  constraint: Profile_Stem_Constraint;
  update_columns?: Array<Profile_Stem_Update_Column>;
  where?: InputMaybe<Profile_Stem_Bool_Exp>;
};

/** Ordering options when selecting data from "profile_stem". */
export type Profile_Stem_Order_By = {
  id?: InputMaybe<Order_By>;
  profiles_aggregate?: InputMaybe<Profile_Aggregate_Order_By>;
};

/** select columns of table "profile_stem" */
export enum Profile_Stem_Select_Column {
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "profile_stem" */
export type Profile_Stem_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Profile_Stem_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Profile_Stem_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
};

/** placeholder for update columns of table "profile_stem" (current role has no relevant permissions) */
export enum Profile_Stem_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** Streaming cursor of the table "profile" */
export type Profile_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Profile_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Profile_Stream_Cursor_Value_Input = {
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  integrationType?: InputMaybe<Integration_Type_Enum>;
  name?: InputMaybe<Scalars['String']>;
  pictureFileId?: InputMaybe<Scalars['uuid']>;
  profileStemId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** placeholder for update columns of table "profile" (current role has no relevant permissions) */
export enum Profile_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** columns and relationships of "prompt" */
export type Prompt = {
  __typename?: 'prompt';
  id: Scalars['uuid'];
  input: Scalars['String'];
  name: Scalars['String'];
  outputContentType: Content_Type_Enum;
  outputRenderer: Prompt_Output_Renderer_Enum;
  /** An array relationship */
  promptOutputs: Array<Meeting_Prompt_Output>;
  /** An aggregate relationship */
  promptOutputs_aggregate: Meeting_Prompt_Output_Aggregate;
  /** An array relationship */
  promptWorkspaces: Array<Prompt_Workspace>;
  /** An aggregate relationship */
  promptWorkspaces_aggregate: Prompt_Workspace_Aggregate;
  saved: Scalars['Boolean'];
  /** An array relationship */
  systemPrompts: Array<System_Prompt>;
  /** An aggregate relationship */
  systemPrompts_aggregate: System_Prompt_Aggregate;
};


/** columns and relationships of "prompt" */
export type PromptPromptOutputsArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Prompt_Output_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Prompt_Output_Order_By>>;
  where?: InputMaybe<Meeting_Prompt_Output_Bool_Exp>;
};


/** columns and relationships of "prompt" */
export type PromptPromptOutputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Prompt_Output_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Prompt_Output_Order_By>>;
  where?: InputMaybe<Meeting_Prompt_Output_Bool_Exp>;
};


/** columns and relationships of "prompt" */
export type PromptPromptWorkspacesArgs = {
  distinct_on?: InputMaybe<Array<Prompt_Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prompt_Workspace_Order_By>>;
  where?: InputMaybe<Prompt_Workspace_Bool_Exp>;
};


/** columns and relationships of "prompt" */
export type PromptPromptWorkspaces_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prompt_Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prompt_Workspace_Order_By>>;
  where?: InputMaybe<Prompt_Workspace_Bool_Exp>;
};


/** columns and relationships of "prompt" */
export type PromptSystemPromptsArgs = {
  distinct_on?: InputMaybe<Array<System_Prompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<System_Prompt_Order_By>>;
  where?: InputMaybe<System_Prompt_Bool_Exp>;
};


/** columns and relationships of "prompt" */
export type PromptSystemPrompts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<System_Prompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<System_Prompt_Order_By>>;
  where?: InputMaybe<System_Prompt_Bool_Exp>;
};

/** aggregated selection of "prompt" */
export type Prompt_Aggregate = {
  __typename?: 'prompt_aggregate';
  aggregate?: Maybe<Prompt_Aggregate_Fields>;
  nodes: Array<Prompt>;
};

/** aggregate fields of "prompt" */
export type Prompt_Aggregate_Fields = {
  __typename?: 'prompt_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Prompt_Max_Fields>;
  min?: Maybe<Prompt_Min_Fields>;
};


/** aggregate fields of "prompt" */
export type Prompt_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Prompt_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "prompt". All fields are combined with a logical 'AND'. */
export type Prompt_Bool_Exp = {
  _and?: InputMaybe<Array<Prompt_Bool_Exp>>;
  _not?: InputMaybe<Prompt_Bool_Exp>;
  _or?: InputMaybe<Array<Prompt_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  input?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  outputContentType?: InputMaybe<Content_Type_Enum_Comparison_Exp>;
  outputRenderer?: InputMaybe<Prompt_Output_Renderer_Enum_Comparison_Exp>;
  promptOutputs?: InputMaybe<Meeting_Prompt_Output_Bool_Exp>;
  promptOutputs_aggregate?: InputMaybe<Meeting_Prompt_Output_Aggregate_Bool_Exp>;
  promptWorkspaces?: InputMaybe<Prompt_Workspace_Bool_Exp>;
  promptWorkspaces_aggregate?: InputMaybe<Prompt_Workspace_Aggregate_Bool_Exp>;
  saved?: InputMaybe<Boolean_Comparison_Exp>;
  systemPrompts?: InputMaybe<System_Prompt_Bool_Exp>;
  systemPrompts_aggregate?: InputMaybe<System_Prompt_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "prompt" */
export enum Prompt_Constraint {
  /** unique or primary key constraint on columns "id" */
  PromptPkey = 'prompt_pkey'
}

/** input type for inserting data into table "prompt" */
export type Prompt_Insert_Input = {
  input?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  promptOutputs?: InputMaybe<Meeting_Prompt_Output_Arr_Rel_Insert_Input>;
  promptWorkspaces?: InputMaybe<Prompt_Workspace_Arr_Rel_Insert_Input>;
  saved?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Prompt_Max_Fields = {
  __typename?: 'prompt_max_fields';
  id?: Maybe<Scalars['uuid']>;
  input?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Prompt_Min_Fields = {
  __typename?: 'prompt_min_fields';
  id?: Maybe<Scalars['uuid']>;
  input?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "prompt" */
export type Prompt_Mutation_Response = {
  __typename?: 'prompt_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Prompt>;
};

/** input type for inserting object relation for remote table "prompt" */
export type Prompt_Obj_Rel_Insert_Input = {
  data: Prompt_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Prompt_On_Conflict>;
};

/** on_conflict condition type for table "prompt" */
export type Prompt_On_Conflict = {
  constraint: Prompt_Constraint;
  update_columns?: Array<Prompt_Update_Column>;
  where?: InputMaybe<Prompt_Bool_Exp>;
};

/** Ordering options when selecting data from "prompt". */
export type Prompt_Order_By = {
  id?: InputMaybe<Order_By>;
  input?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  outputContentType?: InputMaybe<Order_By>;
  outputRenderer?: InputMaybe<Order_By>;
  promptOutputs_aggregate?: InputMaybe<Meeting_Prompt_Output_Aggregate_Order_By>;
  promptWorkspaces_aggregate?: InputMaybe<Prompt_Workspace_Aggregate_Order_By>;
  saved?: InputMaybe<Order_By>;
  systemPrompts_aggregate?: InputMaybe<System_Prompt_Aggregate_Order_By>;
};

export enum Prompt_Output_Renderer_Enum {
  Accordion = 'accordion',
  Markdown = 'markdown',
  Text = 'text'
}

/** Boolean expression to compare columns of type "prompt_output_renderer_enum". All fields are combined with logical 'AND'. */
export type Prompt_Output_Renderer_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Prompt_Output_Renderer_Enum>;
  _in?: InputMaybe<Array<Prompt_Output_Renderer_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Prompt_Output_Renderer_Enum>;
  _nin?: InputMaybe<Array<Prompt_Output_Renderer_Enum>>;
};

/** primary key columns input for table: prompt */
export type Prompt_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "prompt" */
export enum Prompt_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Input = 'input',
  /** column name */
  Name = 'name',
  /** column name */
  OutputContentType = 'outputContentType',
  /** column name */
  OutputRenderer = 'outputRenderer',
  /** column name */
  Saved = 'saved'
}

/** input type for updating data in table "prompt" */
export type Prompt_Set_Input = {
  input?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  saved?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "prompt" */
export type Prompt_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prompt_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prompt_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  input?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  outputContentType?: InputMaybe<Content_Type_Enum>;
  outputRenderer?: InputMaybe<Prompt_Output_Renderer_Enum>;
  saved?: InputMaybe<Scalars['Boolean']>;
};

/** update columns of table "prompt" */
export enum Prompt_Update_Column {
  /** column name */
  Input = 'input',
  /** column name */
  Name = 'name',
  /** column name */
  Saved = 'saved'
}

export type Prompt_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Prompt_Set_Input>;
  /** filter the rows which have to be updated */
  where: Prompt_Bool_Exp;
};

/** columns and relationships of "prompt_workspace" */
export type Prompt_Workspace = {
  __typename?: 'prompt_workspace';
  /** An object relationship */
  prompt: Prompt;
  promptId: Scalars['uuid'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "prompt_workspace" */
export type Prompt_Workspace_Aggregate = {
  __typename?: 'prompt_workspace_aggregate';
  aggregate?: Maybe<Prompt_Workspace_Aggregate_Fields>;
  nodes: Array<Prompt_Workspace>;
};

export type Prompt_Workspace_Aggregate_Bool_Exp = {
  count?: InputMaybe<Prompt_Workspace_Aggregate_Bool_Exp_Count>;
};

export type Prompt_Workspace_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Prompt_Workspace_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Prompt_Workspace_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "prompt_workspace" */
export type Prompt_Workspace_Aggregate_Fields = {
  __typename?: 'prompt_workspace_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Prompt_Workspace_Max_Fields>;
  min?: Maybe<Prompt_Workspace_Min_Fields>;
};


/** aggregate fields of "prompt_workspace" */
export type Prompt_Workspace_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Prompt_Workspace_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "prompt_workspace" */
export type Prompt_Workspace_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Prompt_Workspace_Max_Order_By>;
  min?: InputMaybe<Prompt_Workspace_Min_Order_By>;
};

/** input type for inserting array relation for remote table "prompt_workspace" */
export type Prompt_Workspace_Arr_Rel_Insert_Input = {
  data: Array<Prompt_Workspace_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Prompt_Workspace_On_Conflict>;
};

/** Boolean expression to filter rows from the table "prompt_workspace". All fields are combined with a logical 'AND'. */
export type Prompt_Workspace_Bool_Exp = {
  _and?: InputMaybe<Array<Prompt_Workspace_Bool_Exp>>;
  _not?: InputMaybe<Prompt_Workspace_Bool_Exp>;
  _or?: InputMaybe<Array<Prompt_Workspace_Bool_Exp>>;
  prompt?: InputMaybe<Prompt_Bool_Exp>;
  promptId?: InputMaybe<Uuid_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "prompt_workspace" */
export enum Prompt_Workspace_Constraint {
  /** unique or primary key constraint on columns "workspace_id", "prompt_id" */
  PromptWorkspacePkey = 'prompt_workspace_pkey'
}

/** input type for inserting data into table "prompt_workspace" */
export type Prompt_Workspace_Insert_Input = {
  prompt?: InputMaybe<Prompt_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Prompt_Workspace_Max_Fields = {
  __typename?: 'prompt_workspace_max_fields';
  promptId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "prompt_workspace" */
export type Prompt_Workspace_Max_Order_By = {
  promptId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Prompt_Workspace_Min_Fields = {
  __typename?: 'prompt_workspace_min_fields';
  promptId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "prompt_workspace" */
export type Prompt_Workspace_Min_Order_By = {
  promptId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "prompt_workspace" */
export type Prompt_Workspace_Mutation_Response = {
  __typename?: 'prompt_workspace_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Prompt_Workspace>;
};

/** on_conflict condition type for table "prompt_workspace" */
export type Prompt_Workspace_On_Conflict = {
  constraint: Prompt_Workspace_Constraint;
  update_columns?: Array<Prompt_Workspace_Update_Column>;
  where?: InputMaybe<Prompt_Workspace_Bool_Exp>;
};

/** Ordering options when selecting data from "prompt_workspace". */
export type Prompt_Workspace_Order_By = {
  prompt?: InputMaybe<Prompt_Order_By>;
  promptId?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** select columns of table "prompt_workspace" */
export enum Prompt_Workspace_Select_Column {
  /** column name */
  PromptId = 'promptId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** Streaming cursor of the table "prompt_workspace" */
export type Prompt_Workspace_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prompt_Workspace_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prompt_Workspace_Stream_Cursor_Value_Input = {
  promptId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** placeholder for update columns of table "prompt_workspace" (current role has no relevant permissions) */
export enum Prompt_Workspace_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "file" */
  file: Array<File>;
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<File>;
  /** fetch data from the table: "integration_type" */
  integration_type: Array<Integration_Type>;
  /** fetch data from the table: "integration_type" using primary key columns */
  integration_type_by_pk?: Maybe<Integration_Type>;
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: Job_Aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk?: Maybe<Job>;
  /** fetch data from the table: "meeting" */
  meeting: Array<Meeting>;
  /** fetch aggregated fields from the table: "meeting" */
  meeting_aggregate: Meeting_Aggregate;
  /** fetch data from the table: "meeting" using primary key columns */
  meeting_by_pk?: Maybe<Meeting>;
  /** fetch data from the table: "meeting_event" */
  meeting_event: Array<Meeting_Event>;
  /** fetch aggregated fields from the table: "meeting_event" */
  meeting_event_aggregate: Meeting_Event_Aggregate;
  /** fetch data from the table: "meeting_event" using primary key columns */
  meeting_event_by_pk?: Maybe<Meeting_Event>;
  /** fetch data from the table: "meeting_participant" */
  meeting_participant: Array<Meeting_Participant>;
  /** fetch aggregated fields from the table: "meeting_participant" */
  meeting_participant_aggregate: Meeting_Participant_Aggregate;
  /** fetch data from the table: "meeting_participant" using primary key columns */
  meeting_participant_by_pk?: Maybe<Meeting_Participant>;
  /** fetch data from the table: "meeting_prompt_output" */
  meeting_prompt_output: Array<Meeting_Prompt_Output>;
  /** fetch aggregated fields from the table: "meeting_prompt_output" */
  meeting_prompt_output_aggregate: Meeting_Prompt_Output_Aggregate;
  /** fetch data from the table: "meeting_prompt_output" using primary key columns */
  meeting_prompt_output_by_pk?: Maybe<Meeting_Prompt_Output>;
  /** fetch data from the table: "meeting_recording" */
  meeting_recording: Array<Meeting_Recording>;
  /** fetch aggregated fields from the table: "meeting_recording" */
  meeting_recording_aggregate: Meeting_Recording_Aggregate;
  /** fetch data from the table: "meeting_recording" using primary key columns */
  meeting_recording_by_pk?: Maybe<Meeting_Recording>;
  /** fetch data from the table: "meeting_transcription" */
  meeting_transcription: Array<Meeting_Transcription>;
  /** fetch aggregated fields from the table: "meeting_transcription" */
  meeting_transcription_aggregate: Meeting_Transcription_Aggregate;
  /** fetch data from the table: "meeting_transcription" using primary key columns */
  meeting_transcription_by_pk?: Maybe<Meeting_Transcription>;
  /** fetch data from the table: "meeting_type" */
  meeting_type: Array<Meeting_Type>;
  /** fetch aggregated fields from the table: "meeting_type" */
  meeting_type_aggregate: Meeting_Type_Aggregate;
  /** fetch data from the table: "meeting_type" using primary key columns */
  meeting_type_by_pk?: Maybe<Meeting_Type>;
  /** fetch data from the table: "meeting_type_definition" */
  meeting_type_definition: Array<Meeting_Type_Definition>;
  /** fetch aggregated fields from the table: "meeting_type_definition" */
  meeting_type_definition_aggregate: Meeting_Type_Definition_Aggregate;
  /** fetch data from the table: "meeting_type_definition" using primary key columns */
  meeting_type_definition_by_pk?: Maybe<Meeting_Type_Definition>;
  /** fetch data from the table: "meeting_type_definition_system_prompt" */
  meeting_type_definition_system_prompt: Array<Meeting_Type_Definition_System_Prompt>;
  /** fetch aggregated fields from the table: "meeting_type_definition_system_prompt" */
  meeting_type_definition_system_prompt_aggregate: Meeting_Type_Definition_System_Prompt_Aggregate;
  /** fetch data from the table: "meeting_type_definition_system_prompt" using primary key columns */
  meeting_type_definition_system_prompt_by_pk?: Maybe<Meeting_Type_Definition_System_Prompt>;
  meeting_video?: Maybe<MeetingVideoOutput>;
  /** fetch data from the table: "profile" */
  profile: Array<Profile>;
  /** fetch aggregated fields from the table: "profile" */
  profile_aggregate: Profile_Aggregate;
  /** fetch data from the table: "profile" using primary key columns */
  profile_by_pk?: Maybe<Profile>;
  /** fetch data from the table: "profile_stem" */
  profile_stem: Array<Profile_Stem>;
  /** fetch aggregated fields from the table: "profile_stem" */
  profile_stem_aggregate: Profile_Stem_Aggregate;
  /** fetch data from the table: "profile_stem" using primary key columns */
  profile_stem_by_pk?: Maybe<Profile_Stem>;
  /** fetch data from the table: "prompt" */
  prompt: Array<Prompt>;
  /** fetch aggregated fields from the table: "prompt" */
  prompt_aggregate: Prompt_Aggregate;
  /** fetch data from the table: "prompt" using primary key columns */
  prompt_by_pk?: Maybe<Prompt>;
  /** fetch data from the table: "prompt_workspace" */
  prompt_workspace: Array<Prompt_Workspace>;
  /** fetch aggregated fields from the table: "prompt_workspace" */
  prompt_workspace_aggregate: Prompt_Workspace_Aggregate;
  /** fetch data from the table: "prompt_workspace" using primary key columns */
  prompt_workspace_by_pk?: Maybe<Prompt_Workspace>;
  /** fetch data from the table: "system_prompt" */
  system_prompt: Array<System_Prompt>;
  /** fetch aggregated fields from the table: "system_prompt" */
  system_prompt_aggregate: System_Prompt_Aggregate;
  /** fetch data from the table: "system_prompt" using primary key columns */
  system_prompt_by_pk?: Maybe<System_Prompt>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_integration" */
  user_integration: Array<User_Integration>;
  /** fetch aggregated fields from the table: "user_integration" */
  user_integration_aggregate: User_Integration_Aggregate;
  /** fetch data from the table: "user_integration" using primary key columns */
  user_integration_by_pk?: Maybe<User_Integration>;
  /** fetch data from the table: "workspace" */
  workspace: Array<Workspace>;
  /** fetch aggregated fields from the table: "workspace" */
  workspace_aggregate: Workspace_Aggregate;
  /** fetch data from the table: "workspace" using primary key columns */
  workspace_by_pk?: Maybe<Workspace>;
};


export type Query_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Query_RootFile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIntegration_TypeArgs = {
  distinct_on?: InputMaybe<Array<Integration_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Type_Order_By>>;
  where?: InputMaybe<Integration_Type_Bool_Exp>;
};


export type Query_RootIntegration_Type_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootJobArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


export type Query_RootJob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


export type Query_RootJob_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMeetingArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Order_By>>;
  where?: InputMaybe<Meeting_Bool_Exp>;
};


export type Query_RootMeeting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Order_By>>;
  where?: InputMaybe<Meeting_Bool_Exp>;
};


export type Query_RootMeeting_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMeeting_EventArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Event_Order_By>>;
  where?: InputMaybe<Meeting_Event_Bool_Exp>;
};


export type Query_RootMeeting_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Event_Order_By>>;
  where?: InputMaybe<Meeting_Event_Bool_Exp>;
};


export type Query_RootMeeting_Event_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMeeting_ParticipantArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Participant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Participant_Order_By>>;
  where?: InputMaybe<Meeting_Participant_Bool_Exp>;
};


export type Query_RootMeeting_Participant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Participant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Participant_Order_By>>;
  where?: InputMaybe<Meeting_Participant_Bool_Exp>;
};


export type Query_RootMeeting_Participant_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMeeting_Prompt_OutputArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Prompt_Output_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Prompt_Output_Order_By>>;
  where?: InputMaybe<Meeting_Prompt_Output_Bool_Exp>;
};


export type Query_RootMeeting_Prompt_Output_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Prompt_Output_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Prompt_Output_Order_By>>;
  where?: InputMaybe<Meeting_Prompt_Output_Bool_Exp>;
};


export type Query_RootMeeting_Prompt_Output_By_PkArgs = {
  meetingId: Scalars['uuid'];
  promptId: Scalars['uuid'];
};


export type Query_RootMeeting_RecordingArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Recording_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Recording_Order_By>>;
  where?: InputMaybe<Meeting_Recording_Bool_Exp>;
};


export type Query_RootMeeting_Recording_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Recording_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Recording_Order_By>>;
  where?: InputMaybe<Meeting_Recording_Bool_Exp>;
};


export type Query_RootMeeting_Recording_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMeeting_TranscriptionArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Transcription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Transcription_Order_By>>;
  where?: InputMaybe<Meeting_Transcription_Bool_Exp>;
};


export type Query_RootMeeting_Transcription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Transcription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Transcription_Order_By>>;
  where?: InputMaybe<Meeting_Transcription_Bool_Exp>;
};


export type Query_RootMeeting_Transcription_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMeeting_TypeArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Type_Order_By>>;
  where?: InputMaybe<Meeting_Type_Bool_Exp>;
};


export type Query_RootMeeting_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Type_Order_By>>;
  where?: InputMaybe<Meeting_Type_Bool_Exp>;
};


export type Query_RootMeeting_Type_By_PkArgs = {
  meetingId: Scalars['uuid'];
  meetingTypeDefinitionId: Scalars['String'];
};


export type Query_RootMeeting_Type_DefinitionArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Type_Definition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Type_Definition_Order_By>>;
  where?: InputMaybe<Meeting_Type_Definition_Bool_Exp>;
};


export type Query_RootMeeting_Type_Definition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Type_Definition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Type_Definition_Order_By>>;
  where?: InputMaybe<Meeting_Type_Definition_Bool_Exp>;
};


export type Query_RootMeeting_Type_Definition_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootMeeting_Type_Definition_System_PromptArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Order_By>>;
  where?: InputMaybe<Meeting_Type_Definition_System_Prompt_Bool_Exp>;
};


export type Query_RootMeeting_Type_Definition_System_Prompt_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Order_By>>;
  where?: InputMaybe<Meeting_Type_Definition_System_Prompt_Bool_Exp>;
};


export type Query_RootMeeting_Type_Definition_System_Prompt_By_PkArgs = {
  meetingTypeDefinitionId: Scalars['String'];
  systemPromptId: Scalars['String'];
};


export type Query_RootMeeting_VideoArgs = {
  meetingId: Scalars['uuid'];
};


export type Query_RootProfileArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


export type Query_RootProfile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


export type Query_RootProfile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProfile_StemArgs = {
  distinct_on?: InputMaybe<Array<Profile_Stem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Stem_Order_By>>;
  where?: InputMaybe<Profile_Stem_Bool_Exp>;
};


export type Query_RootProfile_Stem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Stem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Stem_Order_By>>;
  where?: InputMaybe<Profile_Stem_Bool_Exp>;
};


export type Query_RootProfile_Stem_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPromptArgs = {
  distinct_on?: InputMaybe<Array<Prompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prompt_Order_By>>;
  where?: InputMaybe<Prompt_Bool_Exp>;
};


export type Query_RootPrompt_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prompt_Order_By>>;
  where?: InputMaybe<Prompt_Bool_Exp>;
};


export type Query_RootPrompt_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPrompt_WorkspaceArgs = {
  distinct_on?: InputMaybe<Array<Prompt_Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prompt_Workspace_Order_By>>;
  where?: InputMaybe<Prompt_Workspace_Bool_Exp>;
};


export type Query_RootPrompt_Workspace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prompt_Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prompt_Workspace_Order_By>>;
  where?: InputMaybe<Prompt_Workspace_Bool_Exp>;
};


export type Query_RootPrompt_Workspace_By_PkArgs = {
  promptId: Scalars['uuid'];
  workspaceId: Scalars['uuid'];
};


export type Query_RootSystem_PromptArgs = {
  distinct_on?: InputMaybe<Array<System_Prompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<System_Prompt_Order_By>>;
  where?: InputMaybe<System_Prompt_Bool_Exp>;
};


export type Query_RootSystem_Prompt_AggregateArgs = {
  distinct_on?: InputMaybe<Array<System_Prompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<System_Prompt_Order_By>>;
  where?: InputMaybe<System_Prompt_Bool_Exp>;
};


export type Query_RootSystem_Prompt_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootUser_IntegrationArgs = {
  distinct_on?: InputMaybe<Array<User_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Integration_Order_By>>;
  where?: InputMaybe<User_Integration_Bool_Exp>;
};


export type Query_RootUser_Integration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Integration_Order_By>>;
  where?: InputMaybe<User_Integration_Bool_Exp>;
};


export type Query_RootUser_Integration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootWorkspaceArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};


export type Query_RootWorkspace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};


export type Query_RootWorkspace_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "file" */
  file: Array<File>;
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<File>;
  /** fetch data from the table in a streaming manner: "file" */
  file_stream: Array<File>;
  /** fetch data from the table: "integration_type" */
  integration_type: Array<Integration_Type>;
  /** fetch data from the table: "integration_type" using primary key columns */
  integration_type_by_pk?: Maybe<Integration_Type>;
  /** fetch data from the table in a streaming manner: "integration_type" */
  integration_type_stream: Array<Integration_Type>;
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: Job_Aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk?: Maybe<Job>;
  /** fetch data from the table in a streaming manner: "job" */
  job_stream: Array<Job>;
  /** fetch data from the table: "meeting" */
  meeting: Array<Meeting>;
  /** fetch aggregated fields from the table: "meeting" */
  meeting_aggregate: Meeting_Aggregate;
  /** fetch data from the table: "meeting" using primary key columns */
  meeting_by_pk?: Maybe<Meeting>;
  /** fetch data from the table: "meeting_event" */
  meeting_event: Array<Meeting_Event>;
  /** fetch aggregated fields from the table: "meeting_event" */
  meeting_event_aggregate: Meeting_Event_Aggregate;
  /** fetch data from the table: "meeting_event" using primary key columns */
  meeting_event_by_pk?: Maybe<Meeting_Event>;
  /** fetch data from the table in a streaming manner: "meeting_event" */
  meeting_event_stream: Array<Meeting_Event>;
  /** fetch data from the table: "meeting_participant" */
  meeting_participant: Array<Meeting_Participant>;
  /** fetch aggregated fields from the table: "meeting_participant" */
  meeting_participant_aggregate: Meeting_Participant_Aggregate;
  /** fetch data from the table: "meeting_participant" using primary key columns */
  meeting_participant_by_pk?: Maybe<Meeting_Participant>;
  /** fetch data from the table in a streaming manner: "meeting_participant" */
  meeting_participant_stream: Array<Meeting_Participant>;
  /** fetch data from the table: "meeting_prompt_output" */
  meeting_prompt_output: Array<Meeting_Prompt_Output>;
  /** fetch aggregated fields from the table: "meeting_prompt_output" */
  meeting_prompt_output_aggregate: Meeting_Prompt_Output_Aggregate;
  /** fetch data from the table: "meeting_prompt_output" using primary key columns */
  meeting_prompt_output_by_pk?: Maybe<Meeting_Prompt_Output>;
  /** fetch data from the table in a streaming manner: "meeting_prompt_output" */
  meeting_prompt_output_stream: Array<Meeting_Prompt_Output>;
  /** fetch data from the table: "meeting_recording" */
  meeting_recording: Array<Meeting_Recording>;
  /** fetch aggregated fields from the table: "meeting_recording" */
  meeting_recording_aggregate: Meeting_Recording_Aggregate;
  /** fetch data from the table: "meeting_recording" using primary key columns */
  meeting_recording_by_pk?: Maybe<Meeting_Recording>;
  /** fetch data from the table in a streaming manner: "meeting_recording" */
  meeting_recording_stream: Array<Meeting_Recording>;
  /** fetch data from the table in a streaming manner: "meeting" */
  meeting_stream: Array<Meeting>;
  /** fetch data from the table: "meeting_transcription" */
  meeting_transcription: Array<Meeting_Transcription>;
  /** fetch aggregated fields from the table: "meeting_transcription" */
  meeting_transcription_aggregate: Meeting_Transcription_Aggregate;
  /** fetch data from the table: "meeting_transcription" using primary key columns */
  meeting_transcription_by_pk?: Maybe<Meeting_Transcription>;
  /** fetch data from the table in a streaming manner: "meeting_transcription" */
  meeting_transcription_stream: Array<Meeting_Transcription>;
  /** fetch data from the table: "meeting_type" */
  meeting_type: Array<Meeting_Type>;
  /** fetch aggregated fields from the table: "meeting_type" */
  meeting_type_aggregate: Meeting_Type_Aggregate;
  /** fetch data from the table: "meeting_type" using primary key columns */
  meeting_type_by_pk?: Maybe<Meeting_Type>;
  /** fetch data from the table: "meeting_type_definition" */
  meeting_type_definition: Array<Meeting_Type_Definition>;
  /** fetch aggregated fields from the table: "meeting_type_definition" */
  meeting_type_definition_aggregate: Meeting_Type_Definition_Aggregate;
  /** fetch data from the table: "meeting_type_definition" using primary key columns */
  meeting_type_definition_by_pk?: Maybe<Meeting_Type_Definition>;
  /** fetch data from the table in a streaming manner: "meeting_type_definition" */
  meeting_type_definition_stream: Array<Meeting_Type_Definition>;
  /** fetch data from the table: "meeting_type_definition_system_prompt" */
  meeting_type_definition_system_prompt: Array<Meeting_Type_Definition_System_Prompt>;
  /** fetch aggregated fields from the table: "meeting_type_definition_system_prompt" */
  meeting_type_definition_system_prompt_aggregate: Meeting_Type_Definition_System_Prompt_Aggregate;
  /** fetch data from the table: "meeting_type_definition_system_prompt" using primary key columns */
  meeting_type_definition_system_prompt_by_pk?: Maybe<Meeting_Type_Definition_System_Prompt>;
  /** fetch data from the table in a streaming manner: "meeting_type_definition_system_prompt" */
  meeting_type_definition_system_prompt_stream: Array<Meeting_Type_Definition_System_Prompt>;
  /** fetch data from the table in a streaming manner: "meeting_type" */
  meeting_type_stream: Array<Meeting_Type>;
  /** fetch data from the table: "profile" */
  profile: Array<Profile>;
  /** fetch aggregated fields from the table: "profile" */
  profile_aggregate: Profile_Aggregate;
  /** fetch data from the table: "profile" using primary key columns */
  profile_by_pk?: Maybe<Profile>;
  /** fetch data from the table: "profile_stem" */
  profile_stem: Array<Profile_Stem>;
  /** fetch aggregated fields from the table: "profile_stem" */
  profile_stem_aggregate: Profile_Stem_Aggregate;
  /** fetch data from the table: "profile_stem" using primary key columns */
  profile_stem_by_pk?: Maybe<Profile_Stem>;
  /** fetch data from the table in a streaming manner: "profile_stem" */
  profile_stem_stream: Array<Profile_Stem>;
  /** fetch data from the table in a streaming manner: "profile" */
  profile_stream: Array<Profile>;
  /** fetch data from the table: "prompt" */
  prompt: Array<Prompt>;
  /** fetch aggregated fields from the table: "prompt" */
  prompt_aggregate: Prompt_Aggregate;
  /** fetch data from the table: "prompt" using primary key columns */
  prompt_by_pk?: Maybe<Prompt>;
  /** fetch data from the table in a streaming manner: "prompt" */
  prompt_stream: Array<Prompt>;
  /** fetch data from the table: "prompt_workspace" */
  prompt_workspace: Array<Prompt_Workspace>;
  /** fetch aggregated fields from the table: "prompt_workspace" */
  prompt_workspace_aggregate: Prompt_Workspace_Aggregate;
  /** fetch data from the table: "prompt_workspace" using primary key columns */
  prompt_workspace_by_pk?: Maybe<Prompt_Workspace>;
  /** fetch data from the table in a streaming manner: "prompt_workspace" */
  prompt_workspace_stream: Array<Prompt_Workspace>;
  /** fetch data from the table: "system_prompt" */
  system_prompt: Array<System_Prompt>;
  /** fetch aggregated fields from the table: "system_prompt" */
  system_prompt_aggregate: System_Prompt_Aggregate;
  /** fetch data from the table: "system_prompt" using primary key columns */
  system_prompt_by_pk?: Maybe<System_Prompt>;
  /** fetch data from the table in a streaming manner: "system_prompt" */
  system_prompt_stream: Array<System_Prompt>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_integration" */
  user_integration: Array<User_Integration>;
  /** fetch aggregated fields from the table: "user_integration" */
  user_integration_aggregate: User_Integration_Aggregate;
  /** fetch data from the table: "user_integration" using primary key columns */
  user_integration_by_pk?: Maybe<User_Integration>;
  /** fetch data from the table in a streaming manner: "user_integration" */
  user_integration_stream: Array<User_Integration>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
  /** fetch data from the table: "workspace" */
  workspace: Array<Workspace>;
  /** fetch aggregated fields from the table: "workspace" */
  workspace_aggregate: Workspace_Aggregate;
  /** fetch data from the table: "workspace" using primary key columns */
  workspace_by_pk?: Maybe<Workspace>;
  /** fetch data from the table in a streaming manner: "workspace" */
  workspace_stream: Array<Workspace>;
};


export type Subscription_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootFile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFile_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<File_Stream_Cursor_Input>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootIntegration_TypeArgs = {
  distinct_on?: InputMaybe<Array<Integration_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Type_Order_By>>;
  where?: InputMaybe<Integration_Type_Bool_Exp>;
};


export type Subscription_RootIntegration_Type_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootIntegration_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Integration_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Integration_Type_Bool_Exp>;
};


export type Subscription_RootJobArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


export type Subscription_RootJob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


export type Subscription_RootJob_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootJob_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


export type Subscription_RootMeetingArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Order_By>>;
  where?: InputMaybe<Meeting_Bool_Exp>;
};


export type Subscription_RootMeeting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Order_By>>;
  where?: InputMaybe<Meeting_Bool_Exp>;
};


export type Subscription_RootMeeting_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMeeting_EventArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Event_Order_By>>;
  where?: InputMaybe<Meeting_Event_Bool_Exp>;
};


export type Subscription_RootMeeting_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Event_Order_By>>;
  where?: InputMaybe<Meeting_Event_Bool_Exp>;
};


export type Subscription_RootMeeting_Event_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMeeting_Event_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Meeting_Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Meeting_Event_Bool_Exp>;
};


export type Subscription_RootMeeting_ParticipantArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Participant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Participant_Order_By>>;
  where?: InputMaybe<Meeting_Participant_Bool_Exp>;
};


export type Subscription_RootMeeting_Participant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Participant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Participant_Order_By>>;
  where?: InputMaybe<Meeting_Participant_Bool_Exp>;
};


export type Subscription_RootMeeting_Participant_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMeeting_Participant_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Meeting_Participant_Stream_Cursor_Input>>;
  where?: InputMaybe<Meeting_Participant_Bool_Exp>;
};


export type Subscription_RootMeeting_Prompt_OutputArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Prompt_Output_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Prompt_Output_Order_By>>;
  where?: InputMaybe<Meeting_Prompt_Output_Bool_Exp>;
};


export type Subscription_RootMeeting_Prompt_Output_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Prompt_Output_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Prompt_Output_Order_By>>;
  where?: InputMaybe<Meeting_Prompt_Output_Bool_Exp>;
};


export type Subscription_RootMeeting_Prompt_Output_By_PkArgs = {
  meetingId: Scalars['uuid'];
  promptId: Scalars['uuid'];
};


export type Subscription_RootMeeting_Prompt_Output_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Meeting_Prompt_Output_Stream_Cursor_Input>>;
  where?: InputMaybe<Meeting_Prompt_Output_Bool_Exp>;
};


export type Subscription_RootMeeting_RecordingArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Recording_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Recording_Order_By>>;
  where?: InputMaybe<Meeting_Recording_Bool_Exp>;
};


export type Subscription_RootMeeting_Recording_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Recording_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Recording_Order_By>>;
  where?: InputMaybe<Meeting_Recording_Bool_Exp>;
};


export type Subscription_RootMeeting_Recording_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMeeting_Recording_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Meeting_Recording_Stream_Cursor_Input>>;
  where?: InputMaybe<Meeting_Recording_Bool_Exp>;
};


export type Subscription_RootMeeting_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Meeting_Stream_Cursor_Input>>;
  where?: InputMaybe<Meeting_Bool_Exp>;
};


export type Subscription_RootMeeting_TranscriptionArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Transcription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Transcription_Order_By>>;
  where?: InputMaybe<Meeting_Transcription_Bool_Exp>;
};


export type Subscription_RootMeeting_Transcription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Transcription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Transcription_Order_By>>;
  where?: InputMaybe<Meeting_Transcription_Bool_Exp>;
};


export type Subscription_RootMeeting_Transcription_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMeeting_Transcription_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Meeting_Transcription_Stream_Cursor_Input>>;
  where?: InputMaybe<Meeting_Transcription_Bool_Exp>;
};


export type Subscription_RootMeeting_TypeArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Type_Order_By>>;
  where?: InputMaybe<Meeting_Type_Bool_Exp>;
};


export type Subscription_RootMeeting_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Type_Order_By>>;
  where?: InputMaybe<Meeting_Type_Bool_Exp>;
};


export type Subscription_RootMeeting_Type_By_PkArgs = {
  meetingId: Scalars['uuid'];
  meetingTypeDefinitionId: Scalars['String'];
};


export type Subscription_RootMeeting_Type_DefinitionArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Type_Definition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Type_Definition_Order_By>>;
  where?: InputMaybe<Meeting_Type_Definition_Bool_Exp>;
};


export type Subscription_RootMeeting_Type_Definition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Type_Definition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Type_Definition_Order_By>>;
  where?: InputMaybe<Meeting_Type_Definition_Bool_Exp>;
};


export type Subscription_RootMeeting_Type_Definition_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootMeeting_Type_Definition_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Meeting_Type_Definition_Stream_Cursor_Input>>;
  where?: InputMaybe<Meeting_Type_Definition_Bool_Exp>;
};


export type Subscription_RootMeeting_Type_Definition_System_PromptArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Order_By>>;
  where?: InputMaybe<Meeting_Type_Definition_System_Prompt_Bool_Exp>;
};


export type Subscription_RootMeeting_Type_Definition_System_Prompt_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Order_By>>;
  where?: InputMaybe<Meeting_Type_Definition_System_Prompt_Bool_Exp>;
};


export type Subscription_RootMeeting_Type_Definition_System_Prompt_By_PkArgs = {
  meetingTypeDefinitionId: Scalars['String'];
  systemPromptId: Scalars['String'];
};


export type Subscription_RootMeeting_Type_Definition_System_Prompt_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Meeting_Type_Definition_System_Prompt_Stream_Cursor_Input>>;
  where?: InputMaybe<Meeting_Type_Definition_System_Prompt_Bool_Exp>;
};


export type Subscription_RootMeeting_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Meeting_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Meeting_Type_Bool_Exp>;
};


export type Subscription_RootProfileArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


export type Subscription_RootProfile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


export type Subscription_RootProfile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProfile_StemArgs = {
  distinct_on?: InputMaybe<Array<Profile_Stem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Stem_Order_By>>;
  where?: InputMaybe<Profile_Stem_Bool_Exp>;
};


export type Subscription_RootProfile_Stem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Stem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Stem_Order_By>>;
  where?: InputMaybe<Profile_Stem_Bool_Exp>;
};


export type Subscription_RootProfile_Stem_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProfile_Stem_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Profile_Stem_Stream_Cursor_Input>>;
  where?: InputMaybe<Profile_Stem_Bool_Exp>;
};


export type Subscription_RootProfile_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Profile_Stream_Cursor_Input>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


export type Subscription_RootPromptArgs = {
  distinct_on?: InputMaybe<Array<Prompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prompt_Order_By>>;
  where?: InputMaybe<Prompt_Bool_Exp>;
};


export type Subscription_RootPrompt_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prompt_Order_By>>;
  where?: InputMaybe<Prompt_Bool_Exp>;
};


export type Subscription_RootPrompt_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPrompt_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Prompt_Stream_Cursor_Input>>;
  where?: InputMaybe<Prompt_Bool_Exp>;
};


export type Subscription_RootPrompt_WorkspaceArgs = {
  distinct_on?: InputMaybe<Array<Prompt_Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prompt_Workspace_Order_By>>;
  where?: InputMaybe<Prompt_Workspace_Bool_Exp>;
};


export type Subscription_RootPrompt_Workspace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prompt_Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prompt_Workspace_Order_By>>;
  where?: InputMaybe<Prompt_Workspace_Bool_Exp>;
};


export type Subscription_RootPrompt_Workspace_By_PkArgs = {
  promptId: Scalars['uuid'];
  workspaceId: Scalars['uuid'];
};


export type Subscription_RootPrompt_Workspace_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Prompt_Workspace_Stream_Cursor_Input>>;
  where?: InputMaybe<Prompt_Workspace_Bool_Exp>;
};


export type Subscription_RootSystem_PromptArgs = {
  distinct_on?: InputMaybe<Array<System_Prompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<System_Prompt_Order_By>>;
  where?: InputMaybe<System_Prompt_Bool_Exp>;
};


export type Subscription_RootSystem_Prompt_AggregateArgs = {
  distinct_on?: InputMaybe<Array<System_Prompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<System_Prompt_Order_By>>;
  where?: InputMaybe<System_Prompt_Bool_Exp>;
};


export type Subscription_RootSystem_Prompt_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootSystem_Prompt_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<System_Prompt_Stream_Cursor_Input>>;
  where?: InputMaybe<System_Prompt_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootUser_IntegrationArgs = {
  distinct_on?: InputMaybe<Array<User_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Integration_Order_By>>;
  where?: InputMaybe<User_Integration_Bool_Exp>;
};


export type Subscription_RootUser_Integration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Integration_Order_By>>;
  where?: InputMaybe<User_Integration_Bool_Exp>;
};


export type Subscription_RootUser_Integration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_Integration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Integration_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Integration_Bool_Exp>;
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootWorkspaceArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};


export type Subscription_RootWorkspace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};


export type Subscription_RootWorkspace_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWorkspace_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Workspace_Stream_Cursor_Input>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

/** columns and relationships of "system_prompt" */
export type System_Prompt = {
  __typename?: 'system_prompt';
  description: Scalars['String'];
  id: Scalars['String'];
  /** An array relationship */
  meetingTypeDefinitionSystemPrompts: Array<Meeting_Type_Definition_System_Prompt>;
  /** An aggregate relationship */
  meetingTypeDefinitionSystemPrompts_aggregate: Meeting_Type_Definition_System_Prompt_Aggregate;
  /** An object relationship */
  prompt: Prompt;
  promptId: Scalars['uuid'];
  uiPlacement: System_Prompt_Ui_Placement_Enum;
};


/** columns and relationships of "system_prompt" */
export type System_PromptMeetingTypeDefinitionSystemPromptsArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Order_By>>;
  where?: InputMaybe<Meeting_Type_Definition_System_Prompt_Bool_Exp>;
};


/** columns and relationships of "system_prompt" */
export type System_PromptMeetingTypeDefinitionSystemPrompts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Meeting_Type_Definition_System_Prompt_Order_By>>;
  where?: InputMaybe<Meeting_Type_Definition_System_Prompt_Bool_Exp>;
};

/** aggregated selection of "system_prompt" */
export type System_Prompt_Aggregate = {
  __typename?: 'system_prompt_aggregate';
  aggregate?: Maybe<System_Prompt_Aggregate_Fields>;
  nodes: Array<System_Prompt>;
};

export type System_Prompt_Aggregate_Bool_Exp = {
  count?: InputMaybe<System_Prompt_Aggregate_Bool_Exp_Count>;
};

export type System_Prompt_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<System_Prompt_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<System_Prompt_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "system_prompt" */
export type System_Prompt_Aggregate_Fields = {
  __typename?: 'system_prompt_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<System_Prompt_Max_Fields>;
  min?: Maybe<System_Prompt_Min_Fields>;
};


/** aggregate fields of "system_prompt" */
export type System_Prompt_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<System_Prompt_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "system_prompt" */
export type System_Prompt_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<System_Prompt_Max_Order_By>;
  min?: InputMaybe<System_Prompt_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "system_prompt". All fields are combined with a logical 'AND'. */
export type System_Prompt_Bool_Exp = {
  _and?: InputMaybe<Array<System_Prompt_Bool_Exp>>;
  _not?: InputMaybe<System_Prompt_Bool_Exp>;
  _or?: InputMaybe<Array<System_Prompt_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  meetingTypeDefinitionSystemPrompts?: InputMaybe<Meeting_Type_Definition_System_Prompt_Bool_Exp>;
  meetingTypeDefinitionSystemPrompts_aggregate?: InputMaybe<Meeting_Type_Definition_System_Prompt_Aggregate_Bool_Exp>;
  prompt?: InputMaybe<Prompt_Bool_Exp>;
  promptId?: InputMaybe<Uuid_Comparison_Exp>;
  uiPlacement?: InputMaybe<System_Prompt_Ui_Placement_Enum_Comparison_Exp>;
};

/** aggregate max on columns */
export type System_Prompt_Max_Fields = {
  __typename?: 'system_prompt_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  promptId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "system_prompt" */
export type System_Prompt_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  promptId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type System_Prompt_Min_Fields = {
  __typename?: 'system_prompt_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  promptId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "system_prompt" */
export type System_Prompt_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  promptId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "system_prompt". */
export type System_Prompt_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meetingTypeDefinitionSystemPrompts_aggregate?: InputMaybe<Meeting_Type_Definition_System_Prompt_Aggregate_Order_By>;
  prompt?: InputMaybe<Prompt_Order_By>;
  promptId?: InputMaybe<Order_By>;
  uiPlacement?: InputMaybe<Order_By>;
};

/** select columns of table "system_prompt" */
export enum System_Prompt_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  PromptId = 'promptId',
  /** column name */
  UiPlacement = 'uiPlacement'
}

/** Streaming cursor of the table "system_prompt" */
export type System_Prompt_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: System_Prompt_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type System_Prompt_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  promptId?: InputMaybe<Scalars['uuid']>;
  uiPlacement?: InputMaybe<System_Prompt_Ui_Placement_Enum>;
};

export enum System_Prompt_Ui_Placement_Enum {
  CenterAnalysis = 'center_analysis',
  Left = 'left'
}

/** Boolean expression to compare columns of type "system_prompt_ui_placement_enum". All fields are combined with logical 'AND'. */
export type System_Prompt_Ui_Placement_Enum_Comparison_Exp = {
  _eq?: InputMaybe<System_Prompt_Ui_Placement_Enum>;
  _in?: InputMaybe<Array<System_Prompt_Ui_Placement_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<System_Prompt_Ui_Placement_Enum>;
  _nin?: InputMaybe<Array<System_Prompt_Ui_Placement_Enum>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  id: Scalars['String'];
  lastSeen: Scalars['timestamptz'];
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  lastSeen?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "user_integration" */
export type User_Integration = {
  __typename?: 'user_integration';
  id: Scalars['uuid'];
  integrationType: Integration_Type_Enum;
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
};

/** aggregated selection of "user_integration" */
export type User_Integration_Aggregate = {
  __typename?: 'user_integration_aggregate';
  aggregate?: Maybe<User_Integration_Aggregate_Fields>;
  nodes: Array<User_Integration>;
};

/** aggregate fields of "user_integration" */
export type User_Integration_Aggregate_Fields = {
  __typename?: 'user_integration_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Integration_Max_Fields>;
  min?: Maybe<User_Integration_Min_Fields>;
};


/** aggregate fields of "user_integration" */
export type User_Integration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Integration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_integration". All fields are combined with a logical 'AND'. */
export type User_Integration_Bool_Exp = {
  _and?: InputMaybe<Array<User_Integration_Bool_Exp>>;
  _not?: InputMaybe<User_Integration_Bool_Exp>;
  _or?: InputMaybe<Array<User_Integration_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  integrationType?: InputMaybe<Integration_Type_Enum_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type User_Integration_Max_Fields = {
  __typename?: 'user_integration_max_fields';
  id?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Integration_Min_Fields = {
  __typename?: 'user_integration_min_fields';
  id?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_integration" */
export type User_Integration_Mutation_Response = {
  __typename?: 'user_integration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Integration>;
};

/** Ordering options when selecting data from "user_integration". */
export type User_Integration_Order_By = {
  id?: InputMaybe<Order_By>;
  integrationType?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "user_integration" */
export enum User_Integration_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationType = 'integrationType',
  /** column name */
  UserId = 'userId'
}

/** Streaming cursor of the table "user_integration" */
export type User_Integration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Integration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Integration_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  integrationType?: InputMaybe<Integration_Type_Enum>;
  userId?: InputMaybe<Scalars['String']>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  id?: InputMaybe<Order_By>;
  lastSeen?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'lastSeen',
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "workspace" */
export type Workspace = {
  __typename?: 'workspace';
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "workspace" */
export type Workspace_Aggregate = {
  __typename?: 'workspace_aggregate';
  aggregate?: Maybe<Workspace_Aggregate_Fields>;
  nodes: Array<Workspace>;
};

/** aggregate fields of "workspace" */
export type Workspace_Aggregate_Fields = {
  __typename?: 'workspace_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Workspace_Max_Fields>;
  min?: Maybe<Workspace_Min_Fields>;
};


/** aggregate fields of "workspace" */
export type Workspace_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workspace_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "workspace". All fields are combined with a logical 'AND'. */
export type Workspace_Bool_Exp = {
  _and?: InputMaybe<Array<Workspace_Bool_Exp>>;
  _not?: InputMaybe<Workspace_Bool_Exp>;
  _or?: InputMaybe<Array<Workspace_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Workspace_Max_Fields = {
  __typename?: 'workspace_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Workspace_Min_Fields = {
  __typename?: 'workspace_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "workspace". */
export type Workspace_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** select columns of table "workspace" */
export enum Workspace_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "workspace" */
export type Workspace_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workspace_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workspace_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

export type DeleteMeetingPromptOutputMutationVariables = Exact<{
  meetingId: Scalars['uuid'];
  promptId: Scalars['uuid'];
}>;


export type DeleteMeetingPromptOutputMutation = { __typename?: 'mutation_root', delete_meeting_prompt_output_by_pk?: { __typename?: 'meeting_prompt_output', meetingId: any, promptId: any } | null };

export type DeletePromptMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeletePromptMutation = { __typename?: 'mutation_root', delete_prompt_by_pk?: { __typename?: 'prompt', id: any } | null };

export type DeleteUserIntegrationMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteUserIntegrationMutation = { __typename?: 'mutation_root', delete_user_integration_by_pk?: { __typename?: 'user_integration', id: any } | null };

export type GetCenterAnalysisMeetingPromptOutputsSubscriptionVariables = Exact<{
  meetingId: Scalars['uuid'];
}>;


export type GetCenterAnalysisMeetingPromptOutputsSubscription = { __typename?: 'subscription_root', meeting_prompt_output: Array<{ __typename?: 'meeting_prompt_output', output: string, finishedAt?: any | null, prompt: { __typename?: 'prompt', id: any, name: string, saved: boolean, input: string, outputContentType: Content_Type_Enum, outputRenderer: Prompt_Output_Renderer_Enum, promptWorkspaces_aggregate: { __typename?: 'prompt_workspace_aggregate', aggregate?: { __typename?: 'prompt_workspace_aggregate_fields', count: number } | null } } }> };

export type GetLeftMeetingPromptOutputsSubscriptionVariables = Exact<{
  meetingId: Scalars['uuid'];
}>;


export type GetLeftMeetingPromptOutputsSubscription = { __typename?: 'subscription_root', meeting_prompt_output: Array<{ __typename?: 'meeting_prompt_output', output: string, finishedAt?: any | null, prompt: { __typename?: 'prompt', id: any, name: string, saved: boolean, input: string, outputContentType: Content_Type_Enum, outputRenderer: Prompt_Output_Renderer_Enum, promptWorkspaces_aggregate: { __typename?: 'prompt_workspace_aggregate', aggregate?: { __typename?: 'prompt_workspace_aggregate_fields', count: number } | null } } }> };

export type GetMeetingByIdSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetMeetingByIdSubscription = { __typename?: 'subscription_root', meeting_by_pk?: { __typename?: 'meeting', id: any, status?: string | null, scheduledStart?: any | null, scheduledDuration?: number | null, actualStart?: any | null, actualDuration?: any | null, topic?: string | null, description?: string | null, participants: Array<{ __typename?: 'meeting_participant', id: any, externalId: string, profile?: { __typename?: 'profile', id: any, name: string, pictureFileId?: any | null } | null }>, totalJobs: { __typename?: 'job_aggregate', aggregate?: { __typename?: 'job_aggregate_fields', count: number } | null }, finishedJobs: { __typename?: 'job_aggregate', aggregate?: { __typename?: 'job_aggregate_fields', count: number } | null }, meeting_video: Array<{ __typename?: 'meeting_recording', fileId: any, startDate?: any | null }>, processedPromptOutputs: { __typename?: 'meeting_prompt_output_aggregate', aggregate?: { __typename?: 'meeting_prompt_output_aggregate_fields', count: number } | null }, totalPromptOutputs: { __typename?: 'meeting_prompt_output_aggregate', aggregate?: { __typename?: 'meeting_prompt_output_aggregate_fields', count: number } | null }, meetingTypes: Array<{ __typename?: 'meeting_type', meetingTypeDefinition: { __typename?: 'meeting_type_definition', id: string, name: string, description?: string | null, shortDescription?: string | null } }>, meetingVideoRecordings: Array<{ __typename?: 'meeting_recording', id: any, fileId: any, startDate?: any | null }> } | null };

export type GetMeetingParticipantQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetMeetingParticipantQuery = { __typename?: 'query_root', meeting_participant_by_pk?: { __typename?: 'meeting_participant', id: any, externalId: string, profileId?: any | null, profile?: { __typename?: 'profile', id: any, name: string, pictureFileId?: any | null } | null } | null };

export type GetMeetingParticipantsQueryVariables = Exact<{
  meetingId: Scalars['uuid'];
}>;


export type GetMeetingParticipantsQuery = { __typename?: 'query_root', meeting_participant: Array<{ __typename?: 'meeting_participant', id: any, externalId: string, profile?: { __typename?: 'profile', id: any, name: string, pictureFileId?: any | null } | null }> };

export type GetMeetingPromptOutputSubscriptionVariables = Exact<{
  meetingId: Scalars['uuid'];
  promptId: Scalars['uuid'];
}>;


export type GetMeetingPromptOutputSubscription = { __typename?: 'subscription_root', meeting_prompt_output_by_pk?: { __typename?: 'meeting_prompt_output', meetingId: any, promptId: any, output: string, finishedAt?: any | null } | null };

export type GetMeetingTimelineSubscriptionVariables = Exact<{
  meetingId: Scalars['uuid'];
}>;


export type GetMeetingTimelineSubscription = { __typename?: 'subscription_root', meeting_by_pk?: { __typename?: 'meeting', actualStart?: any | null, actualDuration?: any | null, scheduledStart?: any | null, scheduledDuration?: number | null, transcriptions: Array<{ __typename?: 'meeting_transcription', id: any, participantId: any, verse: string, startTime: any, endTime: any }> } | null };

export type GetMeetingTranscriptionsSubscriptionVariables = Exact<{
  meetingId: Scalars['uuid'];
}>;


export type GetMeetingTranscriptionsSubscription = { __typename?: 'subscription_root', meeting_by_pk?: { __typename?: 'meeting', actualStart?: any | null, events: Array<{ __typename?: 'meeting_event', id: any, type: string, timestamp: any, meetingParticipantId?: any | null }>, transcriptions: Array<{ __typename?: 'meeting_transcription', id: any, participantId: any, verse: string, startTime: any, endTime: any }> } | null };

export type GetMeetingTypeSubscriptionVariables = Exact<{
  meetingId: Scalars['uuid'];
}>;


export type GetMeetingTypeSubscription = { __typename?: 'subscription_root', meeting_type: Array<{ __typename?: 'meeting_type', meetingId: any, meetingTypeDefinition: { __typename?: 'meeting_type_definition', id: string, name: string, description?: string | null, shortDescription?: string | null } }> };

export type GetMeetingTypeDefinitionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeetingTypeDefinitionsQuery = { __typename?: 'query_root', meeting_type_definition: Array<{ __typename?: 'meeting_type_definition', id: string, name: string, description?: string | null, shortDescription?: string | null }> };

export type GetMeetingVideoQueryVariables = Exact<{
  meetingId: Scalars['uuid'];
}>;


export type GetMeetingVideoQuery = { __typename?: 'query_root', meeting_video?: { __typename?: 'MeetingVideoOutput', url: string, accessToken: string } | null };

export type GetMeetingVideoRecordingSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetMeetingVideoRecordingSubscription = { __typename?: 'subscription_root', meeting_recording_by_pk?: { __typename?: 'meeting_recording', id: any, fileId: any, startDate?: any | null, meeting: { __typename?: 'meeting', scheduledStart?: any | null, actualStart?: any | null } } | null };

export type GetMeetingsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetMeetingsSubscription = { __typename?: 'subscription_root', meeting: Array<{ __typename?: 'meeting', id: any, status?: string | null, scheduledStart?: any | null, scheduledDuration?: number | null, actualStart?: any | null, actualDuration?: any | null, topic?: string | null, description?: string | null, participants: Array<{ __typename?: 'meeting_participant', id: any, externalId: string, profile?: { __typename?: 'profile', id: any, name: string, pictureFileId?: any | null } | null }>, totalJobs: { __typename?: 'job_aggregate', aggregate?: { __typename?: 'job_aggregate_fields', count: number } | null }, finishedJobs: { __typename?: 'job_aggregate', aggregate?: { __typename?: 'job_aggregate_fields', count: number } | null }, meeting_video: Array<{ __typename?: 'meeting_recording', fileId: any, startDate?: any | null }>, processedPromptOutputs: { __typename?: 'meeting_prompt_output_aggregate', aggregate?: { __typename?: 'meeting_prompt_output_aggregate_fields', count: number } | null }, totalPromptOutputs: { __typename?: 'meeting_prompt_output_aggregate', aggregate?: { __typename?: 'meeting_prompt_output_aggregate_fields', count: number } | null }, meetingTypes: Array<{ __typename?: 'meeting_type', meetingTypeDefinition: { __typename?: 'meeting_type_definition', id: string, name: string, description?: string | null, shortDescription?: string | null } }>, meetingVideoRecordings: Array<{ __typename?: 'meeting_recording', id: any, fileId: any, startDate?: any | null }> }> };

export type GetProfilesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetProfilesSubscription = { __typename?: 'subscription_root', profile: Array<{ __typename?: 'profile', id: any, name: string, pictureFileId?: any | null, email?: string | null }> };

export type GetPromptByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetPromptByIdQuery = { __typename?: 'query_root', prompt_by_pk?: { __typename?: 'prompt', id: any, name: string, saved: boolean, input: string, outputContentType: Content_Type_Enum, outputRenderer: Prompt_Output_Renderer_Enum } | null };

export type GetSavedUserPromptsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSavedUserPromptsQuery = { __typename?: 'query_root', prompt: Array<{ __typename?: 'prompt', id: any, name: string, input: string, saved: boolean, systemPrompts: Array<{ __typename?: 'system_prompt', id: string, description: string }> }> };

export type GetUserIntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserIntegrationsQuery = { __typename?: 'query_root', user_integration: Array<{ __typename?: 'user_integration', id: any, userId: string, integrationType: Integration_Type_Enum }> };

export type GetWorkspacesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetWorkspacesSubscription = { __typename?: 'subscription_root', workspace: Array<{ __typename: 'workspace', id: any, name?: string | null }> };

export type InsertCustomMeetingMutationVariables = Exact<{
  workspaceId: Scalars['uuid'];
  topic?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  externalId: Scalars['String'];
  participants: Array<Meeting_Participant_Insert_Input> | Meeting_Participant_Insert_Input;
}>;


export type InsertCustomMeetingMutation = { __typename?: 'mutation_root', insert_meeting_one?: { __typename?: 'meeting', id: any } | null };

export type InsertMeetingParticipantMutationVariables = Exact<{
  meetingId: Scalars['uuid'];
  externalId: Scalars['String'];
  profile?: InputMaybe<Profile_Obj_Rel_Insert_Input>;
  profileId?: InputMaybe<Scalars['uuid']>;
}>;


export type InsertMeetingParticipantMutation = { __typename?: 'mutation_root', insert_meeting_participant_one?: { __typename?: 'meeting_participant', id: any, externalId: string, profileId?: any | null } | null };

export type InsertMeetingPromptMutationVariables = Exact<{
  meetingId: Scalars['uuid'];
  workspaceId: Scalars['uuid'];
  input: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  saved?: InputMaybe<Scalars['Boolean']>;
}>;


export type InsertMeetingPromptMutation = { __typename?: 'mutation_root', insert_prompt_one?: { __typename?: 'prompt', id: any } | null };

export type InsertMeetingPromptOutputMutationVariables = Exact<{
  meetingId: Scalars['uuid'];
  promptId: Scalars['uuid'];
}>;


export type InsertMeetingPromptOutputMutation = { __typename?: 'mutation_root', insert_meeting_prompt_output_one?: { __typename?: 'meeting_prompt_output', promptId: any } | null };

export type InsertProfileMutationVariables = Exact<{
  name: Scalars['String'];
  integrationType?: Integration_Type_Enum;
  externalId: Scalars['String'];
  workspaceId: Scalars['uuid'];
}>;


export type InsertProfileMutation = { __typename?: 'mutation_root', insert_profile_one?: { __typename?: 'profile', id: any, name: string, integrationType: Integration_Type_Enum, externalId: string, workspaceId: any } | null };

export type InstallUserIntegrationMutationVariables = Exact<{
  type: Scalars['String'];
  payload: Scalars['jsonb'];
}>;


export type InstallUserIntegrationMutation = { __typename?: 'mutation_root', installUserIntegration?: { __typename?: 'InstallUserIntegrationOutput', userIntegrationId: any } | null };

export type UpdateMeetingParticipantMutationVariables = Exact<{
  id: Scalars['uuid'];
  profileId?: InputMaybe<Scalars['uuid']>;
}>;


export type UpdateMeetingParticipantMutation = { __typename?: 'mutation_root', update_meeting_participant_by_pk?: { __typename?: 'meeting_participant', id: any, externalId: string, profileId?: any | null } | null };

export type UpdateMeetingTypeMutationVariables = Exact<{
  meetingId: Scalars['uuid'];
  meetingTypeDefinitionId: Scalars['String'];
}>;


export type UpdateMeetingTypeMutation = { __typename?: 'mutation_root', delete_meeting_prompt_output?: { __typename?: 'meeting_prompt_output_mutation_response', affected_rows: number } | null, delete_meeting_type?: { __typename?: 'meeting_type_mutation_response', affected_rows: number } | null, insert_meeting_type_one?: { __typename?: 'meeting_type', meetingId: any, meetingTypeDefinitionId: string } | null };

export type UpdatePromptMutationVariables = Exact<{
  promptId: Scalars['uuid'];
  input: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  saved?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdatePromptMutation = { __typename?: 'mutation_root', update_prompt_by_pk?: { __typename?: 'prompt', id: any } | null };

export type MeetingFragment = { __typename?: 'meeting', id: any, status?: string | null, scheduledStart?: any | null, scheduledDuration?: number | null, actualStart?: any | null, actualDuration?: any | null, topic?: string | null, description?: string | null, participants: Array<{ __typename?: 'meeting_participant', id: any, externalId: string, profile?: { __typename?: 'profile', id: any, name: string, pictureFileId?: any | null } | null }>, totalJobs: { __typename?: 'job_aggregate', aggregate?: { __typename?: 'job_aggregate_fields', count: number } | null }, finishedJobs: { __typename?: 'job_aggregate', aggregate?: { __typename?: 'job_aggregate_fields', count: number } | null }, meeting_video: Array<{ __typename?: 'meeting_recording', fileId: any, startDate?: any | null }>, processedPromptOutputs: { __typename?: 'meeting_prompt_output_aggregate', aggregate?: { __typename?: 'meeting_prompt_output_aggregate_fields', count: number } | null }, totalPromptOutputs: { __typename?: 'meeting_prompt_output_aggregate', aggregate?: { __typename?: 'meeting_prompt_output_aggregate_fields', count: number } | null }, meetingTypes: Array<{ __typename?: 'meeting_type', meetingTypeDefinition: { __typename?: 'meeting_type_definition', id: string, name: string, description?: string | null, shortDescription?: string | null } }>, meetingVideoRecordings: Array<{ __typename?: 'meeting_recording', id: any, fileId: any, startDate?: any | null }> };

export type MeetingPromptOutputFragment = { __typename?: 'meeting_prompt_output', output: string, finishedAt?: any | null, prompt: { __typename?: 'prompt', id: any, name: string, saved: boolean, input: string, outputContentType: Content_Type_Enum, outputRenderer: Prompt_Output_Renderer_Enum, promptWorkspaces_aggregate: { __typename?: 'prompt_workspace_aggregate', aggregate?: { __typename?: 'prompt_workspace_aggregate_fields', count: number } | null } } };

export type MeetingRecordingFragment = { __typename?: 'meeting_recording', id: any, fileId: any, startDate?: any | null };

export type MeetingTypeDefinitionFragment = { __typename?: 'meeting_type_definition', id: string, name: string, description?: string | null, shortDescription?: string | null };

export type ProfileFragment = { __typename?: 'profile', id: any, name: string, pictureFileId?: any | null };

export type PromptFragment = { __typename?: 'prompt', id: any, name: string, saved: boolean, input: string, outputContentType: Content_Type_Enum, outputRenderer: Prompt_Output_Renderer_Enum };

export const ProfileFragmentDoc = gql`
    fragment profile on profile {
  id
  name
  pictureFileId
}
    `;
export const MeetingTypeDefinitionFragmentDoc = gql`
    fragment meetingTypeDefinition on meeting_type_definition {
  id
  name
  description
  shortDescription
}
    `;
export const MeetingRecordingFragmentDoc = gql`
    fragment meetingRecording on meeting_recording {
  id
  fileId
  startDate
}
    `;
export const MeetingFragmentDoc = gql`
    fragment meeting on meeting {
  id
  status
  scheduledStart
  scheduledDuration
  actualStart
  actualDuration
  topic
  description
  participants {
    id
    externalId
    profile {
      ...profile
    }
  }
  totalJobs: jobs_aggregate {
    aggregate {
      count
    }
  }
  finishedJobs: jobs_aggregate(where: {finishedAt: {_is_null: false}}) {
    aggregate {
      count
    }
  }
  meeting_video: recordings(where: {type: {_eq: "meeting:video"}}, limit: 1) {
    fileId
    startDate
  }
  processedPromptOutputs: promptOutputs_aggregate(
    where: {finishedAt: {_is_null: false}}
  ) {
    aggregate {
      count
    }
  }
  totalPromptOutputs: promptOutputs_aggregate {
    aggregate {
      count
    }
  }
  meetingTypes {
    meetingTypeDefinition {
      ...meetingTypeDefinition
    }
  }
  meetingVideoRecordings: recordings(where: {type: {_eq: "meeting:video"}}) {
    ...meetingRecording
  }
}
    ${ProfileFragmentDoc}
${MeetingTypeDefinitionFragmentDoc}
${MeetingRecordingFragmentDoc}`;
export const PromptFragmentDoc = gql`
    fragment prompt on prompt {
  id
  name
  saved
  input
  outputContentType
  outputRenderer
}
    `;
export const MeetingPromptOutputFragmentDoc = gql`
    fragment meetingPromptOutput on meeting_prompt_output {
  output
  finishedAt
  prompt {
    ...prompt
    promptWorkspaces_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${PromptFragmentDoc}`;
export const DeleteMeetingPromptOutputDocument = gql`
    mutation DeleteMeetingPromptOutput($meetingId: uuid!, $promptId: uuid!) {
  delete_meeting_prompt_output_by_pk(meetingId: $meetingId, promptId: $promptId) {
    meetingId
    promptId
  }
}
    `;

export function useDeleteMeetingPromptOutputMutation() {
  return Urql.useMutation<DeleteMeetingPromptOutputMutation, DeleteMeetingPromptOutputMutationVariables>(DeleteMeetingPromptOutputDocument);
};
export const DeletePromptDocument = gql`
    mutation DeletePrompt($id: uuid!) {
  delete_prompt_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeletePromptMutation() {
  return Urql.useMutation<DeletePromptMutation, DeletePromptMutationVariables>(DeletePromptDocument);
};
export const DeleteUserIntegrationDocument = gql`
    mutation DeleteUserIntegration($id: uuid!) {
  delete_user_integration_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteUserIntegrationMutation() {
  return Urql.useMutation<DeleteUserIntegrationMutation, DeleteUserIntegrationMutationVariables>(DeleteUserIntegrationDocument);
};
export const GetCenterAnalysisMeetingPromptOutputsDocument = gql`
    subscription GetCenterAnalysisMeetingPromptOutputs($meetingId: uuid!) {
  meeting_prompt_output(
    where: {meetingId: {_eq: $meetingId}, prompt: {saved: {_eq: true}, _or: [{systemPrompts: {uiPlacement: {_eq: center_analysis}}}, {systemPrompts_aggregate: {count: {predicate: {_eq: 0}}}}]}}
    order_by: [{prompt: {systemPrompts_aggregate: {count: desc}}}]
  ) {
    ...meetingPromptOutput
  }
}
    ${MeetingPromptOutputFragmentDoc}`;

export function useGetCenterAnalysisMeetingPromptOutputsSubscription<TData = GetCenterAnalysisMeetingPromptOutputsSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetCenterAnalysisMeetingPromptOutputsSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetCenterAnalysisMeetingPromptOutputsSubscription, TData>) {
  return Urql.useSubscription<GetCenterAnalysisMeetingPromptOutputsSubscription, TData, GetCenterAnalysisMeetingPromptOutputsSubscriptionVariables>({ query: GetCenterAnalysisMeetingPromptOutputsDocument, ...options }, handler);
};
export const GetLeftMeetingPromptOutputsDocument = gql`
    subscription GetLeftMeetingPromptOutputs($meetingId: uuid!) {
  meeting_prompt_output(
    where: {meetingId: {_eq: $meetingId}, prompt: {saved: {_eq: true}, systemPrompts: {uiPlacement: {_eq: left}}}}
    order_by: [{prompt: {systemPrompts_aggregate: {count: desc}}}]
  ) {
    ...meetingPromptOutput
  }
}
    ${MeetingPromptOutputFragmentDoc}`;

export function useGetLeftMeetingPromptOutputsSubscription<TData = GetLeftMeetingPromptOutputsSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetLeftMeetingPromptOutputsSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetLeftMeetingPromptOutputsSubscription, TData>) {
  return Urql.useSubscription<GetLeftMeetingPromptOutputsSubscription, TData, GetLeftMeetingPromptOutputsSubscriptionVariables>({ query: GetLeftMeetingPromptOutputsDocument, ...options }, handler);
};
export const GetMeetingByIdDocument = gql`
    subscription GetMeetingById($id: uuid!) {
  meeting_by_pk(id: $id) {
    ...meeting
  }
}
    ${MeetingFragmentDoc}`;

export function useGetMeetingByIdSubscription<TData = GetMeetingByIdSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetMeetingByIdSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetMeetingByIdSubscription, TData>) {
  return Urql.useSubscription<GetMeetingByIdSubscription, TData, GetMeetingByIdSubscriptionVariables>({ query: GetMeetingByIdDocument, ...options }, handler);
};
export const GetMeetingParticipantDocument = gql`
    query GetMeetingParticipant($id: uuid!) {
  meeting_participant_by_pk(id: $id) {
    id
    externalId
    profileId
    profile {
      ...profile
    }
  }
}
    ${ProfileFragmentDoc}`;

export function useGetMeetingParticipantQuery(options: Omit<Urql.UseQueryArgs<GetMeetingParticipantQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMeetingParticipantQuery, GetMeetingParticipantQueryVariables>({ query: GetMeetingParticipantDocument, ...options });
};
export const GetMeetingParticipantsDocument = gql`
    query GetMeetingParticipants($meetingId: uuid!) {
  meeting_participant(where: {meetingId: {_eq: $meetingId}}) {
    id
    externalId
    profile {
      ...profile
    }
  }
}
    ${ProfileFragmentDoc}`;

export function useGetMeetingParticipantsQuery(options: Omit<Urql.UseQueryArgs<GetMeetingParticipantsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMeetingParticipantsQuery, GetMeetingParticipantsQueryVariables>({ query: GetMeetingParticipantsDocument, ...options });
};
export const GetMeetingPromptOutputDocument = gql`
    subscription GetMeetingPromptOutput($meetingId: uuid!, $promptId: uuid!) {
  meeting_prompt_output_by_pk(meetingId: $meetingId, promptId: $promptId) {
    meetingId
    promptId
    output
    finishedAt
  }
}
    `;

export function useGetMeetingPromptOutputSubscription<TData = GetMeetingPromptOutputSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetMeetingPromptOutputSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetMeetingPromptOutputSubscription, TData>) {
  return Urql.useSubscription<GetMeetingPromptOutputSubscription, TData, GetMeetingPromptOutputSubscriptionVariables>({ query: GetMeetingPromptOutputDocument, ...options }, handler);
};
export const GetMeetingTimelineDocument = gql`
    subscription GetMeetingTimeline($meetingId: uuid!) {
  meeting_by_pk(id: $meetingId) {
    actualStart
    actualDuration
    scheduledStart
    scheduledDuration
    transcriptions {
      id
      participantId
      verse
      startTime
      endTime
    }
  }
}
    `;

export function useGetMeetingTimelineSubscription<TData = GetMeetingTimelineSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetMeetingTimelineSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetMeetingTimelineSubscription, TData>) {
  return Urql.useSubscription<GetMeetingTimelineSubscription, TData, GetMeetingTimelineSubscriptionVariables>({ query: GetMeetingTimelineDocument, ...options }, handler);
};
export const GetMeetingTranscriptionsDocument = gql`
    subscription GetMeetingTranscriptions($meetingId: uuid!) {
  meeting_by_pk(id: $meetingId) {
    events(order_by: {timestamp: desc}) {
      id
      type
      timestamp
      meetingParticipantId
    }
    transcriptions {
      id
      participantId
      verse
      startTime
      endTime
      participantId
    }
    actualStart
  }
}
    `;

export function useGetMeetingTranscriptionsSubscription<TData = GetMeetingTranscriptionsSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetMeetingTranscriptionsSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetMeetingTranscriptionsSubscription, TData>) {
  return Urql.useSubscription<GetMeetingTranscriptionsSubscription, TData, GetMeetingTranscriptionsSubscriptionVariables>({ query: GetMeetingTranscriptionsDocument, ...options }, handler);
};
export const GetMeetingTypeDocument = gql`
    subscription GetMeetingType($meetingId: uuid!) {
  meeting_type(where: {meetingId: {_eq: $meetingId}}) {
    meetingId
    meetingTypeDefinition {
      ...meetingTypeDefinition
    }
  }
}
    ${MeetingTypeDefinitionFragmentDoc}`;

export function useGetMeetingTypeSubscription<TData = GetMeetingTypeSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetMeetingTypeSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetMeetingTypeSubscription, TData>) {
  return Urql.useSubscription<GetMeetingTypeSubscription, TData, GetMeetingTypeSubscriptionVariables>({ query: GetMeetingTypeDocument, ...options }, handler);
};
export const GetMeetingTypeDefinitionsDocument = gql`
    query GetMeetingTypeDefinitions {
  meeting_type_definition {
    ...meetingTypeDefinition
  }
}
    ${MeetingTypeDefinitionFragmentDoc}`;

export function useGetMeetingTypeDefinitionsQuery(options?: Omit<Urql.UseQueryArgs<GetMeetingTypeDefinitionsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMeetingTypeDefinitionsQuery, GetMeetingTypeDefinitionsQueryVariables>({ query: GetMeetingTypeDefinitionsDocument, ...options });
};
export const GetMeetingVideoDocument = gql`
    query GetMeetingVideo($meetingId: uuid!) {
  meeting_video(meetingId: $meetingId) {
    url
    accessToken
  }
}
    `;

export function useGetMeetingVideoQuery(options: Omit<Urql.UseQueryArgs<GetMeetingVideoQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMeetingVideoQuery, GetMeetingVideoQueryVariables>({ query: GetMeetingVideoDocument, ...options });
};
export const GetMeetingVideoRecordingDocument = gql`
    subscription GetMeetingVideoRecording($id: uuid!) {
  meeting_recording_by_pk(id: $id) {
    ...meetingRecording
    meeting {
      scheduledStart
      actualStart
    }
  }
}
    ${MeetingRecordingFragmentDoc}`;

export function useGetMeetingVideoRecordingSubscription<TData = GetMeetingVideoRecordingSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetMeetingVideoRecordingSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetMeetingVideoRecordingSubscription, TData>) {
  return Urql.useSubscription<GetMeetingVideoRecordingSubscription, TData, GetMeetingVideoRecordingSubscriptionVariables>({ query: GetMeetingVideoRecordingDocument, ...options }, handler);
};
export const GetMeetingsDocument = gql`
    subscription GetMeetings {
  meeting(
    where: {status: {_neq: "meeting:deleted"}}
    order_by: {events_aggregate: {max: {timestamp: desc}}}
  ) {
    ...meeting
  }
}
    ${MeetingFragmentDoc}`;

export function useGetMeetingsSubscription<TData = GetMeetingsSubscription>(options?: Omit<Urql.UseSubscriptionArgs<GetMeetingsSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetMeetingsSubscription, TData>) {
  return Urql.useSubscription<GetMeetingsSubscription, TData, GetMeetingsSubscriptionVariables>({ query: GetMeetingsDocument, ...options }, handler);
};
export const GetProfilesDocument = gql`
    subscription GetProfiles {
  profile(order_by: [{name: asc}]) {
    id
    name
    pictureFileId
    email
  }
}
    `;

export function useGetProfilesSubscription<TData = GetProfilesSubscription>(options?: Omit<Urql.UseSubscriptionArgs<GetProfilesSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetProfilesSubscription, TData>) {
  return Urql.useSubscription<GetProfilesSubscription, TData, GetProfilesSubscriptionVariables>({ query: GetProfilesDocument, ...options }, handler);
};
export const GetPromptByIdDocument = gql`
    query GetPromptById($id: uuid!) {
  prompt_by_pk(id: $id) {
    ...prompt
  }
}
    ${PromptFragmentDoc}`;

export function useGetPromptByIdQuery(options: Omit<Urql.UseQueryArgs<GetPromptByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPromptByIdQuery, GetPromptByIdQueryVariables>({ query: GetPromptByIdDocument, ...options });
};
export const GetSavedUserPromptsDocument = gql`
    query GetSavedUserPrompts {
  prompt(where: {saved: {_eq: true}}) {
    id
    name
    input
    saved
    systemPrompts {
      id
      description
    }
  }
}
    `;

export function useGetSavedUserPromptsQuery(options?: Omit<Urql.UseQueryArgs<GetSavedUserPromptsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSavedUserPromptsQuery, GetSavedUserPromptsQueryVariables>({ query: GetSavedUserPromptsDocument, ...options });
};
export const GetUserIntegrationsDocument = gql`
    query GetUserIntegrations {
  user_integration {
    id
    userId
    integrationType
  }
}
    `;

export function useGetUserIntegrationsQuery(options?: Omit<Urql.UseQueryArgs<GetUserIntegrationsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserIntegrationsQuery, GetUserIntegrationsQueryVariables>({ query: GetUserIntegrationsDocument, ...options });
};
export const GetWorkspacesDocument = gql`
    subscription GetWorkspaces {
  workspace {
    id
    name
    __typename
  }
}
    `;

export function useGetWorkspacesSubscription<TData = GetWorkspacesSubscription>(options?: Omit<Urql.UseSubscriptionArgs<GetWorkspacesSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<GetWorkspacesSubscription, TData>) {
  return Urql.useSubscription<GetWorkspacesSubscription, TData, GetWorkspacesSubscriptionVariables>({ query: GetWorkspacesDocument, ...options }, handler);
};
export const InsertCustomMeetingDocument = gql`
    mutation InsertCustomMeeting($workspaceId: uuid!, $topic: String, $description: String, $externalId: String!, $participants: [meeting_participant_insert_input!]!) {
  insert_meeting_one(
    object: {workspaceId: $workspaceId, integrationType: none, topic: $topic, externalId: $externalId, description: $description, meetingTypes: {data: [{meetingTypeDefinitionId: "default"}], on_conflict: {constraint: meeting_type_pkey, update_columns: []}}, participants: {data: $participants}}
  ) {
    id
  }
}
    `;

export function useInsertCustomMeetingMutation() {
  return Urql.useMutation<InsertCustomMeetingMutation, InsertCustomMeetingMutationVariables>(InsertCustomMeetingDocument);
};
export const InsertMeetingParticipantDocument = gql`
    mutation InsertMeetingParticipant($meetingId: uuid!, $externalId: String!, $profile: profile_obj_rel_insert_input, $profileId: uuid) {
  insert_meeting_participant_one(
    object: {meetingId: $meetingId, externalId: $externalId, profile: $profile, profileId: $profileId}
  ) {
    id
    externalId
    profileId
  }
}
    `;

export function useInsertMeetingParticipantMutation() {
  return Urql.useMutation<InsertMeetingParticipantMutation, InsertMeetingParticipantMutationVariables>(InsertMeetingParticipantDocument);
};
export const InsertMeetingPromptDocument = gql`
    mutation InsertMeetingPrompt($meetingId: uuid!, $workspaceId: uuid!, $input: String!, $name: String, $saved: Boolean = false) {
  insert_prompt_one(
    object: {input: $input, name: $name, saved: $saved, promptWorkspaces: {data: [{workspaceId: $workspaceId}], on_conflict: {constraint: prompt_workspace_pkey, update_columns: []}}, promptOutputs: {data: [{meetingId: $meetingId, output: ""}], on_conflict: {constraint: meeting_prompt_output_pkey, update_columns: []}}}
  ) {
    id
  }
}
    `;

export function useInsertMeetingPromptMutation() {
  return Urql.useMutation<InsertMeetingPromptMutation, InsertMeetingPromptMutationVariables>(InsertMeetingPromptDocument);
};
export const InsertMeetingPromptOutputDocument = gql`
    mutation InsertMeetingPromptOutput($meetingId: uuid!, $promptId: uuid!) {
  insert_meeting_prompt_output_one(
    object: {meetingId: $meetingId, promptId: $promptId, output: ""}
  ) {
    promptId
  }
}
    `;

export function useInsertMeetingPromptOutputMutation() {
  return Urql.useMutation<InsertMeetingPromptOutputMutation, InsertMeetingPromptOutputMutationVariables>(InsertMeetingPromptOutputDocument);
};
export const InsertProfileDocument = gql`
    mutation InsertProfile($name: String!, $integrationType: integration_type_enum! = none, $externalId: String!, $workspaceId: uuid!) {
  insert_profile_one(
    object: {name: $name, integrationType: $integrationType, externalId: $externalId, profileStem: {data: {}}, workspaceId: $workspaceId}
  ) {
    id
    name
    integrationType
    externalId
    workspaceId
  }
}
    `;

export function useInsertProfileMutation() {
  return Urql.useMutation<InsertProfileMutation, InsertProfileMutationVariables>(InsertProfileDocument);
};
export const InstallUserIntegrationDocument = gql`
    mutation InstallUserIntegration($type: String!, $payload: jsonb!) {
  installUserIntegration(type: $type, payload: $payload) {
    userIntegrationId
  }
}
    `;

export function useInstallUserIntegrationMutation() {
  return Urql.useMutation<InstallUserIntegrationMutation, InstallUserIntegrationMutationVariables>(InstallUserIntegrationDocument);
};
export const UpdateMeetingParticipantDocument = gql`
    mutation UpdateMeetingParticipant($id: uuid!, $profileId: uuid) {
  update_meeting_participant_by_pk(
    pk_columns: {id: $id}
    _set: {profileId: $profileId}
  ) {
    id
    externalId
    profileId
  }
}
    `;

export function useUpdateMeetingParticipantMutation() {
  return Urql.useMutation<UpdateMeetingParticipantMutation, UpdateMeetingParticipantMutationVariables>(UpdateMeetingParticipantDocument);
};
export const UpdateMeetingTypeDocument = gql`
    mutation UpdateMeetingType($meetingId: uuid!, $meetingTypeDefinitionId: String!) {
  delete_meeting_prompt_output(
    where: {meetingId: {_eq: $meetingId}, prompt: {systemPrompts_aggregate: {count: {predicate: {_gt: 0}}}, _not: {systemPrompts: {meetingTypeDefinitionSystemPrompts: {meetingTypeDefinitionId: {_eq: $meetingTypeDefinitionId}}}}}}
  ) {
    affected_rows
  }
  delete_meeting_type(where: {meetingId: {_eq: $meetingId}}) {
    affected_rows
  }
  insert_meeting_type_one(
    object: {meetingId: $meetingId, meetingTypeDefinitionId: $meetingTypeDefinitionId}
  ) {
    meetingId
    meetingTypeDefinitionId
  }
}
    `;

export function useUpdateMeetingTypeMutation() {
  return Urql.useMutation<UpdateMeetingTypeMutation, UpdateMeetingTypeMutationVariables>(UpdateMeetingTypeDocument);
};
export const UpdatePromptDocument = gql`
    mutation UpdatePrompt($promptId: uuid!, $input: String!, $name: String, $saved: Boolean = false) {
  update_prompt_by_pk(
    pk_columns: {id: $promptId}
    _set: {input: $input, name: $name, saved: $saved}
  ) {
    id
  }
}
    `;

export function useUpdatePromptMutation() {
  return Urql.useMutation<UpdatePromptMutation, UpdatePromptMutationVariables>(UpdatePromptDocument);
};