import React, {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  ProfileFragment,
  useGetMeetingTranscriptionsSubscription,
} from '../../generated/urql.user'
import { Loader, Stack } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'
import {
  EventsTimeline,
  EventsTimelineRef,
} from '../../components/EventsTimeline'
import moment from 'moment'
import { formatTime } from '../../lib/time'
import { ProfileAvatar } from '../../components/ProfileAvatar'
import { TypeAttributes } from 'rsuite/cjs/@types/common'

type TimelineItem =
  | {
      id: string
      type: 'meeting:started' | 'meeting:ended'
      text: string
      time: number
    }
  | {
      id: string
      type: 'participant:joined' | 'participant:left'
      text: string
      participant: {
        externalId: string
        profile?: ProfileFragment | null
        color: string
      }
      time: number
    }
  | {
      id: string
      type: 'participant:verse'
      text: string
      participant: {
        externalId: string
        profile?: ProfileFragment | null
        color: TypeAttributes.Color
      }
      time: number
      endTime: number
    }

export type MeetingTranscriptionType = {
  scrollTo: (time: number) => void
}

const MeetingTranscription = forwardRef<
  MeetingTranscriptionType,
  {
    meetingId: string
    participants: Array<{
      id: string
      externalId: string
      profile?: ProfileFragment | null
      color: TypeAttributes.Color
    }>
    className?: string
    style?: CSSProperties
  }
>(({ meetingId, participants, className, style }, ref) => {
  const [{ data: getMeetingTranscriptionsData }] =
    useGetMeetingTranscriptionsSubscription({ variables: { meetingId } })
  const { getAccessTokenSilently } = useAuth0()
  const [accessToken, setAccessToken] = useState<string>()
  const eventsTimelineRef = useRef<EventsTimelineRef | null>(null)

  const meeting = getMeetingTranscriptionsData?.meeting_by_pk

  useImperativeHandle(ref, () => ({
    scrollTo: (time: number) => {
      if (eventsTimelineRef?.current) {
        eventsTimelineRef.current.scrollTo(time)
      }
    },
  }))

  useEffect(() => {
    let stop = false
    getAccessTokenSilently().then((accessToken) => {
      if (!stop) {
        setAccessToken(accessToken)
      }
    })

    return () => {
      stop = true
    }
  }, [getAccessTokenSilently, setAccessToken])

  const startDate = useMemo(
    () => (meeting?.actualStart ? new Date(meeting.actualStart) : undefined),
    [meeting]
  )

  if (!meeting) {
    return <Loader />
  }

  const timeline = meeting.events
    .reduce<TimelineItem[]>((items, event) => {
      if (!startDate) return items

      const time = event.timestamp - startDate.getTime()

      const participant = participants.find(
        (p) => event.meetingParticipantId === p.id
      )

      if (!participant) {
        return items
      }

      switch (event.type) {
        case 'meeting:started':
          return [
            ...items,
            {
              id: event.id,
              type: 'meeting:started' as const,
              text: 'The meeting has been started',
              time,
            },
          ]
        case 'participant:joined':
          return [
            ...items,
            {
              id: event.id,
              type: 'participant:joined' as const,
              text: `${
                participant.profile?.name || participant.externalId
              } joins the meeting`,
              participant,
              time,
            },
          ]
        case 'participant:left':
          return [
            ...items,
            {
              id: event.id,
              type: 'participant:left' as const,
              text: `${
                participant.profile?.name || participant.externalId
              } leaves the meeting`,
              participant,
              time,
            },
          ]
        case 'meeting:ended':
          return [
            ...items,
            {
              id: event.id,
              type: 'meeting:ended' as const,
              text: 'The meeting has ended',
              time,
            },
          ]
        default:
          return items
      }
    }, [])
    .concat(
      meeting.transcriptions.map((transcription) => {
        const participant = participants.find(
          (p) => transcription.participantId === p.id
        )

        if (!participant) {
          throw new Error(
            `Participant ${transcription.participantId} not found`
          )
        }

        return {
          id: transcription.id,
          type: 'participant:verse',
          text: transcription.verse,
          participant,
          time: transcription.startTime,
          endTime: transcription.endTime,
        }
      })
    )
    .sort((t1, t2) => (t1.time < t2.time ? -1 : 1))

  return (
    <EventsTimeline
      ref={eventsTimelineRef}
      className={className}
      style={{
        height: '450px',
        overflowY: 'scroll',
        marginTop: '-20px',
      }}
    >
      {timeline.map((item) => {
        const time = startDate
          ? moment(startDate).add(item.time, 'milliseconds').format('HH:mm:ss')
          : '+' + formatTime(item.time)

        switch (item.type) {
          /*
          case 'meeting:started':
            return (
              <EventsTimeline.Item date={date}>
                <p className="text-muted text-xs">{time}</p>
                {text}
              </EventsTimeline.Item>
            )
          case 'meeting:ended':
            return (
              <EventsTimeline.Item date={date}>
                <p className="text-muted text-xs">{time}</p>
                {text}
              </EventsTimeline.Item>
            )
          case 'participant:joined':
          case 'participant:left':
            return (
              <EventsTimeline.Item date={date}>
                <p>
                  <span className="font-bold text-sm">
                    {participantProfile?.name}
                  </span>
                  <span className="text-muted text-xs">
                    &nbsp;{time}
                  </span>
                </p>
                {text}
              </EventsTimeline.Item>
            )*/

          case 'participant:verse':
            return (
              <EventsTimeline.Item
                key={item.id}
                time={item.time}
                className="pb-4"
              >
                <Stack spacing="16px" alignItems="flex-start">
                  <Stack.Item>
                    <ProfileAvatar
                      profile={
                        item.participant.profile || {
                          name: item.participant.externalId,
                        }
                      }
                      color={item.participant.color}
                      accessToken={accessToken}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <p className="m-0 leading-5">
                      <span className="font-bold text-sm">
                        {item.participant.profile?.name ||
                          item.participant.externalId}
                      </span>
                      <span className="text-muted text-xs">&nbsp;{time}</span>
                    </p>
                    <p className="text-xs m-0">{item.text}</p>
                  </Stack.Item>
                </Stack>
              </EventsTimeline.Item>
            )
          default:
            return null
        }
      })}
    </EventsTimeline>
  )
})

export default MeetingTranscription
