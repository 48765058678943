import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { Avatar, Button, Form, InputPicker, Modal, Stack } from 'rsuite'
import {
  GetMeetingParticipantDocument,
  GetMeetingParticipantQuery,
  GetMeetingParticipantQueryVariables,
  Integration_Type_Enum,
  useGetProfilesSubscription,
  useInsertMeetingParticipantMutation,
  useInsertProfileMutation,
  useUpdateMeetingParticipantMutation,
} from '../../generated/urql.user'
import { getStorageUrl } from '../../lib/storage'
import { useAuth0 } from '@auth0/auth0-react'
import { ItemDataType } from 'rsuite/esm/@types/common'
import { WorkspaceManagerContext } from '../../provider/workspace'
import { useClient } from 'urql'

export type MeetingParticipantModalType = {
  show: (id?: string) => void
}

const MeetingParticipantModal = forwardRef<
  MeetingParticipantModalType,
  { meetingId: string; onChange?: (meetingParticipant: unknown) => void }
>(({ meetingId, onChange }, ref) => {
  const [open, setOpen] = useState(false)
  const [id, setId] = useState<string>()
  const [{ data: profilesData }] = useGetProfilesSubscription()
  const { getAccessTokenSilently } = useAuth0()
  const client = useClient()
  const [accessToken, setAccessToken] = useState<string>()
  const [
    { fetching: updateMeetingParticipantFetching },
    updateMeetingParticipant,
  ] = useUpdateMeetingParticipantMutation()
  const [
    { fetching: insertMeetingParticipantFetching },
    insertMeetingParticipant,
  ] = useInsertMeetingParticipantMutation()
  const [{ fetching: insertProfileFetching }, insertProfile] =
    useInsertProfileMutation()
  const { workspace } = useContext(WorkspaceManagerContext)
  const [profileIdOrName, setProfileIdOrName] = useState<string>()

  useImperativeHandle(ref, () => ({
    show: (id) => {
      setOpen(true)
      setId(id)
    },
  }))

  useEffect(() => {
    if (id) {
      client
        .query<GetMeetingParticipantQuery, GetMeetingParticipantQueryVariables>(
          GetMeetingParticipantDocument,
          { id }
        )
        .toPromise()
        .then(({ data }) => {
          if (data?.meeting_participant_by_pk) {
            setProfileIdOrName(data.meeting_participant_by_pk.profileId)
          }
        })
    }
  }, [id])

  useEffect(() => {
    let stop = false
    getAccessTokenSilently().then((accessToken) => {
      if (!stop) {
        setAccessToken(accessToken)
      }
    })

    return () => {
      stop = true
    }
  }, [getAccessTokenSilently, setAccessToken])

  const profileOptions: ItemDataType[] = useMemo(
    () =>
      profilesData?.profile.map((profile) => ({
        label: profile.name,
        value: profile.id,
        group: profile.email ? 'Registered' : 'Anonymous',
        pictureUrl:
          profile.pictureFileId && accessToken
            ? getStorageUrl(profile.pictureFileId, accessToken)
            : undefined,
      })) || [],
    [profilesData, accessToken]
  )

  const doSave = async () => {
    if (!workspace)
      throw new Error(
        `Workspace is not set in MeetingParticipantModal component`
      )

    if (!profileIdOrName) {
      throw new Error(
        `Profile or name is not set in MeetingParticipantModal component`
      )
    }

    try {
      const shouldCreateProfile = !profilesData?.profile.find(
        ({ id }) => id === profileIdOrName
      )

      if (id) {
        const profileId = shouldCreateProfile
          ? (
              await insertProfile({
                name: profileIdOrName,
                workspaceId: workspace.id,
                externalId: Math.random().toString(36),
              })
            ).data?.insert_profile_one?.id
          : profileIdOrName

        if (!profileId) {
          throw new Error(`Failed to create profile for participant?`)
        }

        const { error } = await updateMeetingParticipant({
          id,
          profileId,
        })

        if (error) {
          console.error(error)
          return
        }
      } else {
        const participantExternalId = Math.random().toString(36)
        const { error } = await insertMeetingParticipant({
          meetingId,
          externalId: participantExternalId,
          ...(shouldCreateProfile
            ? {
                profile: {
                  data: {
                    name: profileIdOrName,
                    integrationType: Integration_Type_Enum.None,
                    externalId: participantExternalId,
                    profileStem: { data: {} },
                    workspaceId: workspace.id,
                  },
                },
              }
            : { profileId: profileIdOrName }),
        })

        if (error) {
          console.error(error)
          return
        }
      }

      setOpen(false)
    } catch (e) {
      console.error(e)
    }
  }

  if (!accessToken) return null

  return (
    <Modal open={open} onClose={() => setOpen(false)} size="xs">
      <Modal.Header closeButton>
        {!!id && <Modal.Title>Edit participant</Modal.Title>}
        {!id && <Modal.Title>Add participant</Modal.Title>}
      </Modal.Header>
      <Modal.Body>
        <Form fluid>
          <Form.Group controlId="profileId">
            <InputPicker
              creatable
              data={profileOptions}
              groupBy="group"
              block
              sort={(isGroup) => (a, b) =>
                isGroup
                  ? a === 'Registered'
                    ? 1
                    : -1
                  : a.label.localeCompare(b.label)}
              renderMenuItem={(label, item) => {
                return (
                  <>
                    <Avatar
                      className="rs-icon"
                      size="xs"
                      style={{ padding: '0' }}
                      circle
                      src={item?.pictureUrl}
                    />{' '}
                    {label}
                  </>
                )
              }}
              /* @ts-ignore */
              renderValue={(
                value: string | undefined,
                item?: (typeof profileOptions)[number]
              ) => {
                const option =
                  item ||
                  profileOptions.find((option) => option.value === value)
                if (option) {
                  return (
                    <Stack spacing={3}>
                      <Stack.Item>
                        <Avatar
                          className="rs-icon"
                          size="xs"
                          style={{ padding: '0' }}
                          circle
                          src={option?.pictureUrl}
                        />
                      </Stack.Item>
                      <Stack.Item>{option.label}</Stack.Item>
                    </Stack>
                  )
                } else if (value) {
                  return value
                }
              }}
              onCreate={(_, item) => {
                item.group = 'Anonymous'
              }}
              onChange={(value) => setProfileIdOrName(value)}
              value={profileIdOrName}
              shouldDisplayCreateOption={() => false}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="d-flex align-items-center"
          appearance="primary"
          loading={
            insertMeetingParticipantFetching ||
            updateMeetingParticipantFetching ||
            insertProfileFetching
          }
          disabled={
            insertMeetingParticipantFetching ||
            updateMeetingParticipantFetching ||
            insertProfileFetching
          }
          onClick={doSave}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
})

export default MeetingParticipantModal
