import { createContext, useEffect } from 'react'
import useLocalStorage from 'use-local-storage'
import {
  GetWorkspacesSubscription,
  useGetWorkspacesSubscription,
} from '../generated/urql.user'

type Workspace = Pick<
  GetWorkspacesSubscription['workspace'][number],
  'id' | 'name'
>

export type WorkspaceManager = ReturnType<typeof useWorkspaceManager>
export const WorkspaceManagerContext = createContext<WorkspaceManager>({
  workspace: null,
  workspaces: [],
  setWorkspace: (workspace: Workspace) => {},
})

export function useWorkspaceManager() {
  const [workspace, setWorkspace] = useLocalStorage<Workspace | null>(
    'workspace',
    null
  )
  const [{ data: getWorkspacesData }] = useGetWorkspacesSubscription()

  useEffect(() => {
    const workspaces = getWorkspacesData?.workspace || []
    if (!workspace && workspaces.length) {
      setWorkspace(workspaces[0])
    } else if (
      workspace &&
      workspaces.length &&
      !workspaces.find((w) => w.id === workspace.id)
    ) {
      setWorkspace(workspaces[0])
    }
  }, [workspace, getWorkspacesData, setWorkspace])

  return {
    workspace,
    setWorkspace: (workspace: Workspace) => setWorkspace(workspace),
    workspaces: getWorkspacesData?.workspace || [],
  }
}
