import {TypeAttributes} from "rsuite/cjs/@types/common";

export const DistinctColors: TypeAttributes.Color[] = [
  'red',
  'orange',
  'yellow',
  'green',
  'cyan',
  'blue',
  'violet',
]

