import { CookiesProvider } from 'react-cookie'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider as UrqlProvider } from 'urql'
import { getClient } from './lib/client'
import { useMemo } from 'react'
import MeetingPage from './pages/MeetingPage'
import { CustomProvider } from 'rsuite'
import Frame from './components/Frame'
import MeetingsPage from './pages/MeetingsPage'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import IntegrationsPage from './pages/IntegrationsPage'
import {
  useWorkspaceManager,
  WorkspaceManagerContext,
} from './provider/workspace'

const App = () => {
  const { getAccessTokenSilently, isLoading, error } = useAuth0()

  const client = useMemo(
    () => getClient(getAccessTokenSilently),
    [getAccessTokenSilently]
  )

  if (error) {
    return <div>Oops... {error.message}</div>
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <CookiesProvider>
      <UrqlProvider value={client}>
        <InnerApp />
      </UrqlProvider>
    </CookiesProvider>
  )
}

const InnerApp = () => {
  const workspaceManager = useWorkspaceManager()

  return (
    <WorkspaceManagerContext.Provider value={workspaceManager}>
      <BrowserRouter>
        <CustomProvider theme="light">
          <Routes>
            <>
              <Route path="/" element={<Frame />}>
                <Route index element={<MeetingsPage />} />
                <Route path="integrations" element={<IntegrationsPage />} />
                <Route path="meetings" element={<MeetingsPage />} />
                <Route path="meetings/:id" element={<MeetingPage />} />
              </Route>
            </>
          </Routes>
        </CustomProvider>
      </BrowserRouter>
    </WorkspaceManagerContext.Provider>
  )
}

export default withAuthenticationRequired(App, {
  onRedirecting: () => <div>Loading...</div>,
})
