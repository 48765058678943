import React, { useEffect, useMemo, useState } from 'react'
import { Panel, PanelGroup } from 'rsuite'
import { parsePromptOutput } from '../../../lib/prompt/output'
import { Content_Type_Enum } from '../../../generated/urql.user'
import { getDistinctColorForString } from '../../../lib/ui'
import { TypeAttributes } from 'rsuite/cjs/@types/common'

const AccordionColors: TypeAttributes.Color[] = [
  'red',
  'orange',
  'yellow',
  'green',
  'cyan',
  'blue',
  'violet',
]

export const AccordionMeetingPromptOutput: React.FC<{
  output: string
  loading: boolean
}> = ({ output, loading }) => {
  const [parsed, setParsed] = useState<object>([])

  useEffect(() => {
    try {
      const parsed = parsePromptOutput(
        output,
        Content_Type_Enum.ApplicationJson
      )
      setParsed(parsed)
    } catch (error) {}
  }, [output])

  const items = useMemo(
    () => (Array.isArray(parsed) ? parsed : [parsed]),
    [parsed]
  )

  return (
    <PanelGroup accordion defaultActiveKey={0}>
      {items.map((item, i) => {
        if (!item || !(typeof item === 'object')) return null

        const topicKey = Object.keys(item).find(
          (key) => key.toLowerCase() === 'topic'
        )
        if (!topicKey) return null

        if (!item[topicKey] || typeof item[topicKey] !== 'string') return null

        const { [topicKey]: topic, ...rest } = item

        return (
          <Panel
            header={topic && <span className="text-xs font-bold">{topic}</span>}
            eventKey={i}
            style={{
              backgroundColor: getDistinctColorForString(
                '',
                i,
                AccordionColors
              ),
            }}
          >
            {Object.entries(rest).map(([key, value]) =>
              (Array.isArray(value) ? !!value.length : !!value) ? (
                <p className="text-xs">
                  <strong>{key}: </strong>
                  {typeof value === 'string' ? (
                    value
                  ) : Array.isArray(value) ? (
                    <ul className="list-disc list-inside">
                      {value.map((v) => (
                        <li>{v}</li>
                      ))}
                    </ul>
                  ) : (
                    <code>parse error</code>
                  )}
                </p>
              ) : null
            )}
          </Panel>
        )
      })}
      {loading && <span className="pulse m-5">●</span>}
    </PanelGroup>
  )
}
