import {
  Breadcrumb,
  Col,
  Grid,
  IconButton,
  Loader,
  Message,
  Panel,
  Placeholder,
  Row,
  Stack,
  Whisper,
  WhisperInstance,
} from 'rsuite'
import { useParams } from 'react-router'
import {
  Content_Type_Enum,
  GetMeetingByIdSubscription,
  useGetLeftMeetingPromptOutputsSubscription,
  useGetMeetingByIdSubscription,
} from '../../generated/urql.user'
import NavLink from '../../components/NavLink'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {
  CiBoxes,
  CiFlag1,
  CiSquareQuestion,
  CiVideoOn,
  CiViewList,
  CiViewTimeline,
} from 'react-icons/ci'
import { MeetingPromptOutputs } from './MeetingPromptOutputs'
import MeetingVideoRecording, {
  MeetingVideoRecordingType,
} from './MeetingVideoRecording'
import MeetingTimeline from './MeetingTimeline'
import MeetingTranscription, {
  MeetingTranscriptionType,
} from './MeetingTranscription'
import CustomPromptDrawer, {
  CustomPromptDrawerType,
} from '../../components/CustomPromptDrawer'
import { TfiPlus } from 'react-icons/tfi'
import { MeetingPromptAddPopover } from './MeetingPromptAddPopover'
import { MeetingType } from './MeetingType'
import { AccordionMeetingPromptOutput } from './prompt/AccordionMeetingPromptOutput'
import { getDistinctColorForString } from '../../lib/ui'
import { FeatureFlag, FeatureFlags } from '../../components/FeatureFlag'
import MeetingParticipantModal, {
  MeetingParticipantModalType,
} from './MeetingParticipantModal'
import { DistinctColors } from '../../lib/colors'

const MeetingComponent = ({
  meeting,
}: {
  meeting: NonNullable<GetMeetingByIdSubscription['meeting_by_pk']>
}) => {
  const { getAccessTokenSilently } = useAuth0()
  const [accessToken, setAccessToken] = useState<string>()
  const [currentTime, setCurrentTime] = useState<number>()
  const meetingVideoRecordingRef = useRef<MeetingVideoRecordingType>(null)
  const meetingTranscriptionRef = useRef<MeetingTranscriptionType>(null)
  const meetingVideo = meeting.meeting_video[0]
  const customPromptDrawerRef = useRef<CustomPromptDrawerType>(null)
  const meetingParticipantModalRef = useRef<MeetingParticipantModalType>(null)
  const meetingPromptAddPopoverRef = useRef<WhisperInstance>(null)
  const [isMeetingPromptAddPopoverOpen, setIsMeetingPromptAddPopoverOpen] =
    useState(false)
  const [{ data: getLeftMeetingPromptOutputsData }] =
    useGetLeftMeetingPromptOutputsSubscription({
      variables: { meetingId: meeting.id },
    })

  const meetingVideoStartDate = useMemo(
    () =>
      meetingVideo?.startDate || meeting.actualStart || meeting.scheduledStart,
    [meetingVideo?.startDate, meeting.actualStart, meeting.scheduledStart]
  )

  useEffect(() => {
    if (
      meetingVideoRecordingRef.current &&
      meetingVideo &&
      currentTime !== undefined
    ) {
      if (
        Math.abs(meetingVideoRecordingRef.current.currentTime - currentTime) > 1
      ) {
        meetingVideoRecordingRef.current.currentTime = currentTime
      }
    }

    if (meetingTranscriptionRef.current && currentTime !== undefined) {
      meetingTranscriptionRef.current.scrollTo(currentTime)
    }
  }, [
    meetingVideoRecordingRef,
    currentTime,
    meetingVideo,
    meetingVideoStartDate,
  ])

  useEffect(() => {
    let stop = false
    getAccessTokenSilently().then((accessToken) => {
      if (!stop) {
        setAccessToken(accessToken)
      }
    })

    return () => {
      stop = true
    }
  }, [getAccessTokenSilently, setAccessToken])

  const participants = useMemo(() => {
    return meeting.participants.map((participant, i) => ({
      ...participant,
      color: getDistinctColorForString(participant.id, 0, DistinctColors),
    }))
  }, [meeting])

  useEffect(() => {
    const handler = () =>
      isMeetingPromptAddPopoverOpen &&
      meetingPromptAddPopoverRef.current?.close()
    document.getElementById('root')?.addEventListener('click', handler)
    return () =>
      document.getElementById('root')?.removeEventListener('click', handler)
  }, [meetingPromptAddPopoverRef, isMeetingPromptAddPopoverOpen])

  if (!accessToken) return null

  return (
    <>
      <Row>
        <Col xs={6}>
          {getLeftMeetingPromptOutputsData?.meeting_prompt_output?.map(
            ({ prompt, output, finishedAt }) => (
              <Panel
                header={
                  <h2 className="flex items-center gap-2">
                    <CiFlag1 size="24" />
                    <span>{prompt.name}</span>
                  </h2>
                }
                bordered
                bodyFill
              >
                {prompt.outputContentType ===
                  Content_Type_Enum.ApplicationJson &&
                  prompt.outputRenderer === 'accordion' && (
                    <AccordionMeetingPromptOutput
                      output={output}
                      loading={!finishedAt}
                    />
                  )}
              </Panel>
            )
          )}
        </Col>
        <Col xs={11}>
          <Panel
            header={
              <h2
                className="flex items-center gap-2"
                style={{ marginLeft: '130px' }}
              >
                <CiViewTimeline size="24" />
                <span>Timeline</span>
              </h2>
            }
          >
            <MeetingTimeline
              meetingId={meeting.id}
              participants={participants}
              currentTime={currentTime}
              onCurrentTimeChange={setCurrentTime}
              onMeetingParticipantEdit={(id) =>
                meetingParticipantModalRef.current?.show(id)
              }
            />
          </Panel>
          <Panel
            header={
              <Stack justifyContent="space-between" alignItems="center">
                <h2
                  className="flex items-center gap-2"
                  style={{ marginLeft: '130px' }}
                >
                  <CiSquareQuestion size="24" />
                  <span>Analysis</span>
                </h2>
                <Whisper
                  placement="bottom"
                  trigger="click"
                  rootClose={false}
                  ref={meetingPromptAddPopoverRef}
                  onOpen={() => setIsMeetingPromptAddPopoverOpen(true)}
                  onClose={() => setIsMeetingPromptAddPopoverOpen(false)}
                  speaker={({ top, left, className, onClose }, ref) => (
                    <MeetingPromptAddPopover
                      meetingId={meeting.id}
                      onCreateCustom={({ meetingId }) =>
                        customPromptDrawerRef.current?.show({ meetingId })
                      }
                      onClose={onClose}
                      left={left}
                      top={top}
                      className={className}
                      ref={ref}
                    />
                  )}
                >
                  <IconButton icon={<TfiPlus className="text-gray-600" />} />
                </Whisper>
              </Stack>
            }
          >
            <FeatureFlag flag={FeatureFlags.MeetingTypeEnabled}>
              <Stack alignItems="center" className="mb-4">
                <Stack.Item grow={0} shrink={0} basis="130px" className="pr-3">
                  <h5 className="flex gap-1 text-gray-900 items-start relative m-0">
                    <CiBoxes size="20" />
                    <span>Meeting type</span>
                  </h5>
                </Stack.Item>
                <Stack.Item grow={1}>
                  <MeetingType meetingId={meeting.id} />
                </Stack.Item>
              </Stack>
            </FeatureFlag>
            <MeetingPromptOutputs
              meetingId={meeting.id}
              onEdit={(args) => customPromptDrawerRef.current?.show(args)}
            />
          </Panel>
        </Col>
        <Col xs={7}>
          {meeting.meetingVideoRecordings.length > 0 && (
            <Panel
              header={
                <h2 className="flex items-center gap-2">
                  <CiVideoOn size="24" />
                  <span>Recording</span>
                </h2>
              }
            >
              <MeetingVideoRecording
                meetingVideoRecordingId={meeting.meetingVideoRecordings[0].id}
                ref={meetingVideoRecordingRef}
                onTimeUpdate={(currentTimeFromVideo) => {
                  if (currentTime !== currentTimeFromVideo) {
                    setCurrentTime(currentTimeFromVideo)
                  }
                }}
              />
            </Panel>
          )}
          <Panel
            header={
              <h2 className="flex items-center gap-2">
                <CiViewList size="24" />
                <span>Transcription</span>
              </h2>
            }
          >
            <MeetingTranscription
              meetingId={meeting.id}
              participants={participants}
              ref={meetingTranscriptionRef}
            />
          </Panel>
        </Col>
      </Row>
      <CustomPromptDrawer
        onSelectedPrompt={console.log}
        ref={customPromptDrawerRef}
      />
      <MeetingParticipantModal
        meetingId={meeting.id}
        ref={meetingParticipantModalRef}
      />
    </>
  )
}

const MeetingPage = () => {
  const { id } = useParams()
  const [{ data, fetching, error }] = useGetMeetingByIdSubscription({
    variables: { id },
  })

  const errorComponentHtml = (
    <>
      <Message showIcon type="error" header="Failed to load the meeting">
        {error?.message}
      </Message>
    </>
  )

  const loadingComponentHtml = (
    <>
      <Placeholder.Paragraph rows={8} />
      <Loader backdrop content="Loading..." vertical />
    </>
  )

  return (
    <Panel
      header={
        <Breadcrumb>
          <Breadcrumb.Item as={NavLink} to="/">
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item as={NavLink} to="/meetings">
            Meetings
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{data?.meeting_by_pk?.topic}</Breadcrumb.Item>
        </Breadcrumb>
      }
      bodyFill
    >
      <Grid fluid className="pl-4">
        {fetching && loadingComponentHtml}
        {error && errorComponentHtml}
        {!fetching && !error && data?.meeting_by_pk && (
          <MeetingComponent meeting={data.meeting_by_pk} />
        )}
      </Grid>
    </Panel>
  )
}

export default MeetingPage
