import {
  Loader,
  Stack,
  Breadcrumb,
  Panel,
  Table,
  Popover,
  Dropdown,
  IconButton,
  Whisper,
  Button,
} from 'rsuite'
import {
  GetMeetingsSubscription,
  useGetMeetingsSubscription,
} from '../../generated/urql.user'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import {
  BsCheckCircle,
  BsClock,
  BsFillRecord2Fill,
  BsQuestionCircle,
} from 'react-icons/bs'
import { FaSkullCrossbones } from 'react-icons/fa6'
import NavLink from '../../components/NavLink'
import React, { useEffect, useRef, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { TfiUpload } from 'react-icons/tfi'
import { FiMoreVertical } from 'react-icons/fi'
import UploadRecordingsModal, {
  UploadRecordingsModalType,
} from './UploadRecordingsModal'
import { PiPlus } from 'react-icons/pi'
import AddMeetingModal, { AddMeetingModalType } from './AddMeetingModal'
import { ProfileAvatar } from '../../components/ProfileAvatar'
import { getDistinctColorForString } from '../../lib/ui'
import { DistinctColors } from '../../lib/colors'
const { Column, HeaderCell, Cell } = Table

type Meeting = GetMeetingsSubscription['meeting'][number]

function getMeetingStatus(meeting: Meeting) {
  switch (meeting.status) {
    case 'meeting:started':
      return (
        <Stack spacing={8}>
          <Stack direction="column">
            <BsFillRecord2Fill
              size={20}
              style={{ color: 'var(--rs-red-500)' }}
              title="Live"
            />
            <p
              style={{
                color: 'var(--rs-red-500)',
                fontSize: '6px',
                margin: '0',
                lineHeight: '100%',
              }}
            >
              Live
            </p>
          </Stack>
          <p className="text-sm">live</p>
        </Stack>
      )
    case 'meeting:created':
      return (
        <Stack spacing={8}>
          <BsClock size={20} title="Scheduled" />
          <p className="text-sm">scheduled</p>
        </Stack>
      )
    case 'meeting:deleted':
      return (
        <Stack spacing={8}>
          <FaSkullCrossbones size={20} title="Deleted" />
          <p className="text-sm">deleted</p>
        </Stack>
      )
    case 'meeting:ended':
      const total =
        (meeting.totalJobs.aggregate?.count || 0) +
        (meeting.totalPromptOutputs.aggregate?.count || 0)
      const finished =
        (meeting.finishedJobs.aggregate?.count || 0) +
        (meeting.processedPromptOutputs.aggregate?.count || 0)
      if (total === 0) {
        return (
          <Stack spacing={8}>
            <Stack direction="column">
              <Loader size="xs" title="Processing" />
              <p
                style={{
                  fontSize: '6px',
                  margin: '0',
                  lineHeight: '100%',
                }}
              >
                downloading
              </p>
            </Stack>
            <p className="text-sm">processing</p>
          </Stack>
        )
      } else if (total === finished) {
        return (
          <Stack spacing={8}>
            <BsCheckCircle
              size={20}
              style={{ color: 'var(--rs-green-500)' }}
              title="Finished"
            />
            <p className="text-sm">finished</p>
          </Stack>
        )
      } else {
        return (
          <Stack spacing={8}>
            <Stack direction="column">
              <Loader size="xs" title="Processing" />
              <p
                style={{
                  fontSize: '6px',
                  margin: '0',
                  lineHeight: '100%',
                }}
              >
                {finished}/{total}
              </p>
            </Stack>
            <p className="text-sm">processing</p>
          </Stack>
        )
      }
    default:
      return <BsQuestionCircle size={20} />
  }
}

const MeetingsPage = () => {
  const [{ data, fetching }] = useGetMeetingsSubscription()
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const [accessToken, setAccessToken] = useState<string>()
  const uploadRecordingsModalRef = useRef<UploadRecordingsModalType>(null)
  const addMeetingModalRef = useRef<AddMeetingModalType>(null)

  useEffect(() => {
    let stop = false
    getAccessTokenSilently().then((accessToken) => {
      if (!stop) {
        setAccessToken(accessToken)
      }
    })

    return () => {
      stop = true
    }
  }, [getAccessTokenSilently, setAccessToken])

  if (!accessToken) return null

  return (
    <Panel
      header={
        <Breadcrumb>
          <Breadcrumb.Item as={NavLink} to="/">
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Meetings</Breadcrumb.Item>
        </Breadcrumb>
      }
    >
      <Stack justifyContent="flex-end" className="pb-2">
        <Button
          appearance="ghost"
          size="xs"
          onClick={() => addMeetingModalRef.current?.show()}
        >
          <PiPlus /> Add
        </Button>
      </Stack>
      <Table
        loading={fetching}
        autoHeight
        data={data ? data.meeting : []}
        onRowClick={(meeting: Meeting) => navigate(`/meetings/${meeting.id}`)}
        rowClassName="cursor-pointer"
        rowHeight={(rowData) => 30 + (rowData?.participants.length || 1) * 28}
      >
        <Column align="left" width={150} flexGrow={1}>
          <HeaderCell>Topic</HeaderCell>
          <Cell dataKey="topic" />
        </Column>
        <Column width={200}>
          <HeaderCell>Start date and time</HeaderCell>
          <Cell>
            {(rowData) => {
              const time = rowData.actualStart || rowData.scheduledStart
              return time ? moment(time).format('llll') : 'N/A'
            }}
          </Cell>
        </Column>
        <Column width={150}>
          <HeaderCell>Duration</HeaderCell>
          <Cell>
            {(rowData) => {
              const duration =
                rowData.actualDuration || rowData.scheduledDuration
              return duration
                ? moment.duration(duration, 'milliseconds').humanize()
                : 'N/A'
            }}
          </Cell>
        </Column>
        <Column width={300} fullText={true}>
          <HeaderCell>Participants</HeaderCell>
          <Cell>
            {(rowData) => (
              <Stack
                direction="column"
                spacing=".25rem"
                alignItems="flex-start"
              >
                {rowData.participants.map((p: any) => (
                  <Stack spacing=".25rem">
                    <ProfileAvatar
                      size="xs"
                      profile={p.profile || { name: p.externalId }}
                      accessToken={accessToken}
                      color={getDistinctColorForString(p.id, 0, DistinctColors)}
                    />
                    <span className="text-sm">{p.profile?.name || p.externalId}</span>
                  </Stack>
                ))}
              </Stack>
            )}
          </Cell>
        </Column>
        <Column width={150}>
          <HeaderCell>Status</HeaderCell>
          <Cell>{(meeting: any) => getMeetingStatus(meeting)}</Cell>
        </Column>
        <Column width={50}>
          <HeaderCell>&nbsp;</HeaderCell>
          <Cell onClick={(event) => event.stopPropagation()}>
            {(meeting: any) => (
              <Whisper
                placement="bottomEnd"
                trigger="click"
                speaker={({ top, left, className, onClose }, ref) => (
                  <Popover
                    ref={ref}
                    className={className}
                    style={{ left, top }}
                    full
                  >
                    <Dropdown.Menu
                      onSelect={() => {
                        onClose()
                      }}
                    >
                      <Dropdown.Item
                        icon={
                          <TfiUpload
                            style={{
                              display: 'inline-block',
                              marginRight: '3px',
                            }}
                          />
                        }
                        onClick={() => {
                          uploadRecordingsModalRef.current?.show(meeting.id)
                          onClose()
                        }}
                      >
                        Upload recordings
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Popover>
                )}
              >
                <IconButton size="sm" icon={<FiMoreVertical />} />
              </Whisper>
            )}
          </Cell>
        </Column>
      </Table>
      <UploadRecordingsModal ref={uploadRecordingsModalRef} />
      <AddMeetingModal
        ref={addMeetingModalRef}
        onMeetingCreated={(meetingId) =>
          uploadRecordingsModalRef.current?.show(meetingId)
        }
      />
    </Panel>
  )
}

export default MeetingsPage
