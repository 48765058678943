import { getStorageUrl } from '../lib/storage'
import { initials } from '../lib/initials'
import { Avatar } from 'rsuite'
import React, { useEffect, useState } from 'react'
import { Profile } from '../generated/urql.user'
import { useAuth0 } from '@auth0/auth0-react'
import { TypeAttributes } from 'rsuite/cjs/@types/common'

export const ProfileAvatar = ({
  profile,
  color,
  onClick,
  accessToken: predefinedAccessToken,
  size,
}: {
  profile: Pick<Profile, 'pictureFileId' | 'name'> &
    Partial<Pick<Profile, 'id'>>
  color?: TypeAttributes.Color
  size?: TypeAttributes.Size
  accessToken?: string
  onClick?: () => void
}) => {
  const { getAccessTokenSilently } = useAuth0()
  const [accessToken, setAccessToken] = useState<string | undefined>(
    predefinedAccessToken
  )

  useEffect(() => {
    if (predefinedAccessToken) {
      return
    }

    let stop = false
    getAccessTokenSilently().then((accessToken) => {
      if (!stop) {
        setAccessToken(accessToken)
      }
    })

    return () => {
      stop = true
    }
  }, [getAccessTokenSilently, predefinedAccessToken, setAccessToken])

  return (
    <Avatar
      circle
      bordered
      color={color}
      size={size || 'sm'}
      className={`m-1 ${onClick && 'cursor-pointer'}`}
      alt={initials(profile.name || '?')}
      title={profile.name || '?'}
      onClick={onClick}
      {...(profile.pictureFileId
        ? { src: getStorageUrl(profile.pictureFileId, accessToken) }
        : {})}
    />
  )
}
